@import "../../../styles/variables";

.AtScaleLegend {
  @border: solid 1px @grey-shade-8;
  margin-top: @margin-sm;
  padding: 0 !important;

  &.Unipolar,
  &.Bipolar {
    > .row {
      margin: 0 @margin-xl;

      > .col {
        > .row {
          margin: auto;
          align-items: center;

          .legend-category {
            min-height: @pixels-sm;

            .legend-category-label {
              border-left: 1px solid @grey-color-bg;
              border-right: 1px solid @grey-color-bg;
            }

            .legend-category-label,
            .legend-category-scores {
              .textSizeSmall;
              .textWeightBold;
              color: @grey-color-base;
              margin-bottom: @margin-xs;

              > .col {
                padding: 0;
              }
            }

            .legend-category-label {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &.Unipolar {
    > .row {
      justify-content: flex-end;

      > .col {
        max-width: 54%;
        padding-left: (15px + @padding-xs); // Bootstrap's 15px padding-left

        > .row {
          .legend-category {
            border-right: @border;

            &:first-child {
              border-left: @border;
            }
          }
        }
      }
    }

    &.team-view.container {
      margin-bottom: @margin-xs;
    }
  }

  &.Bipolar {
    > .row {
      margin: 0 @margin-xl;
      justify-content: center;

      > .col {
        max-width: 52%;

        > .row {
          margin: auto;

          .legend-category {
            .legend-category-label,
            .legend-category-scores {
              > .col {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  @media print {
    &.container {
      min-width: initial !important;
      max-width: 1140px !important;
    }
  }
}

.internetExplorer {
  .AtScaleLegend {
    &.Unipolar {
      > .row {
        justify-content: inherit;

        > .col {
          display: table;
          margin-left: auto;
        }
      }
    }

    &.Bipolar {
      > .row {
        justify-content: inherit;

        > .col {
          display: table;
          margin: 0 auto;
        }
      }
    }
  }
}
