@import "../../../styles/variables";

.TransactionAffectedBalanceBadge {
  &.badge {
    .textSizeMedium;
    .cardBorder();
    .borderRadius();
    padding: 6px 12px;
    background: #fafafa;
    color: @grey-shade-2;
  }

  .quantity {
    .textWeightMedium;
  }

  .name {
    .textWeightRegular;
  }
}
