@import "../../styles/variables";

.CompetencySetPreview {
  min-width: 320px;

  .tooltip-inner {
    min-width: 320px;
    padding: @padding-sm @padding-md;
    text-align: left;

    .sub-header {
      .textSizeSmall;
      .textWeightBold;
      color: @grey-shade-4;
    }

    .header {
      text-wrap: avoid;
    }

    ul {
      padding-left: @padding-sm;
      margin-bottom: 0;

      li.competency-preview-entry {
        text-align: left;
        .textWeightSemiBold;
        margin-bottom: @margin-xxs;
      }
    }
  }
}
