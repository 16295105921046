@import "../../styles/variables";

.AtNextStepAction {
  .jobs {
    display: flex;

    .AtBadge {
      margin-right: @margin-xs;
    }
  }
}
