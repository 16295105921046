@import "../../styles/variables";

#ClientMarketingInfoForm {
  .inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .CustomTextInput {
      flex: 0 0 49%;
      text-align: left;

      @media @screenWidthXL {
        flex: 0 0 23%;
      }
    }
  }
}
