@import "../../styles/variables";

.CandidatePicture {
  .borderRadius(100%);

  &.size-xxxs {
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  &.size-xxs {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }

  &.size-xs {
    width: 48px;
    height: 48px;
  }

  &.size-sm {
    width: 64px;
    height: 64px;
  }

  &.size-md {
    width: 80px;
    height: 80px;
  }

  &.size-lg {
    width: 96px;
    height: 96px;
  }
}
