@import "../../styles/variables";

.ProductIcon {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  .boxShadow(4px, 0.16, 2px);
  border-width: 0;
  margin-right: 4px;

  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 10px;
    .boxShadow(4px, 0.16, 2px);
    top: 4px;
    left: 4px;
    text-align: center;
  }

  &.Integral,
  &.PremiumIntegral,
  &.PremiumIntegralWithoutCognitive {
    background: @app-gradient-90;

    &:after {
      content: "I";
      background: @app-gradient-90;
    }
  }

  &.CognitiveTest,
  &.ProCognitiveTest,
  &.PremiumCognitiveTest {
    background: @cognitive-assessment-color;
    border-color: @cognitive-assessment-color;

    &:after {
      content: "C";
    }
  }

  &.PersonalityTest,
  &.ProPersonalityTest,
  &.PremiumPersonalityTest {
    background: @personality-assessment-color;
    border-color: @personality-assessment-color;

    &:after {
      content: "P";
    }
  }

  &.PreferencesTest,
  &.ProPreferencesTest,
  &.PremiumPreferencesTest {
    background: @preference-assessment-color;
    border-color: @preference-assessment-color;

    &:after {
      content: "P";
    }
  }

  &.LearningModeTest,
  &.ProLearningTest,
  &.PremiumLearningTest,
  &.PremiumLearningModeTest {
    background: @learning-assessment-color;
    border-color: @learning-assessment-color;

    &:after {
      content: "L";
    }
  }

  &.TripleBottomLineTest,
  &.ProTripleBottomLineTest,
  &.PremiumTripleBottomLineTest {
    background: @triple-bottom-line-assessment-color;
    border-color: @triple-bottom-line-assessment-color;

    &:after {
      content: "T";
    }
  }
}
