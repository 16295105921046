@import "../../../styles/variables";

.AtCompatibilityFitDataView {
  margin: @margin-sm @margin-sm @margin-xs @margin-sm;

  .combined-elements-container.elements-container {
    .header {
      .flexStartLayout();
      margin: @pixels-sm 0;

      .CandidateInterpretationHeader {
        margin: 0 20px;
      }
    }
  }

  .AtTabs {
    .AtButtonTabs.fullWidth {
      width: 95%;
    }

    .content.contentTable {
      .AtContainer.HighlightedText {
        border-top: none;
        margin-top: @margin-xs;
      }
      .combined-elements-container-strength.elements-container,
      .combined-elements-container-advice.elements-container {
        .highlighted-candidates {
          display: flex;
          margin: @margin-md 0 @margin-sm @margin-md;

          .AtIcon {
            margin: 0 @margin-xs @margin-xxs @margin-xxs;
          }
        }
      }
    }
  }

  @media print {
    .atCompatibilityFitDataView {
      .elements-container {
        @spacing: @page-top-padding;

        li {
          .multiplePagePrintHack();
        }

        &.second-candidate-elements-container {
          .multiplePagePrintHack();
        }
      }
    }

    .statusAndCandidatesAvatar {
      display: flex;
      justify-content: space-between;
      .highlighted-candidates {
        display: flex;
        align-items: center;

        .CandidateInterpretationHeader:first-child {
          margin-right: @margin-sm;
        }
      }
    }

    .HighlightedText {
      display: flex;
      flex-direction: column;
      margin-bottom: @margin-md;
      .interpretation-title {
        margin-bottom: @margin-xs;
      }
    }
  }
}
