@import "../../../../../../../../styles/variables";

.ObservableBehaviorStatementRow {
  padding: (@padding-xs + @padding-xxs) 0;
  display: flex;
  align-items: center;

  .left-section {
    display: flex;
    align-items: center;

    .statement-label {
      max-width: 100%;
      .textWeightMedium;
      .textSizeMedium;
      flex: 1 1 auto;
    }

    .add-notes-btn {
      margin-left: @margin-xs;
      flex: 0 0 auto;
    }
  }

  .notes-section {
    margin-top: @margin-sm;

    .notes-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: @margin-xxs;

      .AtSubtitle {
        .textWeightSemiBold;
        color: @grey-shade-4;
      }

      .remove-note-link {
        text-decoration: underline;
      }
    }

    .text-area-container {
      display: flex;
      .border(@grey-shade-9);
      .borderRadius();
      box-shadow: 0px 1px 2px @box-shadow-color;
      padding: (@padding-xs + @padding-xxs) @padding-sm;

      .AtIcon {
        align-self: baseline;
        margin-right: @margin-xs;
      }

      .CustomTextInput {
        flex: 1 0 auto;
        margin-bottom: 0;

        .note-text-input {
          padding: 2px 0;
          border-color: transparent;
          box-shadow: none;
          resize: none;
          //height: auto;
        }
      }
    }
  }

  @media @screenWidthMaxSM {
    .add-notes-btn {
      margin-top: @margin-sm;
    }

    .notes-section {
      .text-area-container {
        padding: @padding-xs (@padding-xs + @padding-xxs);
      }
    }

    .left-section {
      margin-bottom: @margin-xs;

      .statement-label {
        .textSizeLarge;
        .textWeightMedium;
        white-space: inherit;
      }
    }
  }
}
