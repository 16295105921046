@import "../../../styles/variables";

.AtContainer {
  .layoutBorder;
  .borderRadius(8px);
  background-color: white;

  .AtContainer-header {
    .flexLayout(@align-items: center, @justify-content: space-between);

    &.showHeaderMarginBottom:not(:last-child) {
      margin-bottom: @margin-sm;
    }

    &.isUnderlined {
      border-bottom: 1px solid @grey-shade-9;

      .flexLayout(@align-items: center, @justify-content: flex-start);

      h1 {
        margin-bottom: 0;
      }

      .AtIcon {
        margin-right: 10px;
      }
    }
  }

  .AtTabs {
    .AtButtonTabs {
      margin: @margin-sm @alternate-pixels-l 0; // TODO: @ced check to remove this
    }
    .content.contentTable {
      .topBar {
        z-index: 100;
        margin: @margin-sm @alternate-pixels-l @alternate-pixels-l;
      }
    }

    .AtTableBase.AtContainer {
      border: none;
      .AtTableHeader.AtContainer {
        border: none;
      }
      table {
        border-top: 1px solid @grey-shade-9;
        border-bottom: none;
      }
    }
    .AtTableRow > .AtTableRowCell:first-child {
      border-left: none;
    }

    .AtTableRow > .AtTableRowCell:last-child {
      border-right: none;
    }
  }

  @media print {
    border: none;
  }
}
