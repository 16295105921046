@import "../../styles/variables";

#AttributeCredits {
  #select-recipient-step {
    label {
      .textSizeSmall;
      .textWeightMedium;
      margin-bottom: @padding-xxs;
    }
  }

  #type-of-transaction-step {
    label:not(:last-child) {
      margin-right: 8px;
    }

    input {
      max-width: @container-max-width-sm;
    }
  }

  #confirmation-step {
    .error-container {
      .alert {
        margin-top: 8px;
      }
    }

    .modal-buttons {
      margin: @padding-md 0;
    }
  }
}
