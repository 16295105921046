@import "../../styles/variables";

.SummaryCard {
  text-align: left;

  &.AtContainer {
    .header {
      margin-left: -40px;
      margin-right: -40px;
      .lgHeaderSvg {
        margin-left: 0;
        position: relative;
        top: -20px;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }

  .header {
    .AtmanIcon {
      margin-right: @pixels-sm;
      flex: 1 0 auto;
    }

    .summaryCardHeaderTitleWrapper {
      display: flex;
      align-items: center;
      padding-bottom: @padding-md;
      .AtmanIcon {
        flex: none;
      }
      .AtTitle {
        margin-bottom: 0;
        font-weight: 500;
      }

      img {
        width: 170px;
      }
    }

    margin-bottom: @pixels-sm;
  }

  .bottomSection {
    width: 90%;
    ul {
      padding-left: @padding-md;
      li {
        margin-bottom: @margin-xs;
      }
      li:first-child {
        padding-top: @padding-xs;
      }
    }
  }

  .summaryCardHeaderContentWrapper {
    &.inTeamsDesign {
      margin-top: @margin-md;
    }
  }

  .tagsContainer {
    .flexLayout(@justify-content: flex-start);
    flex-wrap: wrap;
    gap: @pixels-xs;
  }

  @media print {
    .header {
      .AtmanIcon {
        flex: inherit;
      }
    }
  }
}
