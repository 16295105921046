@import "../../styles/variables";

.CreditsBalanceSelectorLineItem {
  display: flex;
  align-items: center;
  padding: 12px @padding-md;

  .product-info-section {
    flex: 1 0 30%;

    .ProductInfo {
      .product-group {
        .textSizeSmall;
      }

      .product-name {
        .textSizeMedium;
      }
    }
  }

  .quantity-selection-section {
    flex: 1 0 35%;
    display: flex;
    align-items: center;

    .btn-group {
      margin-right: @padding-sm;

      .AtButton {
        width: 37px;
        height: 37px;
        .textSizeSmall;
        .textWeightMedium;
        .cardBorder();
        padding: 0 !important;

        &.active,
        &:active,
        &.focus,
        &:focus {
          box-shadow: 0 0 0 #000 !important;
        }
      }
    }

    .custom-quantity-btn {
      width: 66px;
      min-width: inherit;
      height: 37px;
      padding: 0;
      .textSizeSmall;
      .textWeightMedium;

      .btn-input {
        border-width: 0;
        padding: 0;
        height: ~"calc(1.5em + 2px)";
        text-align: center;

        &:focus {
          box-shadow: 0 0 0 #000;
        }
      }
    }
  }

  .recipient-balance-section,
  .new-balance-section {
    flex: 1 0 20%;
  }

  .current-balance-section {
    flex: 1 0 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .balance {
      width: 2.5vw;
    }

    .addProductLink {
      margin-left: 4px;
    }
  }
}
