@import "../../styles/variables";

.CollapsibleInputTextarea {
  &.AtCollapsibleCard {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    .header > .right-section {
      .right-header-icons {
        display: flex;
        align-items: center;
        svg {
          margin-left: @margin-sm;
        }
      }
    }

    .collapse.show > .content {
      text-align: initial;
    }
  }
}

.CustomTextInput,
.AtInput {
  label {
    font-size: @alternate-pixels-s;
  }
}
