@import "../../styles/variables";

.CompDevEmployeeHeader {
  background: #ffffff;

  > .header-title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: @margin-md;

    .right-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > *:not(:last-child) {
        margin-right: (@padding-xs + @padding-xxs);
      }

      .last-update {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-3;
      }
    }
  }

  > .employee-header {
    padding-bottom: @margin-xs;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    .employee-section-content {
      display: flex;
      align-items: center;

      .AtPersonAvatar {
        margin-right: @margin-xs;
      }

      .employee-name {
        margin-bottom: 0;
        .AtTitle {
          margin-bottom: 0;
        }
        .AtSubtitle {
          font-weight: 500;
        }
      }
    }

    > .buttons-section {
      .flexLayout();

      > *:not(:last-child) {
        margin-right: @margin-xs;
      }
    }
  }

  > .cancelled-notice-container {
    .textSizeMedium;
    .textWeightMedium;
    .dashedBoxContainer-warning;
    //margin: 0 (@margin-sm + @margin-md);
    padding: @padding-xs (@padding-xs + @padding-xxs);
    margin-bottom: @margin-sm;

    .user-name {
      .textWeightBold;
    }
  }
}
