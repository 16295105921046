@import "../../../styles/variables";

.AssessmentDocumentsHeader {
  .ReportHeader,
  .FitLevelFiltering {
    background-color: #fff;
  }

  .FitLevelFiltering {
    margin-bottom: @margin-xxs;
  }

  .help-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: @margin-xs;
  }

  .peopleLabelCompareWrapper {
    padding: @pixels-md;
    margin-bottom: @pixels-xxs;
    div.d-flex {
      justify-content: space-between;
      .FitScoreContainerAndLegend-wrapper {
        display: flex;
        .FitScoreContainerAndLegend {
          margin-right: @margin-xs;
        }
      }
    }
  }

  .helpCenterLinkWrapper {
    margin-top: @pixels-sm;
    display: flex;
    text-align: start;
    .HelpCenterInfoLink a {
      text-decoration: underline;
      .icon-container {
        display: none;
      }
    }
  }

  .main-info-container {
    display: flex;
    align-items: center;

    .icon-container {
      height: @pixels-xxxl;
      flex: 0 0 @pixels-xxxl;
      .flexLayout();
      margin-right: @margin-sm;
    }

    .title {
      text-align: left;
      margin-bottom: @margin-xxs;
    }

    .description {
      .textSizeLarge;
      .textWeightMedium;
      text-align: left;
      color: @grey-shade-2;

      &:not(:last-child) {
        margin-bottom: @margin-xs;
      }
    }

    .AtBadge {
      .borderRadius(@pixels-xxs);

      > *:not(:last-child) {
        margin-right: @margin-xs;
      }
    }
  }

  #FitLevelLegend {
    margin-top: @margin-sm;
  }

  .ReportLegendContainer {
    justify-content: flex-start;
    margin-top: @margin-sm;
    margin-bottom: 0;
  }

  @media print {
    border: none;
    margin-top: @margin-xxl;
    margin-bottom: -@page-top-padding;

    .peopleLabelCompareWrapper {
      padding: @pixels-lg @pixels-md 0 @pixels-md;
      margin-bottom: 0;
    }
  }
}
