@import "../../../styles/variables";

.FitLevelFiltering {
  .flexLayout();
  .layoutBorder();
  .borderRadius();

  padding: @padding-xxs;
  background-color: @grey-color-bg;

  .AtFilter {
    width: 100%;
    margin-right: @margin-xs;

    &:last-child {
      margin-right: 0;
    }
  }
}
