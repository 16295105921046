@import "../../../../styles/variables";

#SessionsList {
  .layoutBorder();
  .borderRadius();

  .list-container {
    text-align: left;

    .table {
      margin-bottom: 0;

      th {
        .textSizeSmall;
        border-top-width: 0;
      }

      td {
        .textSizeMedium;
        border-top-width: 0;
      }
    }
  }
}
