/**
 * This file includes all the border variables & mixins used in the design system.
 **/

// #region: Generic Borders
.horizontalBorder(@borderStyle: @grey-color-base 1px solid) {
  border-left: @borderStyle;
  border-right: @borderStyle;
}

.verticalBorder(@borderStyle: @grey-color-base 1px solid) {
  border-top: @borderStyle;
  border-bottom: @borderStyle;
}

.customBorder(@borderStyle: @grey-color-base 1px solid, @borderTop: none, @borderRight: none, @borderBottom: none, @borderLeft: none) {
  & when not (@borderTop = none) {
    border-top: @borderStyle;
  }

  & when not (@borderRight = none) {
    border-right: @borderStyle;
  }

  & when not (@borderBottom = none) {
    border-bottom: @borderStyle;
  }

  & when not (@borderLeft = none) {
    border-left: @borderStyle;
  }
}

.border(@color: @grey-color-base, @width: 1px, @style: solid) {
  border: @style @width @color;
}

.borderRadius(@radius: 4px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}
// #endregion

// #region: Layout Borders
@layout-border-color: @grey-shade-9;
@layout-border: solid 1px @layout-border-color;

.horizontalLayoutBorder(@width: 1px, @style: solid) {
  .horizontalBorder(@style @width @layout-border-color);
}

.verticalLayoutBorder(@width: 1px, @style: solid) {
  .verticalBorder(@style @width @layout-border-color);
}

.customLayoutBorder(@borderTop: none, @borderRight: none, @borderBottom: none, @borderLeft: none, @width: 1px, @style: solid) {
  .customBorder(@style @width @layout-border-color, @borderTop, @borderRight, @borderBottom, @borderLeft);
}

.layoutBorder(@width: 1px, @style: solid) {
  border: @style @width @layout-border-color;
}
// #endregion

// #region: Card Borders
@card-border-color: @grey-shade-9; // TODO: Discuss with Design Team why this value was changed to shade-9
@card-border: solid 1px @card-border-color;

.horizontalCardBorder(@width: 1px, @style: solid) {
  .horizontalBorder(@style @width @card-border-color);
}

.verticalCardBorder(@width: 1px, @style: solid) {
  .verticalBorder(@style @width @card-border-color);
}

.customCardBorder(@borderTop: none, @borderRight: none, @borderBottom: none, @borderLeft: none, @width: 1px, @style: solid) {
  .customBorder(@style @width @card-border-color, @borderTop, @borderRight, @borderBottom, @borderLeft);
}

.cardBorder(@width: 1px, @style: solid) {
  border: @style @width @card-border-color;
}
// #endregion

// #region: Input Borders
@input-border-color: @grey-shade-7;
@input-border-hover-color: @grey-shade-5;
@input-border: solid 1px @input-border-color;

.horizontalInputBorder(@width: 1px, @style: solid) {
  .horizontalBorder(@style @width @input-border-color);
}

.verticalInputBorder(@width: 1px, @style: solid) {
  .verticalBorder(@style @width @input-border-color);
}

.customInputBorder(@borderTop: none, @borderRight: none, @borderBottom: none, @borderLeft: none, @width: 1px, @style: solid) {
  .customBorder(@style @width @input-border-color, @borderTop, @borderRight, @borderBottom, @borderLeft);
}

.inputBorder(@width: 1px, @style: solid) {
  border: @style @width @input-border-color;
}
// #endregion

// #region: Table Borders
.tableBorder(@width: 1px, @style: solid) {
  border-bottom: @style @width @layout-border-color;
}
