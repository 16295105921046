@import "../../../../../../styles/variables";

.AtTableBooleanFilter {
  .AtSwitch {
    margin-left: auto;
    margin-top: @margin-xs;
  }

  .RadioInputGroup {
    .row {
      display: flex;
    }
    .custom-control-label {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: @alternate-pixels-s;
    }
  }
}
