@import "../../../styles/variables";

.SidePanelMenuItem {
  background-color: white;
  padding: @padding-md @padding-sm;
  width: 100%;

  &.isActive {
    background-color: @grey-color-bg;
  }

  &:hover {
    cursor: pointer;
  }
}
