@import "../../styles/variables";

.PersonLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .picture {
    margin-right: 12px;
    position: relative;

    img {
      .borderRadius(100%);
    }
  }

  .person-info {
    .name {
      .textSizeMedium;
      .textWeightSemiBold;
      display: flex;
      align-items: center;

      .manager-indicator {
        .square(16px);
        .flexLayout();
        .borderRadius(2px);
        background: darken(@grey-shade-2, 10%);
        color: white;
        .textSizeSmall;
        .textWeightBold;
        margin-right: @margin-xxs;
      }
    }

    .sub-text {
      .textSizeSmall;
      .textWeightMedium;
      text-align: left;
      color: @grey-shade-4;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.active {
    .person-info {
      .name {
        .textWeightSemiBold;
        color: @primary-color-base;
      }
    }
  }
}
