@import "../../../styles/variables";

.CompDevActionPlanObjectiveRow {
  .AtCollapse-toggle-row {
    &.is-expanded {
      .competency-rank,
      .competency-name {
        color: @primary-color-base;
      }
    }

    .objective-row-header {
      .objective-description {
        .textSizeLarge;
        .textWeightSemiBold;
      }
    }

    .content-col {
      &.competency-col {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .AtCheckboxBase {
          margin-right: @margin-lg;
        }

        .chevron-container {
          margin-left: -@margin-sm;
          margin-right: (@margin-xs + @margin-xxs);
          .square(20px);
        }

        .competency-rank,
        .competency-name {
          .textWeightMedium;
          margin-bottom: 0;
        }

        .competency-rank {
          min-width: @pixels-lg;
        }

        .competency-name {
          margin-right: @margin-xs;
        }

        .tags-container {
          .flexStartLayout();

          .AtBadge:not(:last-child) {
            margin-right: @margin-xxs;
          }
        }
      }

      &.potential-col {
        display: flex;
        align-items: center;
      }
    }
  }

  @media print {
    .multiplePagePrintHack(@offset: @margin-md);
    page-break-inside: auto;

    > .AtCollapse-toggle-row {
      padding-top: 0 !important;

      .objective-row-header {
        .objective-description {
          font-family: Domine;
          font-size: 28px;
          line-height: 32px;
          .textWeightBold;
        }
      }
    }

    .AtCollapse {
      .AtCollapse-toggle-row {
        display: none;
      }

      .AtCollapse-collapse-content {
        .content {
          > * {
            .multiplePagePrintHack() !important;
            page-break-inside: auto !important;

            .ObjectiveApplication {
              .multiplePagePrintHack(@offset: @margin-md);
            }
          }

          .DocumentSection {
            .action-plan-subsection-title {
              font-family: Domine !important;
              font-size: 24px !important;
              line-height: 28px !important;
              .textWeightBold !important;
            }

            .action-plan-subsection-description {
              display: none;
            }
          }
        }
      }
    }
  }
}
