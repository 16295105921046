@import "../../../styles/variables";

.LegendSection {
  margin-top: (@margin-xs + @margin-xxs);

  > .label {
    text-transform: uppercase;
  }

  > .items-container {
    .flexStartLayout();
    flex-wrap: wrap;
  }
}
