@import "../../styles/variables";

.ProductInfo {
  display: flex;
  align-items: center;
  text-align: left;

  .product-group {
    font-size: 10px;
    color: @grey-shade-4;
  }

  .product-name {
    .textSizeSmall;
    .textWeightMedium;
    color: @grey-shade-2;
  }
}
