@import "../../styles/variables";

#ConsumptionRestrictionForm {
  text-align: left;

  .inputs {
    display: flex;

    .RadioFormInput {
      flex: 0 0 50%;
      max-width: inherit;
    }
  }

  .billing-details {
    display: flex;

    > * {
      flex: 0 0 50%;
      max-width: inherit;
    }
  }
}
