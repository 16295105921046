@import "../../styles/variables";

#EntityOverviewContainer {
  padding: @default-container-padding;

  .page-content {
    display: flex;

    .overview {
      padding: 0 @padding-sm 0 0;
      flex: 1 0 70%;

      @media @screenWidthXL {
        flex: 1 0 75%;
      }
    }

    .sidebar {
      padding: 0;
      flex: 0 0 30%;

      @media @screenWidthXL {
        flex: 1 0 25%;
      }
    }
  }
}
