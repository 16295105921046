@import "../../../styles/variables";

.StackedCircles {
  display: flex;
  justify-content: left;

  .circle {
    margin-left: -15px;
  }
}
