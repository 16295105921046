@import "../../../styles/variables";

.CircleContainer {
  width: fit-content;
  height: fit-content;
  .flexLayout();
  .border(@grey-shade-9);
  .borderRadius(100%);
  padding: 2px;
  background-color: white;
}
