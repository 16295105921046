@import "../../../styles/variables";

.StripeCreditCardForm {
  .stripe-elements-group {
    display: flex;
    margin-bottom: 1rem;
  }

  .zipCode-field-container {
    display: flex;

    .zipCode-input {
      flex: 0 0 25%;
    }
  }
}
