@import "../../../../../styles/variables";

#CompetencySetTemplateStep {
  > .header {
    margin-bottom: @margin-xs;

    .CustomTextInput {
      margin-bottom: 0;
    }
  }

  .templates-container {
    .flexLayout(stretch, flex-start);
    gap: @margin-xs;
    flex-wrap: wrap;
  }
}
