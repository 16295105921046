@import "../../../styles/variables";

.AtButtonTabs {
  width: fit-content;
  height: fit-content;
  display: flex;
  background-color: @grey-color-bg;
  padding: 2px;
  .borderRadius();

  &.border {
    padding: @padding-xxs;
    .layoutBorder();
  }

  &.round {
    .borderRadius(24px);

    > .AtButtonBase {
      .borderRadius(24px);
    }
  }

  &.fullWidth {
    width: 100%;
    justify-content: space-between;
  }

  &.fitContent > .AtButtonBase:not(:last-of-type) {
    margin-right: @margin-xs;
  }

  .AtButtonBase:not(.color-ghost) {
    font-weight: bold;
  }
}
