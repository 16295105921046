@import "../../../styles/variables";

.LearningResourceFilterContainer {
  .LearningResourceFilter {
    .bottom-section {
      padding: @padding-xs;
    }

    &:not(:last-child) {
      margin-right: @margin-xs;
      margin-bottom: @margin-xs;
    }
  }
}
