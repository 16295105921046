@import "../../../../../../../styles/variables";

#CompDevAdjustmentModal {
  padding: @padding-sm @padding-lg;
  overflow-y: auto;

  .content-container {
    background-color: white;
    .layoutBorder();
    .borderRadius();
    padding: @padding-md;

    .AtTitleSubtitle {
      margin-bottom: @margin-sm;
    }

    .privacy-warning {
      .flexLayout();
      padding: @padding-xs (@padding-xs + @padding-xxs);
      margin-bottom: @margin-md;
      .dashedBoxContainer-warning();

      .AtIcon {
        margin-right: @margin-xs;
      }

      > .content {
        .textWeightMedium;
      }
    }

    .competency-details {
      margin-bottom: @margin-sm;

      .TableHeader {
        display: inline-block;
        margin-bottom: @margin-xs;
      }

      .competency-name-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .competency-name {
          .textWeightMedium;
          margin-bottom: 0;
          margin-right: @margin-xxs;
        }
      }
    }

    .sub-competency-details {
      margin: 0 -@margin-md @margin-lg;

      .TableHeaderRow {
        margin-bottom: @margin-xxs;
      }

      .TableHeader,
      .header-col,
      .content-col {
        text-align: center;

        &.competency-col {
          flex: 1;
          text-align: left;
        }

        &.average-note-col {
          flex: 0 0 15%;
        }

        &.employee-note-col {
          flex: 0 0 10%;
        }

        &.manager-note-col {
          flex: 0 0 10%;
        }

        &.spread-col {
          flex: 0 0 12%;
        }
      }

      .details-row {
        display: flex;
        align-items: center;
        padding: 0 @padding-lg;

        .competency-col {
          .sub-competency-name-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: 100%;

            .sub-competency-name {
              max-width: 100%;
              .textWeightMedium;
              margin-bottom: 0;
              margin-right: @margin-xxs;
              .textOverflow;
            }
          }
        }
      }

      .sub-competency-description {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-3;
        margin-top: @margin-xs;
      }
    }

    .observable-behaviors-form {
      .indications-header {
        .textSizeLarge;
        .textWeightMedium;
        margin-bottom: @margin-sm;
      }

      .header {
        display: flex;
        align-items: center;
        .verticalLayoutBorder();
        margin: 0 -@margin-md;
        padding: (@padding-xs + @padding-xxs) @padding-md;
        text-transform: uppercase;
        .textSizeSmall;
        .textWeightBold;
      }

      .statements-container {
        .ObservableBehaviorStatementRow {
          &:not(:last-child) {
            border-bottom: @layout-border;
          }
        }
      }
    }
  }

  @media @screenWidthMaxSM {
    max-height: calc(100% - 120px);
    overflow-y: auto;
    padding: @padding-xs 0;

    .content-container {
      padding: @padding-sm;
      .borderRadius(0);

      .explication-header {
        .textSizeSmall;
        margin-bottom: (@margin-xs + @margin-xxs);
      }

      .competency-details {
        margin-bottom: (@margin-xs + @margin-xxs);
      }

      .sub-competency-details {
        margin: 0 0 (@margin-md - @margin-xxs);

        .TableHeader.competency-col {
          justify-content: flex-start;
        }

        .details-row {
          margin-bottom: @margin-xs;
          padding: 0;
        }

        .score-values {
          display: flex;
          align-items: center;
        }
      }

      .observable-behaviors-form {
        .indications-header {
          .textSizeMedium;
          margin-bottom: @margin-xs;
        }

        .header {
          padding: @padding-xs @padding-md;
        }
      }
    }
  }
}

.firefox,
.internetExplorer {
  #CompDevAdjustmentModal {
    .content-container {
      margin-bottom: @margin-sm;
    }
  }
}

.CompDevAdjustmentModal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media @screenWidthMaxSM {
    &.modal-footer {
      padding: 0 (@padding-xs + @padding-xxs);

      .AtButton {
        flex: 1;
        margin: 0;

        &:not(:last-child) {
          margin-right: @margin-sm;
        }
      }
    }
  }
}

@media @screenWidthMaxSM {
  .modal .modal-dialog .modal-content {
    max-height: 100%;
  }
}
