@import "../../styles/variables";

.DominantStyleResultRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 6px;

  .topContent {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: @padding-xs;
  }

  .leftContent {
    display: flex;
    align-items: center;

    .AtmanIcon {
      border: none;
    }
  }

  .AtText {
    display: flex;
    align-items: end;
    .number {
      font-size: @pixels-md;
      font-weight: 700;
      line-height: @alternate-pixels-lg;
      margin-right: @margin-xs;
    }

    .members {
      text-transform: lowercase;
      color: @grey-shade-3;
    }
  }

  .scoreBar {
    width: 100%;
    align-items: flex-end;
    height: @pixels-xs;
    padding: 0;
    .progress-bar {
      height: inherit;
    }
  }

  .AtParagraph {
    padding: @padding-xs;
  }

  // I take the color directly from figma
  &.Creatif {
    background-color: rgba(239, 150, 69, 0.08);
  }
  &.Rigoureux {
    background-color: rgba(255, 204, 77, 0.08);
  }

  &.Ambassadeur {
    background-color: rgba(127, 144, 251, 0.08);
  }
  &.Analytique {
    background-color: rgba(152, 198, 116, 0.08);
  }
  &.Bienveillant {
    background-color: rgba(142, 206, 208, 0.08);
  }
  &.Fonceur {
    background-color: rgba(214, 86, 86, 0.08);
  }

  @media print {
    //margin: 0 0 @margin-sm 0;

    .topContent {
      padding: @padding-md @padding-md @padding-xs @padding-md;
    }
    .AtParagraph {
      padding: 0 @padding-md @padding-xs @padding-md;
    }
    .AtText {
      .number {
        font-size: @pixels-lg;
      }
    }
  }
}
