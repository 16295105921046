@import "../../styles/variables";

#AssignmentForm {
  .AtTableRowCell:last-child {
    justify-content: end;
  }

  .AtTable {
    .AtTableBase.isEmpty {
      min-height: 300px;
    }
  }
}
