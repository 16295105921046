@import "../../../../styles/variables";

.EntityOverviewCard {
  .content {
    padding: @padding-sm;

    @media @screenWidthXL {
      padding: @padding-md @padding-sm;
    }
  }
}
