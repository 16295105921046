@import "../../../styles/variables";

.FitScoreMapDisplay {
  .dimensionLabels-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .dimensions-container {
      .AtBadge {
        &.very-good-fit,
        &.good-fit,
        &.be-vigilent {
          margin: @margin-xs 0;
          display: table;
        }

        &.very-good-fit {
          color: #fff;
        }
      }
    }
  }
}
