@import "../../styles/variables";

.AtCustomizableCard {
  padding: @padding-sm @padding-md;
  .cardBorder();
  .borderRadius();
  background: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 16px;

      &.has-icon {
        justify-content: space-between;
      }
    }

    .titles-container {
      margin-bottom: 16px;

      .title {
        margin-bottom: 4px;
      }

      .subtitle {
        .textWeightBold;
        font-size: 12px;
        color: @grey-shade-5;
        text-transform: uppercase;
      }
    }

    .properties-container {
      margin-bottom: 16px;
      flex: 1;
      &.top-properties-container {
      }

      &.bottom-properties-container {
      }
    }

    .additional-properties-container {
      margin-bottom: 16px;
    }

    .completion-info-container {
      margin-bottom: 16px;

      .completion-rate-label {
        .percentage {
          .textSizeMedium;
          .textWeightBold;
        }

        .fraction {
          .textSizeMedium;
          .textWeightBold;

          & ~ .percentage {
            .textSizeSmall;
            .textWeightRegular;
            color: @grey-shade-3;
          }
        }
      }

      .progress {
        height: 8px;

        .progress-bar {
          background: @green-color-base;
        }
      }
    }
  }
}

.additional-properties-tooltip-content {
  padding: @padding-sm @padding-xs;
  text-align: left;

  .header-label {
    .textSizeMedium;
    .textWeightBold;
    margin-bottom: 16px;
  }

  .properties-list {
    .property-item-container {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .property-label {
        .textSizeSmall;
        .textWeightRegular;
        color: @grey-shade-3;
        display: block;
        margin-bottom: 4px;
      }

      .property-value {
        .textWeightMedium;
      }
    }
  }
}

.internetExplorer {
  .AtCustomizableCard {
    display: block;

    .top-section {
      display: block;
    }
  }
}
