@import "../../../../styles/variables";

.AtTable {
  display: flex;
  flex-flow: column;
  flex: 1 0;
  max-height: 100%;
  overflow: auto;
  table {
    width: 100%;
  }

  .topBar {
    &.doNotShowSearchBar {
      display: flex;
      justify-content: start;
      margin-bottom: @margin-xxs;
    }

    .flexLayout();
    margin-bottom: @margin-sm;
    flex: 0 0;
    z-index: 100;

    .tableViewButton {
      &:active,
      &.active,
      &:hover {
        background-color: @grey-color-bg;
        border: none;
      }
    }

    > * {
      margin-bottom: 0;
      margin-right: @margin-xs;

      &:last-child {
        margin-right: 0;
      }
    }

    .search {
      width: 100%;
      margin-bottom: 0;
      margin-right: @pixels-xs;
    }

    .right-icon-container {
      position: absolute;
      display: inline-block;
      top: ~"calc(50% - 8px)";
    }

    .right-icon-container {
      right: 10px;

      &.hasOnClickEvent {
        cursor: pointer;
      }
    }
    .AtSelect {
      .AtSelectBase__control,
      .AtSelectBase__menu {
        .AtCheckboxBase {
          display: none;
        }
      }
    }
  }
}
