@import "../../../../styles/variables";

.AtStepperStep {
  border-left: none;
  border-right: none;

  .AtCollapse-toggle-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .progress-number {
      .flexLayout();
      .minSquare(@pixels-md);
      .borderRadius(100%);
      .textSizeLarge;
      .textWeightBold;
      margin-right: @margin-sm;

      .border(@grey-shade-7);
      color: @grey-color-base;
      background-color: @grey-color-bg;

      &.active {
        .border(@primary-color-base);
        color: @primary-color-base;
        background-color: @primary-color-bg;
      }

      &.completed {
        .border(@green-color-base);
        color: @grey-color-base;
        background-color: @green-color-bg;
      }
    }

    > .info-container {
      > .description {
        .textSizeMedium;
        .textWeightMedium;
        color: @grey-shade-4;
      }
    }

    > .additional-header-content {
      margin-left: @margin-xs;
      flex: 1;
      text-align: right;
      .textSizeSmall;
      .textWeightSemiBold;
    }
  }

  > * > .content {
    padding: @padding-md @padding-xl;
  }
}

.internetExplorer {
  .AtCollapse-toggle-row {
    > .additional-header-content {
      flex: 1 1 auto;
    }
  }
}
