@import "../../../../../../styles/variables";

#CompetencyDevelopmentAssessmentWelcomeScreen {
  .layoutBorder();
  .borderRadius();
  background-color: white;
  padding: @padding-md @padding-lg;
  max-width: @container-max-width-lg;

  .assessment-title {
    margin-bottom: @margin-xs;
  }

  .assessment-description {
    margin-bottom: @margin-md;
  }

  .CandidateIdentityView {
    margin-bottom: @margin-md;

    .info-container {
      .name,
      .job-name {
        max-width: 100%;
      }
    }
  }

  .example-container {
    margin-bottom: @margin-xxxl;

    .example-statement-title {
      margin-bottom: 0;
    }

    .example-statement-description {
      margin-bottom: @margin-lg;
    }

    .LikertScales,
    #LikertLegend {
      max-width: 450px;
      margin: 0 auto;
    }

    #LikertLegend {
      margin-bottom: @margin-sm;
    }
  }

  .button-container {
    .flexLayout();
  }
}
