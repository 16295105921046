@import "../../../styles/variables";

.AtButtonBase {
  line-height: 1 !important;

  &.btn {
    &.rounded {
      .borderRadius(8px);
    }

    .borderRadius();
    .textWeightSemiBold;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default !important;
  }

  &.color-primary.btn-primary {
    .textWeightMedium;
    @textColor: white;
    @borderColor: @primary-color-base;

    background-color: @primary-shade-1;
    color: @textColor;
    .border(@borderColor);

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:focus {
        background-color: @primary-color-base;
      }

      &:focus,
      &:active:focus {
        .formBoxShadow(fade(@primary-color-base, 40%));
      }

      &:active {
        background-color: @primary-shade-2;
      }
    }
  }

  &.color-primary-reverse.btn-primary-reverse {
    .textWeightMedium;
    @textColor: @primary-color-base;
    @borderColor: #eaeaee;

    background-color: white;
    color: @textColor;
    .border(@borderColor);
    box-shadow: 0px 1px 2px #00000014;

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:focus {
        background-color: white;
        color: @textColor;
        .border(@borderColor);
        box-shadow: 0px 1px 2px #00000014;
      }

      &:active {
        background-color: @primary-shade-2;
      }
    }
  }

  &.color-secondary.btn-secondary {
    @textColor: @grey-color-base;
    @bgColor: white;
    @borderColor: @grey-shade-8;

    background-color: @bgColor;
    color: @textColor;
    .border(@borderColor);

    &:not(:disabled):not(.disabled) {
      @hoverBorderColor: @grey-shade-5;

      &:hover,
      &:focus {
        .border(@hoverBorderColor);
      }

      &:focus,
      &:active:focus {
        .formBoxShadow(fade(@hoverBorderColor, 40%));
      }

      &:active,
      &:active:focus {
        background-color: @bgColor;
        color: @textColor;
      }
    }
  }

  &.color-ghost.btn-secondary {
    @textColor: @grey-color-base;
    @bgColor: transparent;
    @borderColor: transparent;

    background-color: @bgColor;
    color: @textColor;
    .border(@borderColor);

    &:not(:disabled):not(.disabled) {
      @hoverBorderColor: @grey-shade-5;

      &:hover,
      &:focus {
        color: @textColor;
        .border(@hoverBorderColor);
      }

      &:focus,
      &:active:focus {
        .formBoxShadow(fade(@hoverBorderColor, 40%));
      }

      &:active,
      &:active:focus {
        background-color: @bgColor;
        color: @textColor;
      }
    }
  }

  &.color-tertiary.btn-light {
    @textColor: @grey-color-base;
    @borderColor: @grey-shade-8;

    background-color: @grey-color-bg;
    color: @textColor;
    .border(@borderColor);

    &:not(:disabled):not(.disabled) {
      @hoverBorderColor: @grey-shade-5;

      &:hover,
      &:focus {
        .border(@hoverBorderColor);
        background-color: @grey-shade-9;
      }

      &:focus,
      &:active:focus {
        .formBoxShadow(fade(@hoverBorderColor, 40%));
      }

      &:active,
      &:active:focus {
        background-color: @grey-shade-9;
        .border(@hoverBorderColor);
        color: @textColor;
      }
    }
  }

  &.color-danger {
    @textColor: @red-color-base;
    @bgColor: white;
    @borderColor: @grey-shade-8;

    background-color: @bgColor;
    color: @textColor;
    .border(@borderColor);

    &.has-icon {
      @textColor: @grey-color-base;

      color: @textColor;

      svg {
        color: @red-color-base;
      }

      &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus {
          color: @textColor;
        }

        &:active,
        &:active:focus {
          background-color: @bgColor;
          color: @textColor;
        }
      }
    }

    &:not(:disabled):not(.disabled) {
      @hoverBorderColor: @grey-shade-5;
      @focusBorderColor: @red-color-base;

      &:hover,
      &:focus {
        background-color: @bgColor;
        color: @textColor;
        .border(@grey-shade-5);
      }

      &:focus,
      &:active:focus {
        .border(@focusBorderColor);
        .formBoxShadow(fade(@focusBorderColor, 40%));
      }

      &:active,
      &:active:focus {
        background-color: @bgColor;
        color: @textColor;
        .border(@focusBorderColor);
      }
    }

    &.btn-primary {
      @textColor: white;
      @bgColor: @red-color-base;

      background-color: @bgColor;
      color: @textColor;

      &.has-icon {
        color: @textColor;

        &:not(:disabled):not(.disabled) {
          &:hover,
          &:focus {
            color: @textColor;
          }

          &:active,
          &:active:focus {
            color: @textColor;
          }
        }
      }

      &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus {
          background-color: @bgColor;
          color: @textColor;
        }

        &:active,
        &:active:focus {
          color: @textColor;
        }
      }
    }
  }
}
