@import "../../../styles/variables";

.BaseAtScaleGroup {
  margin-bottom: @margin-md;

  .group-name {
    text-align: left;
  }

  .results-container {
    margin: 0 auto;
  }

  @media print {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
