@import "../../../styles/variables";

.SubMenuItem {
  height: 100%;

  > .view-container {
    padding: @padding-xs 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .view-header,
    > .view-content {
      width: 100%;
      max-width: @container-max-width-xl;
      margin: 0 auto;
      height: 100%;
      .TagsList,
      #TransactionsList,
      #CulturesContainer,
      #JobsContainer,
      #UserManagement,
      .TeamsList,
      #JobFitsList,
      #CultureFitsList,
      #PerformanceEvaluationsList {
        display: flex;
        flex-direction: column;
        .TableItemHeading {
          flex: 0 0;
        }

        .AtContainer.list {
          flex: 1 0;
          overflow: hidden;
          .AtTabs {
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .content.contentTable {
              flex: 1 0;
              overflow: hidden;
            }
          }
        }

        @media @screenWidthMD {
          max-width: 500px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthLG {
          max-width: 570px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXL {
          max-width: 835px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXXL {
          max-width: 930px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXXXL {
          max-width: 1100px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }
      }

      #SsoConfigurationForm,
      #OrgCustomizationSettings {
        height: 100%;
        overflow: auto;
        @media @screenWidthXXL {
          max-width: 1038px;
          margin: 0 auto;
        }
      }

      .QuickLinksList,
      .WorkspacesList,
      .PaymentMethodsList {
        .AtTable {
          .AtTableBase.isEmpty {
            height: 50vh;
          }
        }

        @media @screenWidthMD {
          max-width: 500px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthLG {
          max-width: 570px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXL {
          max-width: 835px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXXL {
          max-width: 930px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }

        @media @screenWidthXXXL {
          max-width: 1100px;
          margin: 0 auto;
          padding: @pixels-xs 0;
        }
      }

      .TeamsList {
        padding: 0;
      }

      #CulturesContainer,
      #JobsContainer {
        height: 100%;
        overflow: hidden;
        .cards-container {
          overflow: auto;
        }
      }
      .cards-container {
        height: 100%;
        .AtTable {
          height: 100%;
        }
      }
      .QuickLinksList,
      .WorkspacesList {
        .AtTable {
          display: flex;
          overflow: auto;

          @media only screen and (min-width: 1400px) {
            height: fit-content;
          }

          @media only screen and (min-width: 1200px) {
            height: fit-content;
          }
        }
      }

      #UsersAuthenticationSettings {
        @media @screenWidthXXL {
          max-width: 1038px;
          margin: 0 auto;
        }
      }
    }

    > .view-header {
      flex: 0 0;
      margin-bottom: @margin-md;
    }

    > .view-content {
      flex: 1 0;
    }
  }
}

.firefox,
.internetExplorer {
  .SubMenuItem {
    > .view-container {
      > .view-content {
        > *:last-child {
          margin-bottom: @margin-xl;
        }
      }
    }
  }
}

.internetExplorer {
  .SubMenuItem {
    > .view-container {
      display: block;
    }
  }
}
