@import "../../../styles/variables";

.SubMenuNavItem {
  a {
    padding: 12px 0;
    .flexLayout();
    .textWeightBold;
    color: @grey-color-base;

    &:active,
    &.active {
      color: @primary-color-base;
    }

    &:hover {
      text-decoration: none;
      color: @primary-color-base;
    }

    @media @screenWidthMD {
      .flexStartLayout();
    }

    .icon-container {
      .square(20px);
      .flexLayout();

      @media @screenWidthMD {
        margin-right: @margin-xs;
      }
    }
  }
}
