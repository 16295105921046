@import "../../styles/variables";

.AtSimpleCard {
  padding: @padding-sm @padding-md;
  .cardBorder();
  .borderRadius();
  background: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .top-section {
    width: 100%;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 16px;

      .icon-container {
        .flexLayout();
        .square(32px);
        .borderRadius(2px);
      }
    }

    .description-container {
      margin-top: 16px;
      .textSizeMedium;
      .textWeightMedium;
      color: @grey-shade-3;
    }
  }

  .bottom-section {
    width: 100%;

    .actions-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;

      .AtButton {
        .textWeightBold;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}
