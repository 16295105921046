@import "../../../../../../styles/variables";

.SearchHelper {
  margin: @margin-lg @margin-sm 0 @margin-sm;

  .IconTitleSubtitle {
    .AtTitle,
    .AtSubtitle {
      text-align: center;
    }
  }
}
