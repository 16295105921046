@import "../../../styles/variables";

.InputHeader {
  text-align: left;

  .header {
    .requirement-label {
      .textWeightRegular;
      .textSizeMedium;
      margin-left: @margin-xs;
    }
  }

  .header-sub-content {
    display: flex;
    align-content: center;
    justify-content: space-between;

    .MaxNumberCounter {
      .textSizeSmall;
      .textWeightMedium;
      color: @grey-shade-3;
    }

    .MaxNumberCounter {
      padding-right: 6px;
    }
  }
}
