@import "../../../styles/variables";

.LikertScales {
  @media @screenWidthMaxSM {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ScaleCell {
      padding: 10px;
      .inputBorder;
      .borderRadius();

      &.selected {
        border-color: @primary-color-base;
      }

      .RadioFormInput {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        margin-bottom: 0;

        > label {
          width: 100%;
          .border(#BBBBBB);
          .borderRadius();
          padding: 14px 12px;
        }

        &.checked {
          > label {
            .border(@primary-color-base);
          }
        }
      }
    }
  }

  @media @screenWidthMD {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .radio-container {
      flex: 1 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      .CustomStandaloneRadioInput {
        padding-left: 0;
      }
    }
  }
}

.custom-control-label {
  &::after {
    background: ~"no-repeat 50% / 70% 70%";
  }
}
