@import "../../../styles/variables";

.AtCheckbox {
  .borderRadius(2px);
  background: #ffffff;
  text-align: left;
  .defaultInputBorderStyles;

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:focus {
    .border(@input-border-hover-color);
  }

  &.checked {
    .border(@primary-color-base);
  }

  &.form-check {
    padding-left: 0;
  }

  .form-check-label {
    padding: @padding-xs @padding-sm;
    .flexLayout(center, flex-start);
    margin-left: 0 !important;
    cursor: pointer;
    .textWeightMedium;

    &.disabled {
      opacity: 0.4;
      user-select: none;
      cursor: default;
    }
  }
}
