@import "../../../../styles/variables";

.EntityOverviewCardLine {
  .textSizeSmall;
  padding: @padding-xs 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #fafafa;

  .left-section {
    .textWeightRegular;
    color: #818181;

    svg {
      margin-right: 8px;
    }
  }

  .right-section {
    .textWeightMedium;
    color: @grey-shade-2;
  }

  @media @screenWidthXL {
    .textSizeMedium;
    margin-bottom: 24px;
  }
}
