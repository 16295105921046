@import "../../styles/variables";

.CompetencySelectionForm {
  &.fullWidth {
    .search {
      margin: @margin-lg @margin-lg @margin-sm @margin-lg;
    }
  }

  .suggestedCompetencies {
    text-align: left;
    margin-bottom: @margin-xs;

    > .AtTitle {
      margin-bottom: @margin-sm;
    }

    .checkableCardsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: @margin-sm;

      > .AtCheckableCard {
        align-items: flex-start;

        flex: 0 0 100%;
        max-width: 100%;

        @media @screenWidthXXL {
          flex: 0 0 calc(50% - @margin-xs);
          max-width: calc(50% - @margin-xs);
        }

        .AtContainer-header {
          max-width: 100%;
          align-items: center;
        }

        .checkableCardContent {
          .flexLayout(center, flex-start);
          min-width: 0;
          gap: @margin-sm;

          > .AtTitle {
            .textMaxLines();
          }
        }
      }
    }
  }

  .search {
    margin-bottom: @margin-xs;
    .flexStartLayout();

    .searchString-input {
      flex: 1;
      margin-bottom: 0;
      margin-right: @margin-xs;

      &.has-icon svg {
        color: @primary-color-base;
      }
    }

    .AtSelect {
      min-width: (@pixels-eighty + @pixels-xxl);
      margin-bottom: 0;

      .AtSelect__control {
        height: @pixels-xl; // TODO Simon 2021-08-02 - Rework size prop instead of overriding the height here (currently either 32px (sm) or 46px (md))
      }
    }
  }

  .competency-groups-container {
    &.isSearching {
      margin-left: @margin-lg;
      margin-right: @margin-lg;
    }

    .CompetencyGroupFormSection {
      &:not(:last-child) {
        margin-bottom: @margin-xs;
      }
    }
  }
}
