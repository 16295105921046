@import "../../../../styles/variables";

.AtTableBase {
  border-radius: @pixels-xs;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &.displayType_cards {
    display: inline-flex;
    flex-wrap: wrap;
    background: none;
    height: auto;
  }

  table {
    width: 100%;
  }

  &.isClickable {
    .AtTableRow:hover {
      cursor: pointer;
      background-color: @grey-color-bg;
    }
  }

  .AtTableRow {
    .AtTableRowCell.checkbox:first-child {
      padding-left: @pixels-xxs;
    }
    .AtTableRowCell:first-child {
      padding-left: @pixels-sm;
    }

    .AtTableRowCell:last-child {
      padding-right: @pixels-sm;
    }

    &.hasHoverAction {
      &.isHovered {
        > .AtTableRowCell {
          &:first-child {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .loading {
    width: 100%;
    margin-top: @margin-xxxl;
  }

  &.isEmpty {
    height: 100%;
    min-height: 400px;
    position: relative;
    .AtTitleSubtitle {
      width: auto;
      padding: 0 @padding-md;
      text-align: center;
    }
    .table-body {
      display: block;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    table {
      display: block;
    }

    .AtTableHeader {
      display: block;

      tr {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .AtTableHeaderCell {
        display: flex;
        &.checkbox {
          padding-left: @padding-xxs;
        }
        &.isHidden {
          display: none;
        }
      }
    }
  }

  .emptyState {
    margin: auto;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    background-color: @almost-white-color;
    padding: @padding-xxxl;

    .icon-container {
      margin-bottom: @alternate-pixels-xs;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: initial;
      height: @pixels-xxl;
      width: @pixels-xxl;
      border-radius: @alternate-pixels-xs;
      background: #f5f5f5;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}
