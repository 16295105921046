@import "../../../../global/styles/variables";

.AcceptOrDenyConfirmation {
  .LoginComponentHeader {
    display: block;
    .AtTitle {
      margin-top: @margin-sm;
    }
  }

  .AtTitleSubtitle {
    border-radius: 6px;
    margin-bottom: @margin-sm;
    margin-top: @margin-xs;
    padding: @padding-sm;
  }
}
