@import "../../styles/variables";

.CustomRadioInputGroup {
  text-align: left;

  > label {
    margin-bottom: @padding-sm;
  }

  .inputs {
    &.flex-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
