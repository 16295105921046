@import "../../../../styles/variables";

.AtWizardFooter {
  min-height: 56px;
  padding: 8px @padding-md;
  background-color: white;
  border-top: @layout-border;

  > .content {
    .flexLayout();
    width: 100%;
    max-width: @container-max-width-lg;
    margin: 0 auto;

    > .sub-step-progress-container {
      flex: 1;
    }

    > .back-btn-container {
      justify-content: flex-start;
    }

    > .next-btn-container {
      justify-content: flex-end;
    }

    @media @screenWidthMaxSM {
      flex-wrap: wrap;

      > .back-btn-container {
        width: 50%;
        order: 2;
      }

      > .sub-step-progress-container {
        width: 100%;
        flex: initial;
        order: 1;
        margin-bottom: @margin-xs;

        .SubStepProgressionComponent .progress {
          max-width: none;
        }
      }

      > .next-btn-container {
        width: 50%;
        order: 3;
        text-align: right;
      }
    }
  }
}
