@import "../../styles/variables";

.OrderSummary {
  .textSizeMedium;
  .textWeightMedium;

  .order-summary-container {
    .cardBorder();
    .borderRadius;
    padding: 0;
    margin-bottom: 48px;

    .price-col {
      text-align: right;
      .textSizeMedium;
      .textWeightMedium;
    }

    .header-row {
      padding: @padding-sm;
      border-bottom: @card-border;

      .table-header {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-4;
      }
    }

    .discount-rate-row {
      padding: @padding-sm;
      border-top: @card-border;
    }

    .subtotal-row {
      padding: @padding-sm;
      border-top: @card-border;
    }

    .tax-row {
      padding: @padding-xs @padding-sm;
    }

    .footer-row {
      margin-top: @padding-sm;
      padding: @padding-sm;
      border-top: @card-border;

      .total-label {
        .textSizeMedium;
        .textWeightBold;
      }

      .total-price {
        .currency {
          .textSizeSmall;
          .textWeightMedium;
          color: @grey-shade-4;
          margin-right: 12px;
        }

        .total-price-value {
          .textSizeMedium;
          .textWeightBold;
        }
      }
    }
  }

  .error-container {
    .alert {
      margin-top: 8px;
    }
  }

  .btn-container {
    margin-top: @padding-md;
    display: flex;
    justify-content: flex-end;
  }
}
