@import "../../../../styles/variables";

#EntityWizardOverview-competency-list {
  .flexLayout(initial, initial, column);
  overflow-y: auto;

  > *:not(:last-child) {
    margin-bottom: @margin-xxs;
  }

  > .AtHint {
    min-height: @pixels-xxl;
  }
}
