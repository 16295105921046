@import "../../../../../styles/variables";

.ScoreContainer {
  position: absolute;
  height: 100%;
  background-color: transparent;
  z-index: 1;

  .score-indicator {
    z-index: 10;
    .flexLayout();

    .distribution-count {
      color: white;
      .textSizeSmall;
    }

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .InitialsAvatar {
    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .distribution-count {
    color: @grey-color-base;
    .textSizeMedium;
    .textWeightBold;
  }

  &.scale-type-bipolar {
    .flexLayout();

    .score-indicator {
      .square(20px);
      .borderRadius(50%);
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
  }

  &.scale-type-unipolar {
    .score-indicator {
      height: (@pixels-xs + @pixels-xxs);
      .borderRadius(7px);
    }
  }

  &.separator {
    border-right: 1px solid @grey-shade-9;
  }
}

@media print {
  .ScoreContainer {
    &.scale-type-unipolar {
      .score-indicator {
        height: @pixels-md;
      }
    }
  }
}
