@import "../../styles/variables";

.HelpCenterInfoContained {
  text-align: left;
  max-width: 100%;

  @media @screenWidthMD {
    max-width: 240px;
  }

  @media @screenWidthLG {
    max-width: 280px;
  }

  @media @screenWidthXL {
    max-width: 304px;
  }

  > .header {
    .flexLayout(flex-start);
    text-align: left;

    .AtIcon {
      margin-right: @padding-xs;
    }

    .AtTitle {
      .textWeightBold;
    }
  }

  .AtParagraph {
    color: @grey-shade-3;
    margin-bottom: @margin-sm;
  }
}
