@import "../../styles/variables";

.BaseModalContainer {
  .fontDefault;
  overflow-y: hidden !important;

  .modal-header {
    height: 56px;
    flex: 0 1 auto;
    border-bottom: 1px @card-border-color solid;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;

    .modal-title {
      color: @grey-color-base;
      .textWeightBold;
    }

    .btn-link {
      color: @grey-color-base;
    }
  }

  .modal-footer {
    height: 64px;
    flex: 0 1 auto;
    border-top: 1px @card-border-color solid;
    padding: 0 @padding-xxl;
  }

  .modal-body {
    background: @bg-color;
  }

  .group {
    background: white;
    padding: 15px 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
    position: relative;

    label {
      font-size: 15px;
    }
  }

  @media @screenWidthMaxSM {
    .modal-header {
      button.close {
        display: none;
      }
    }
  }
}
