@import "../../../../../../styles/variables";

.DevelopmentPlanCompetencyResultTable {
  .table-filter-header {
    padding: @padding-md @padding-xl;

    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .AtSelect {
        min-width: 160px;
        margin-bottom: 0;
      }
    }
  }

  .show-all-competencies-container {
    padding: @padding-sm @padding-xl;
    .flexStartLayout;
  }

  .AtCollapse {
    &.left-collapse-content-offset {
      > .AtCollapse-collapse-content {
        > .content {
          border-left: none;
        }
      }
    }

    @media @screenWidthMaxSM {
      .table-filter-header {
        padding: @padding-xs @padding-sm;
      }
    }
  }
}
