@import "../../../../../styles/variables";

.ScoreLineContainer {
  position: relative;
  height: @pixels-md;
  background: @bg-color;
  width: 100%;
  .borderRadius();

  .range-container {
    @bgColor: fade(@grey-shade-2, 30%);

    position: absolute;
    top: -2px;
    height: (@pixels-md + @pixels-xxs);
    opacity: 0.5;
    .border(@color: @bgColor, @width: 1px);

    background: @bgColor;
    .borderRadius();

    &.is-inverted {
      background: repeating-linear-gradient(-60deg, @bgColor, @bgColor 5px, transparent 5px, transparent 10px);
    }
  }

  &.scale-type-unipolar {
    height: (@pixels-xs + @pixels-xxs);
    .borderRadius(8px);

    .range-container {
      height: @pixels-sm;
      top: -2px;
      .borderRadius(8px);

      & ~ .ScoreContainer {
        left: 1px !important;
      }
    }
  }
}

@media print {
  .ScoreLineContainer {
    background: @grey-shade-9;
    &.scale-type-unipolar {
      height: @pixels-md;
    }
  }
}
