@import "../../../styles/variables";

#LeverageAssessmentContent {
  .AtWizardStepHeader {
    margin-bottom: @margin-md;
  }

  .leveraging-objectives-container {
    .sub-comp-scores-container {
      padding: @padding-sm @padding-xl @padding-sm;

      h5 {
        margin-bottom: (@margin-xs + @margin-xxs);
      }

      .AverageScoreDisplayWithLabel {
        &:not(:last-child) {
          margin-right: (@margin-md - @margin-xxs);
          margin-bottom: @margin-xxs;
        }

        span {
          .textSizeMedium;
          .textWeightMedium;
        }
      }
    }

    > h5 {
      padding: 0 @padding-xl;
    }
  }

  .AtContainer {
    .IconTitleSubtitle {
      padding: @padding-sm @padding-xl @padding-xs;
    }
  }

  @media @screenWidthMaxSM {
    .AtWizardStepHeader {
      margin-bottom: 0;
      padding: @padding-md @padding-sm (@padding-xs + @padding-xxs) @padding-sm;
    }
  }
}
