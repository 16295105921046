@import "../../styles/variables";
.AtStylesTabView {
  .AtTabs {
    .AtButtonTabs {
      margin: 0;
      background: white;
      .AtButtonBase {
        background: white;
        border: 0px;
        &:not(:disabled):not(.disabled) {
          &:hover,
          &:focus,
          &:active:focus {
            border: 0px;
            .formBoxShadow(@blurRadius: 0px);
          }
        }

        .atmanStyleButton {
          display: flex;
          align-items: center;
          .flexLayout();
          margin-bottom: -@alternate-pixels-xs;

          .AtmanIcon {
            margin-right: @margin-sm;
          }
        }
      }
    }

    .content.contentTable > .AtStyleView {
      .SummaryCard,
      .summaryCardSection,
      .atCollapsibleCardBehaviorSection,
      .AtCollapsibleCardBehavior.AtCollapsibleCard,
      .AtContainerListSection > .AtContainerList.AtContainer {
        margin-bottom: 0;
        border: none;
        border-radius: 0;
      }

      .AtCollapsibleCardBehavior {
        .header {
          border: none;
        }
      }

      .AtContainerListSection {
        background-color: #fff;
      }

      .AtContainerListSection > .AtContainerList {
        .AtContainer-header.isUnderlined,
        .listContainer > .item {
          border: none;
          margin-bottom: 0;
        }

        .AtContainer-header {
          margin-left: @margin-xs;
        }

        .listContainer {
          list-style: disc;
          .item {
            padding: @pixels-xs 0;
            margin: 0 @pixels-xxl;
          }
        }
      }
    }
  }
}
