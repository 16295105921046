@import "../../../styles/variables";

.OrderSummaryItem {
  padding: @padding-sm;

  .product-name {
    display: flex;

    .label {
      margin-left: 0;
      display: flex;
      align-items: center;
    }
  }

  .quantity {
    display: flex;
    align-items: center;
  }

  .discount {
    display: flex;
    align-items: center;

    .discount-rate {
      .textSizeSmall;
      .textWeightMedium;
      .borderRadius(2px);
      padding: @padding-xxs @padding-xs;
      background: #94f6bf;
    }
  }

  .price-per-unit {
    .textSizeSmall;
    .textWeightRegular;

    .regular-price {
      text-decoration: line-through;
      margin-right: 4px;
    }
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
