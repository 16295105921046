@import "../../styles/variables";

.ReportLegendContainer {
  .ReportLegendItem {
    &.ReportCandidateLegendItem {
      padding: @padding-xxs (@padding-xs + @padding-xxs) @padding-xxs @padding-xxs;
      margin-right: @margin-xs;
    }
  }
}
