@import "../../../../../styles/variables";

.TableItemHeading {
  display: flex;
  padding: @padding-md;
  margin-bottom: @margin-xxs;
  justify-content: space-between;
  align-items: center;

  .icon-title-subtitle-container {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .icon-container {
      .flexLayout();
      .square(40px);
      .borderRadius(8px);

      background: #f5f5f5;
      margin-right: @margin-xs;
      flex-shrink: 0;
      margin-right: @alternate-pixels-xs;
      box-shadow: rgb(000 / 8%) 0px 1px 0px 1px;

      svg {
        color: @grey-color-base;
      }
    }
  }

  .tableItemHeading-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .AtButton:first-child {
    margin-right: @margin-xxs;
  }

  .AtButton:last-child {
    margin-left: @margin-xxs;
  }
}
