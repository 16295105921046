@import "../../../styles/variables";

.ProductElement {
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: @margin-xl;
  }

  .product-name-prefix,
  .product-name-suffix {
    display: inline-block;
    color: @grey-color-base;

    &.product-name-prefix {
      .textWeightBold;
    }

    &.product-name-suffix {
      .textWeightMedium;
    }
  }

  &.active {
    .product-name-prefix,
    .product-name-suffix {
      color: @primary-color-base;
    }
  }

  &.disabled {
    pointer-events: none;

    .product-name-prefix,
    .product-name-suffix {
      opacity: 0.6;
    }
  }

  &:hover:not(.active) {
    .active-indicator {
      background-color: @grey-shade-7;
    }
  }

  .active-indicator {
    height: 3px;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    background-color: @grey-shade-9;

    &.active {
      background-color: @primary-color-base;
    }
  }
}
