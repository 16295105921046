@import "../../../styles/variables";

.CustomSidebarNavItem {
  &:active,
  &.active,
  &:hover {
    background-color: @grey-color-bg;
  }

  a {
    display: block;
    padding: 18px 0;

    .icon-container {
      font-size: 20px;
      line-height: 20px;

      svg {
        color: @grey-color-base;
      }
    }

    @media @screenWidthMaxSM {
      display: flex;
      align-items: center;

      .icon-container {
        margin-left: @margin-sm;
      }

      .label {
        margin-left: @margin-sm;
        color: @grey-color-base;
        .textWeightMedium;
      }
    }
  }
}
