@import "../../../styles/variables";

.AtBaseCard {
  background: #ffffff;
  .cardBorder();
  .borderRadius();
  flex: 1 0 auto;

  h4 {
    .textWeightBold;
  }

  > label,
  .cardContainer {
    width: 100%;
    height: 100%;
    padding: @padding-md @padding-sm;
    display: block;
    position: relative;
    margin-bottom: 0;
  }
}
