@import "../../../styles/variables";

#AtQuickSearch {
  position: relative;
  display: flex;
  margin: 0;

  .search-icon-container {
    .flexLayout();
    width: 12px;
    cursor: pointer;

    &:hover {
      .search-icon {
        color: @primary-color-base;
      }
    }

    .search-icon {
      margin: 0;
      color: @grey-shade-2;
      z-index: 2;
    }
  }

  &.is-expanded {
    .search-icon-container {
      .search-icon {
        color: @primary-color-base;
      }
    }
  }

  &.is-not-expanded {
    .input-container {
      width: 0;

      .react-autosuggest__container,
      .react-autosuggest__input {
        width: 0;
        padding: 0;
        border-width: 0;
      }
    }
  }

  .input-container {
    margin-left: -28px;
    width: 22vw;

    .status-icon-container {
      position: absolute;
      right: 16px;
      top: 8px;
      z-index: 1;

      a {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 2px;
      }
    }

    .react-autosuggest__container {
      width: 22vw;
      position: relative;
      z-index: 1;
    }

    .react-autosuggest__input {
      width: 22vw;
      height: 40px;
      margin-top: 0;
      padding: 6px 12px 6px 40px;
      font-family: "Quicksand", sans-serif;
      font-weight: 500;
      font-size: 12px;
      .inputStyles;
      background: white;

      &::-ms-clear {
        width: 0;
        height: 0;
      }

      @media @screenWidthMD {
        font-size: 14px;
      }

      &:hover {
        .border(@input-border-hover-color);
      }
    }

    .react-autosuggest__input--focused {
      outline: none;
      .border(@primary-color-base);
      .formBoxShadow();

      &:hover {
        .border(@primary-color-base);
        .formBoxShadow();
      }

      &::placeholder {
        color: transparent;
      }

      @media @screenWidthMD {
        line-height: 32px;
      }
    }

    .react-autosuggest__input--open {
      .border(#818181);
    }

    .react-autosuggest__suggestions-container {
      display: none;
      position: absolute;
      left: 0;
      top: 49px;
      width: 100%;
    }

    .react-autosuggest__suggestions-container--open {
      display: block;
      .border(#818181);
      background-color: #fff;
      font-weight: 300;
      font-size: 16px;
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      .borderRadius();
      .boxShadow(4px);
    }

    .react-autosuggest__suggestions-list {
      margin: 0;

      padding: (@padding-xxs + @padding-xs) 0;

      list-style-type: none;
      z-index: 10;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      font-size: 14px;

      .suggestion-container {
        height: auto;
        padding: (@padding-xxs + @padding-xs);
        display: flex;
        align-items: center;
        position: relative;

        .type-container {
          display: flex;
          flex: 0 0 20%;

          .icon-container {
            width: 24px;
            color: @grey-shade-4;
            margin-right: 4px;

            &.picture {
              top: ~"calc(50% - 15px)";
            }
          }

          .type {
            font-size: 12px;
          }
        }

        .label {
          flex: 1 0 20%;
          text-align: left;
          .textWeightMedium;
        }

        .ownerName {
          flex: 1 0 35%;
          text-align: left;
        }
      }
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: @grey-color-bg;
    }

    .react-autosuggest__section-container {
    }

    .react-autosuggest__section-container--first {
      border-top: 0;
    }
  }
}
