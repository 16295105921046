@import "../../../../styles/variables";

.maxWidthAndCenteredMixin() {
  max-width: @container-max-width-xl;
  margin: 0 auto;
}

.AssessmentWithFooterContainer {
  display: flex;
  flex-direction: column;

  .scrollable-container {
    overflow-y: auto;
    flex: 1;

    .assessment-container {
      .maxWidthAndCenteredMixin();
      padding: 0;

      @media @screenWidthMD {
        padding: @padding-md @padding-lg;
      }

      @media @screenWidthLG {
        padding: @padding-lg @padding-xxl;
      }

      @media @screenWidthXXL {
        padding: @padding-lg 0;
      }
    }
  }

  .competency-development-footer {
    width: 100%;
    background-color: white;
    border-top: @layout-border;

    .footer-content {
      .maxWidthAndCenteredMixin();

      @media @screenWidthXS {
        padding: @padding-xs @padding-sm;
      }

      @media @screenWidthSM {
        padding: (@padding-xs + @padding-xxs) @padding-md;
      }

      @media @screenWidthMD {
        padding: (@padding-xs + @padding-xxs) @padding-lg;
      }

      @media @screenWidthLG {
        padding: (@padding-xs + @padding-xxs) @padding-xxl;
      }

      @media @screenWidthXXL {
        padding: (@padding-xs + @padding-xxs) 0;
      }
    }
  }

  @media @screenWidthMaxSM {
    .competency-development-footer {
      .footer-content {
        > *:not(:last-child) {
          margin-bottom: @margin-xs;
        }

        .AtButton {
          width: 100%;
        }
      }
    }
  }
}
