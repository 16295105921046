@import "../../styles/variables";

#AtStylesPieView {
  display: flex;
  .graphSubtitle {
    position: absolute;
    padding-left: @padding-lg;
  }
  .chart-container {
    flex: 1 0 50%;
    text-align: center;
  }

  .legend-container {
    flex: 1 0 50%;
    margin: auto 0;

    .legend-items-list {
      display: flex;
      flex-direction: column;
      row-gap: @pixels-xxs;
      max-width: @container-max-width-xs;
      text-align: center;

      .style-legend-item {
        border-bottom: 1px solid @grey-color-bg;
        padding-right: @padding-sm;
        padding-left: @padding-xs;
        border-radius: @pixels-xs;
        // I take the color directly from figma
        &.Creatif {
          background-color: rgba(239, 150, 69, 0.08);
        }
        &.Rigoureux {
          background-color: rgba(255, 204, 77, 0.08);
        }

        &.Ambassadeur {
          background-color: rgba(127, 144, 251, 0.08);
        }
        &.Analytique {
          background-color: rgba(152, 198, 116, 0.08);
        }
        &.Bienveillant {
          background-color: rgba(142, 206, 208, 0.08);
        }
        &.Fonceur {
          background-color: rgba(214, 86, 86, 0.08);
        }

        .flexLayout(@justify-content: space-between);

        .left-section {
          .flexLayout();
          .textWeightSemiBold;
          .AtmanIcon {
            border: none;
          }
        }

        .right-section {
          .flexLayout();
        }
      }
      .style-legend-item:last-child {
        border-bottom: none;
      }
    }
  }
}
