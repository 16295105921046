@import "../../../styles/variables";

.AtContainerList {
  text-align: left;
  .header {
    display: flex;
    align-items: center;
    width: 100%;

    .header-style-svg {
      width: 100%;
      text-align: center;
    }
  }

  .narrativeText {
    margin-bottom: @pixels-xs;
  }

  .listContainer {
    list-style: none;
    padding-left: 0;

    .item {
      padding-bottom: @padding-sm;
      padding-top: @padding-sm;
      margin: 0 @margin-lg;

      &:not(:last-child) {
        border-bottom: 1px solid @grey-shade-9;
      }
    }
  }
}
