/**
 * This file sets the default typography styles used in the design system.
 **/

body {
  .fontDefault;
  .lineHeightDefault;
  .textSizeMedium;
  color: @grey-color-base;
  font-weight: 500;

  @media print {
    .textSizeExtraLarge;
  }
}

li {
  &:not(:last-of-type) {
    > p {
      margin-bottom: (@margin-xs + @margin-xxs);
    }
  }
}

p {
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: (@margin-xs + @margin-xxs);
  }
}

a {
  &:not(.disabled) {
    cursor: pointer;
  }
}
