@import "../../../../../../../../styles/variables";

.DetailedObservableBehaviorSubRow {
  display: flex;
  align-items: center;

  .observable-behavior-notes {
    margin-top: @margin-xs;
    padding: (@padding-xs + @padding-xxs) @padding-sm;
    background-color: @grey-color-bg;
    .borderRadius();
    .textSizeSmall;
    .textWeightMedium;
  }

  .content-col {
    &.competency-col {
      .observable-behavior-details {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .observable-behavior-rank,
        .observable-behavior-label {
          .textSizeMedium;
          .textWeightMedium;
        }

        .observable-behavior-rank {
          min-width: @pixels-xxl;
        }
      }
    }

    &.average-note-col {
      display: flex;
      position: relative;
      .flexLayout();
    }

    &.manager-note-col {
      display: flex;
      .flexLayout();
    }

    &.spread-col {
      .textWeightBold;
    }

    &.employee-note-col {
      display: flex;
      .flexLayout();
    }
  }

  @media @screenWidthMaxSM {
    margin-left: 0;
    margin-bottom: (@margin-md - @margin-xxs);
    flex-direction: column;
    align-items: flex-start;

    .observable-behavior-title {
      max-width: 100%;
      .textSizeMedium;
      .textWeightMedium;
      overflow-wrap: break-word;
      margin-bottom: @margin-xs;
    }

    .scores-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .score-container {
        .flexLayout();

        &:not(:last-child) {
          margin-right: @margin-lg;
        }

        .AtPersonAvatar {
          margin-right: @margin-xs;
        }

        .score {
          .textSizeLarge;
          .textWeightMedium;
        }
      }
    }
  }
}
