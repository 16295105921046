@import "../../styles/variables";

.PackageBundleItem {
  background: #ffffff;
  .cardBorder();
  .borderRadius;
  flex: 1 0 auto;
  margin-bottom: 0 !important;

  &:hover {
    border-color: #343434;

    label {
      .custom-control {
        label {
          &:before {
            border-color: #343434;
          }
        }
      }
    }
  }

  &.active {
    border-color: #343434;
    .boxShadow(4px, 0.18);
  }

  > label {
    cursor: pointer;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    min-height: 180px;

    .custom-control {
      position: absolute;
      top: @padding-md;
      right: @padding-sm;
    }

    .top-section {
      margin-top: @margin-md;

      .product-category {
        .textSizeSmall;
        .textWeightRegular;
        color: #818181;
      }
    }

    .bottom-section {
      .label {
        .textSizeSmall;
        .textWeightMedium;
        color: #818181;
      }

      .prices-container {
        display: flex;

        .price {
          .textSizeLarge;
          .textWeightBold;
          margin-right: 8px;
        }
      }

      .unit-price {
        font-size: 11px;
        line-height: 16px;
        .textWeightRegular;
      }

      .discount-rate {
        .textSizeSmall;
        .textWeightMedium;
        .borderRadius(2px);
        padding: @padding-xxs @padding-xs;
        background: #94f6bf;
        display: inline-block;
      }
    }
  }

  &.hidden-prices {
    > label {
      min-height: inherit;

      .custom-control {
        top: 30px;
      }
    }
  }
}
