@import "../../styles/variables";

#ProductRestrictionForm {
  .inputs {
    display: block;
    margin-bottom: 24px;

    .AtCheckbox {
      margin: 0 0 8px 0;
    }
  }

  @media @screenWidthXL {
    .inputs {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .AtCheckbox {
        margin-right: 8px;
      }
    }
  }
}
