@import "../../../styles/variables";

.FeatureEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  .left-section {
    .description {
      .textSizeSmall;
      .textWeightRegular;
      color: @grey-shade-4;
    }
  }

  .right-section {
    margin-left: @margin-lg;

    .AtSwitch {
      margin-bottom: 0.5rem;
    }
  }
}
