@import "../../styles/variables";

#AddCard {
  .card-containers {
    .card-logo {
      .cardBorder();
      .borderRadius(2px);

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  #card-information-section {
    .label-field-container {
      display: flex;

      .label-input {
        flex: 0 0 50%;
      }
    }
  }
}
