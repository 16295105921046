@import "../../../styles/variables";

.EntityWizardOverview {
  height: 100%;
  text-align: left;
  .flexLayout(initial, space-between, column);

  > .top-content {
    .flexLayout(@align-items: initial, @justify-content: initial, @flex-direction: column);
    flex: 1;
    padding: @padding-lg @padding-xl;
    border-bottom: @layout-border;
    overflow-y: auto;

    > .header {
      margin-bottom: @margin-md;

      > .AtTitle {
        margin-bottom: @margin-sm;
      }

      > .IconLabelTitle {
        justify-content: flex-start;

        .AtLabelTitle {
          overflow-x: hidden;

          .title {
            .AtTitle {
              .textOverflow;
            }
          }
        }
      }
    }

    > .content {
      .flexLayout(initial, initial, column);
      overflow-y: auto;

      > .content-section {
        .flexLayout(initial, initial, column);
        overflow-y: auto;

        &.flexible {
          flex: 1;
        }

        &:not(:last-child) {
          margin-bottom: (@margin-sm + @margin-xs);
        }

        > .AtTitle {
          margin-bottom: @margin-xs;
        }

        > .AtHint {
          margin-bottom: @margin-xs;
        }
      }
    }
  }

  > .footer {
    .flexLayout();
    padding: @padding-xxs @padding-xl;
    min-height: 56px;
  }
}
