@import "../../../../../styles/variables";

.TableItemTitle {
  .flexLayout(@justify-content: flex-start);
  text-align: left;
  width: 95%;

  .AtTitleSubtitle {
    .AtTitle,
    .AtSubtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Quicksand";
    }

    .AtSubtitle {
      font-style: normal;
      font-weight: 500;
      font-size: @alternate-pixels-xs;
      line-height: @pixels-sm;
    }

    .AtTitle {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: @alternate-pixels-s;
      line-height: @pixels-sm;
    }
  }

  .customLeftContent {
    .flexLayout();
    padding-right: (@padding-xs + @padding-xxs);
  }
}
