@import "../../../styles/variables";

.AtCompetenciesAverageScoresGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .AtCompetenciesAverageScore {
    &:not(:last-of-type) {
      margin-right: @margin-xs;

      @media @screenWidthXL {
        margin-right: @margin-sm;
      }
    }
  }
}
