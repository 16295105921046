@import "../../../../../../../../../styles/variables";

.ObservableBehaviorsTable {
  table {
    text-align: center;

    tr {
      margin-bottom: @margin-sm !important;
    }

    thead th {
      .textSizeSmall;
      .textWeightBold;
      text-transform: uppercase;
      border-bottom: @layout-border;

      &:first-child {
        width: 90%;
      }
    }

    tbody {
      tr {
        &:not(:first-child) {
          border-top: 2px solid @grey-color-bg;
        }

        &:last-child {
          td {
            padding-bottom: 0;
          }
        }

        td {
          padding: @padding-sm 0;
          .textSizeMedium;

          .observable-behavior-notes {
            margin-top: @margin-xs;
            padding: (@padding-xs + @padding-xxs) @padding-sm;
            background-color: @grey-color-bg;
            .borderRadius();
            .textSizeSmall;
            .textWeightMedium;
          }

          &:first-child {
            vertical-align: middle;

            > div {
              .textWeightMedium;
            }
          }

          &:not(:first-child) > div {
            .flexLayout();
            position: relative;

            .AtIconTooltip {
              @media @screenWidthSM {
                position: initial;
              }

              @media @screenWidthMD {
                position: absolute;
                right: @pixels-sm;
              }

              @media @screenWidthLG {
                right: @pixels-xs;
              }

              @media @screenWidthXL {
                right: @pixels-sm;
              }

              @media @screenWidthXXL {
                right: @pixels-lg;
              }
            }
          }

          .individual-score {
            height: @pixels-lg;
            .flexLayout();
            .textWeightMedium;
          }
        }
      }
    }

    thead th,
    tbody td {
      border-top: none;
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        text-align: left;
      }
    }
  }
}
