@import "../../../../../../styles/variables";

.AtTableHeaderCell {
  &.isHidden {
    display: none;
  }

  padding: @padding-xxs @padding-md;
  .headerCellContainer {
    text-transform: uppercase;
  }

  &.left {
    .headerCellContainer {
      .flexLayout(@justify-content: flex-start);
    }
  }
  &.center {
    .headerCellContainer {
      .flexLayout(@justify-content: center);
    }
  }
  &.right {
    .headerCellContainer {
      .flexLayout(@justify-content: flex-end);
    }
  }

  &.hasIcon {
    .headerCellContainer {
      .action:hover {
        cursor: pointer;
        background-color: @bg-color;
      }
    }
  }

  &.isSorted {
    .action.sort svg {
      color: @primary-color-base;
    }
  }

  &.isFiltered {
    .action.filter svg {
      color: @primary-color-base;
    }
  }

  .filtersContainer {
    position: relative;

    > * {
      position: absolute;
      top: @margin-sm;
      right: 0;
    }
  }
}
