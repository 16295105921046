@import "../../../../../../styles/variables";

.improvement-objectives-container {
  .improvement-objectives-explanation {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: @pixels-sm;
  }

  .improvement-objectives-container-subtitle {
    color: #838383;
  }

  .groups-container
    > .AtCollapse.ObjectiveSubRow.left-collapse-content-offset
    > .AtCollapse-toggle-row
    > .average-note-col.content-col
    > .AtValueColor.render-background {
    margin-left: @alternate-pixels-s;
  }
}
