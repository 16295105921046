@import "../../../styles/variables";

#AccountSecurity {
  text-align: left;

  .section-description {
    margin-bottom: @margin-md;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .AtButton:not(:first-child) {
      margin-left: @margin-xs;
    }
  }
}
