@import "../../styles/variables";

#PartnerMarketingInfoForm {
  .inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .CustomTextInput {
      flex: 0 0 23%;
      text-align: left;
    }
  }
}
