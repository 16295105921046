@import "../../../../../../styles/variables";

.AtTableMultipleSelectFilter {
  .options {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    row-gap: @margin-xs;

    .item {
      flex: 1 0 50%;

      .AtCheckboxTitleSubtitle {
        align-items: center;
        .AtTitle {
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 600;
          font-size: @alternate-pixels-s;
          line-height: @alternate-pixels-l;
        }
      }
    }
  }
}
