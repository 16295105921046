@import "../../../styles/variables";

.DimensionGroupScoreSummary {
  margin-top: @margin-xxs;
  .flexLayout();

  .AtContainer {
    width: 100%;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
  margin-bottom: @margin-xxs;
}
