@import "../../../styles/variables";

.LabelValue {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: @padding-xs;

  .AtTitleBadge {
    margin-right: @margin-sm;
  }
}
