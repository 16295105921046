@import "../../../../../styles/variables";

.TableItemScoreBox {
  .AtBadge {
    .square(@pixels-md);
    .borderRadius(6px);
    .flexLayout(center, center, column);
    .textSizeSmall;
  }

  &.lighterGreen {
    background-color: @green-shade-9;
  }

  &.lightGreen {
    background-color: @green-shade-5;
  }

  &.green {
    background-color: @green-shade-1;
  }

  &.green,
  &.yellow,
  &.red {
    color: #ffffff;
  }

  &.lighterGreen,
  &.lightGreen {
    color: #317159;
  }

  &.lighterYellow {
    background-color: @yellow-shade-8;
  }

  &.lightYellow {
    background-color: @yellow-shade-4;
  }

  &.lighterGreen,
  &.lightGreen {
    color: #66460d;
  }

  &.lighterRed {
    background-color: @red-shade-8;
  }

  &.lightRed {
    background-color: @red-shade-4;
  }

  &.red {
    background-color: @red-color-base;
  }
}
