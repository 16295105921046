@import "../../../styles/variables";

.AtBipolarScaleGroup {
  .scale-group-header {
    margin: @margin-xxs (@margin-xs + @margin-lg);
    text-align: left;
    display: flex;

    @media print {
      margin: @margin-xxs 0;
    }

    svg {
      .square(12px);
      margin-top: 6px;
      margin-left: @margin-xs;
      color: #8d8d8d;
      vertical-align: initial;
      opacity: 0;
      transition: 0.3s;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  @media print {
    &.cognitiveBipolarScale {
      .results-container {
        .AtTitle {
          .textSizeH2;
          .fontDomine;
          .textWeightBold;
          line-height: @pixels-md;
        }
      }
    }

    .AtSubtitle {
      page-break-before: always;
    }

    .subtitleTitle {
      text-align: left;
    }

    .results-container {
      .AtBipolarScale {
        &.detailed {
          &:not(:last-child) {
            margin-bottom: 0;
          }

          &.cognition {
            padding-top: @padding-sm;

            &:first-child {
              padding-top: 0;
            }
          }
        }

        &.cognitiveBipolarScaled {
          &.cognition {
            padding-top: @padding-sm;

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.tooltip-inner.scale-group-bipolar-tooltip {
  max-width: 320px;
  text-align: left;
  padding: 6px 12px;
  background-color: @grey-color-base;
  color: white;
  line-height: 18px;
  font-weight: 100;
  font-family: Quicksand, sans-serif;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

@media print {
  .AtScaleLegend + .AtBipolarScaleGroup {
    padding-top: 0;
    .scale-group-header {
      .AtSubtitle {
        page-break-before: avoid;
      }
    }
  }

  .AtScaleLegend + .AtBipolarScaleGroup ~ .AtBipolarScaleGroup {
    .scale-group-header {
      &:not(:first-child) {
        padding-top: 0;
      }
    }

    &.detailed {
      page-break-before: avoid;
    }
  }

  .AtBipolarScaleGroup ~ .AtBipolarScaleGroup {
    &.detailed {
      page-break-before: always;
    }
  }
}
