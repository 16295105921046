@import "../../styles/variables";

.ProductBalanceChange {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: @padding-xs @padding-xxs;
  background: #ffffff;
  min-width: 72px;
  .cardBorder();
  .borderRadius;

  .old-balance,
  .new-balance {
    .textSizeSmall;
    .textWeightMedium;

    &.old-balance {
      color: #818181;
      text-decoration: line-through;
      .textWeightRegular;
      margin-right: 4px;
    }
  }
}
