@import "../../../../styles/variables";

#OrgSsoCustomizationForm {
  padding: @padding-md @padding-xl;

  .AtTitleSubtitle {
    margin-bottom: @margin-md;
  }

  .sso-customization-form {
    > .LocalizedTextInput {
      margin-bottom: @margin-sm;
    }

    > .CardSaveButtons {
      margin-top: @margin-lg;
    }
  }
}
