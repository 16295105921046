@import "../../styles/variables";

#RequestCredits {
  .header-message {
    .textWeightMedium;
    margin-bottom: @padding-md;
  }

  .contact-info-container {
    display: flex;
    background: #ffffff;
    padding: @padding-md @padding-sm;
    .cardBorder();
    .borderRadius;

    .contact-info-card {
      flex: 0 1 33%;

      &:not(:last-child) {
        border-right: @card-border;
        margin-right: @padding-lg;
      }

      .card-title-container {
        display: flex;
        align-items: center;
        margin-bottom: @padding-sm;

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          margin-right: 12px;
          font-size: 24px;
          color: @grey-shade-2;
        }

        .card-title {
          color: #343434;
          margin-bottom: 0;
        }
      }

      .card-content {
        color: #818181;
      }
    }
  }
}
