@import "../../../styles/variables";

.PanelItem {
  position: relative;

  @media @screenWidthXL {
    display: flex;
    height: 100%;
  }

  @headerHeight: 57px;

  > .header {
    height: @headerHeight;
    border-bottom: @layout-border;

    > * {
      height: 100%;
    }
  }

  > .content {
    width: 100%;

    @media @screenWidthXL {
      width: 100%;

      .absoluteLayout();
    }

    &.scrollable {
      overflow-y: auto;
    }

    > * {
      height: 100%;
    }
  }

  > * {
    width: 100%;
  }

  &.size-sm,
  &.size-lg {
    width: 100%;

    &.is-collapsed {
      min-width: 0 !important;
      max-width: 0 !important;
    }

    > .content {
      padding: @padding-md @padding-sm;
    }

    &.size-sm {
      @media @screenWidthXL {
        flex: 1 1 25vw;
        min-width: 280px;
        max-width: 360px;
      }
    }

    &.size-lg {
      @media @screenWidthXL {
        flex: 1 1 40vw;
        min-width: 480px;
        max-width: 520px;
      }
    }

    .collapse-action {
      @collapseButtonMargin: 8px;

      .absoluteLayout(@collapseButtonMargin);
      .square(40px);
      align-items: center;
      justify-content: center;
      z-index: 99;
      cursor: pointer;
      .defaultInputBorderStyles;
      display: none;

      &.collapse-direction-left {
        right: @collapseButtonMargin;
        left: inherit;

        &.expand-btn {
          left: 0;
          right: initial;
        }
      }

      &.collapse-direction-right {
        left: @collapseButtonMargin;
        right: inherit;

        &.expand-btn {
          right: 0;
          left: initial;
        }
      }

      @media @screenWidthXL {
        display: flex;
      }

      &.collapse-btn {
        border-color: transparent !important;

        &:hover {
          border-color: @input-border-hover-color !important;
        }
      }

      &.expand-btn {
        background: white;

        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.size-auto {
    flex: 1;

    > .content {
      .absoluteLayout(@headerHeight);

      &.no-header {
        .absoluteLayout();
      }
    }

    > .content,
    > .header {
      padding: @padding-sm;

      &.size-sm {
        max-width: 668px;
        margin: 0 auto;
      }

      &.size-md {
        max-width: @container-max-width-xl;
        margin: 0 auto;
      }

      &.size-lg {
        @verticalPadding: 40px;

        @media @screenWidthMD {
          padding: @verticalPadding @padding-md;
        }

        @media @screenWidthLG {
          padding: @verticalPadding @padding-lg;
        }

        @media @screenWidthXL {
          padding: @verticalPadding @padding-xxl;
        }

        @media @screenWidthXXL {
          padding: @verticalPadding @padding-xxxl;
        }

        @media @screenWidthXXXL {
          padding: @verticalPadding @padding-eighty;
        }
      }
    }

    > .header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
