@import "../../../styles/variables";

.EntityOverview {
  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 0;
    height: 100%;
  }

  .EntityOverviewCard {
    margin-left: 0;
  }
}
