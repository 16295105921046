@import "../variables";

.multiplePagePrintHack(@spacing: @page-top-padding, @offset: 0) {
  page-break-inside: avoid;

  .paddingMarginHack(@spacing: @spacing, @offset: @offset);
}

.paddingMarginHack(@spacing: @page-top-padding, @offset: 0) {
  &:not(:first-child) {
    padding-top: @spacing;
  }
  &:not(:last-child) {
    margin-bottom: -(@spacing - @offset);
  }
}

&.reportsPaddingTop {
  margin-top: @page-top-padding;
}

&.hideCollapsibleHeader > .header {
  display: none;
}

.coverPageFit {
  #coverpage-content {
    .title-company-name-dates > #coverpage-title {
      span.coverPage-title,
      span.jobFit-domain-title {
        font-family: Quicksand;
        font-size: @pixels-lg;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -2px;
        text-align: left;
      }
    }

    #coverpage-candidate-name {
      margin-bottom: @margin-xxl;
      padding-top: @padding-xs;
    }

    #coverpage-company-name {
      .printStyle-client-name-container {
        margin-bottom: @margin-sm;
      }
    }

    #coverpage-footer {
      top: 1310px;
    }
  }
}

.unipolarScaleInPrintHack {
  .H3SectionLayout.AtUnipolarScaleGroup.detailed,
  .H3SectionLayout.AtUnipolarScaleGroup.summary {
    .results-container {
      .AtScaleLegend.Unipolar.container {
        .legend-category.d-flex.flex-column.col {
          border: none;
        }
      }

      .AtScaleLegend.Unipolar.container > .row.main-row {
        margin: 0 60px 0 20px;
        .first-col {
          max-width: 493.5px;
          padding-left: 15px;
          padding-right: 15px;
          text-align: end;
        }
      }
    }
  }
}

.bipolarScaleLegendHack(@needCustomAdjustment: false, @spacing: 50px, @width: 468px) {
  .AtScaleLegend.Bipolar.container > .row.main-row {
    & when (@needCustomAdjustment = true) {
      margin: 0 @spacing 0 0;
    }

    .first-col {
      max-width: @width;
      text-align: center;
    }
  }
}

.allCompetenciesUnipolarScaleHack {
  .allCompetencies > .scale-legend-label > .AtScaleLegend.Unipolar {
    .main-row > .first-col {
      max-width: 487px;
    }
  }

  .allCompetencies > .OneCompetencie > .ScoreLineContainer {
    max-width: 492px;
  }
}

.teamCompetenciesContentUnipolarHack {
  .AtCollapsibleCard.cardContentSummary {
    .first-col {
      max-width: 491px;
    }
  }
  .H3SectionLayout.AtUnipolarScaleGroup.summary {
    .results-container {
      .ScoreLineContainer {
        max-width: 492px;
      }
    }
  }
}

.teamReportFitCoverPage {
  #coverpage-content {
    .title-company-name-dates {
      top: 740px;
      #coverpage-candidate-name {
        margin-bottom: @margin-lg;
      }
    }
    #coverpage-footer {
      top: 1400px;
    }
  }
}

.personalityDetailedScaleFitHack {
  .groups {
    .AtScaleLegend.Bipolar {
      .main-row.row {
        margin: 0 90px 0 40px;
        .first-col {
          max-width: 470px;
          .inner-row {
            max-width: 435px;
            text-align: center;
          }
        }
      }
    }
  }
}
