@import "../../../styles/variables";

.RouteNavItem,
.dropdown-item.RouteNavItem {
  display: flex;
  position: relative;
  height: @pixels-xxl;
  text-align: left;

  //@media @screenWidthXL {
  //  .icon-container {
  //    margin-right: 10px;
  //  }
  //}

  a {
    display: flex;
    flex: 1 0 auto;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    //@media @screenWidthXL {
    //  margin: 0 0 0 20px;
    //  justify-content: flex-start;
    //}

    .active-indicator {
      position: absolute;
      .boxedLayout(18px, 0, 18px, "");
      width: 3px;
      background: @atman-orange;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }

  .nav-link {
    padding: 0;
  }
}
