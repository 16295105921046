@import "../../../global/styles/variables";

#CookiePolicy {
  width: 400px;
  margin: 0 auto;
  text-align: center;

  h1 {
    text-align: left;
  }

  form {
    text-align: left;

    .disclaimer-text {
      margin-bottom: @margin-sm;
    }

    #open-pdf-btn {
      margin-bottom: @margin-md;
    }

    .agreement-checkbox-container {
      cursor: pointer;
      .textWeightSemiBold;

      .flexStartLayout();
      margin-bottom: @margin-sm;
    }
  }

  @media @screenWidthMaxSM {
    width: 100%;
    max-width: 400px;
    padding-bottom: @padding-sm;
  }
}
