@import "../../styles/variables";

.CustomConsent {
  .customConsent-header.AtContainer {
    padding: @padding-sm @padding-md;
  }

  .customConsent-body {
    padding: @padding-sm @padding-md;
    margin-top: @margin-xxs;

    .customContent-bodyHeading {
      padding: @padding-xxs @padding-sm;
      display: flex;
    }

    .customContent-highlighted-text {
      margin: @margin-md @margin-xs;
      padding: @padding-sm @padding-sm;
      background-color: #fffaf1;
      text-align: initial;
    }

    .customConsent-collapsibles {
      padding: @padding-xxs @padding-md @padding-xxs @padding-sm;
      margin-bottom: @margin-sm;
    }

    .customConsent-footer {
      .customConsent-footer-lastModified {
        text-align: end;
        margin: @margin-md 0;
      }
    }
  }
}

.view-content {
  overflow-y: scroll;
}
