@import "../../../../../styles/variables";

.TableFilteringBase {
  .AtCollapse {
    .content {
      padding: @padding-sm @padding-md;
      .FormInputGroup.RadioInputGroup.form-group {
        margin-top: @pixels-sm;
        .row {
          .radio-input-container-col {
            .flexLayout();
            .RadioFormInput {
              width: 50%;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .titleLink {
    width: 100%;
    .flexLayout();
    justify-content: space-between;

    .AtTitle {
      margin-bottom: 0;
    }
  }
}
