@import "../../../../styles/variables";

.ActionPlanTablesRowContentObjective {
  padding: @pixels-xl @pixels-eighty;

  .actionPlanTablesRowContentObjectiveSectionMain {
    .flexLayout(start, space-between, row);
    gap: @padding-md;
  }

  .actionPlanTablesRowContentObjectiveSectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  .actionPlanTablesRowContentObjectiveSection,
  .actionPlanTablesRowContentObjectiveSectionLearnings {
    margin: @margin-md 0;
    .AtNotes {
      overflow-wrap: anywhere;
    }
  }

  .actionPlanTablesRowContentObjectiveSectionApplications {
    margin: @margin-md 0 @margin-xs 0;
  }

  .actionPlanTablesRowContentApplicationSectionNotes {
    margin-top: @margin-sm;
  }

  .actionPlanApplicationActionItem {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
  }

  .actionPlanLearningResourceItem {
    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
  }

  @media print {
    padding: @padding-xs 0;

    .actionPlanTablesRowContentObjectiveSectionApplications {
      .actionPlanTablesRowContentObjectiveSectionHeader {
        break-inside: avoid;
      }
    }

    .actionPlanTablesRowContentObjectiveSectionMain,
    .print-only-description-action-plan,
    .actionPlanTablesRowContentObjectiveSection {
      padding: @padding-sm 0;
    }

    .actionPlanTablesRowContentObjectiveSectionApplications {
      margin-top: 0;
      .multiplePagePrintHack(@spacing: 90px, @offset: @pixels-lg);
    }

    .actionPlanTablesRowContentObjectiveSectionLearnings,
    .actionPlanTablesRowContentApplicationSectionNotes {
      break-before: auto;
      padding-top: @padding-xxs;
      break-inside: avoid;
    }

    .actionPlanTablesRowContentObjectiveSectionLearnings {
      .multiplePagePrintHack(@offset: 112px;);
    }

    .actionPlanTablesRowContentApplicationSectionNotes {
      .multiplePagePrintHack();
    }

    .actionPlanTablesRowContentObjectiveSectionApplications + .objective-learning-ressources-notes-display {
      padding-top: @padding-xxxl;
    }

    .AtContainer.actionPlanApplicationActions,
    .AtContainer.actionPlanLearningResources {
      display: flex;
      flex-direction: column;
      border: initial;
    }

    .actionPlanLearningResourceItem,
    .actionPlanApplicationActionItem {
      display: flex;
      flex-direction: row;
      border: 1px solid #efefef;
      break-inside: avoid;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    .actionPlanTablesRowContentApplicationSectionNotes {
      padding-top: @padding-xxs;
      break-inside: avoid;
    }

    .print-only-description-action-plan {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 32px;

      .competency-wrapper,
      .sub-competency-wrapper {
        width: 45%;
      }
    }
  }
}
