@import "../../../../../../styles/variables";

.AtTableRow {
  .borderRadius();
  .tableBorder();
  align-items: center;
  background-color: white;
  height: @alternate-pixels-xxl;

  .AtCheckboxBase.custom-checkbox {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: @alternate-pixels-xxl;
    width: @pixels-md;

    &.size-sm {
      .custom-control-label {
        height: @pixels-sm;
        width: @pixels-sm;
      }
    }

    &.size-md {
      .custom-control-label {
        height: @alternate-pixels-l;
        width: @alternate-pixels-l;
      }
    }

    .custom-control-label::before,
    .custom-control-label::after {
      top: 0;
      left: 0;
    }

    .custom-control-input {
      width: 100%;
      height: 50%;
    }
  }

  .AtTableRowCell {
    border-left: none;
    border-right: none;
  }

  &.isHovered {
    .displayOnHover {
      > * {
        display: flex !important;
      }
    }
  }
}
