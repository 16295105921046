@import "../../../styles/variables";

#LikertLegend {
  .labels-flex-container {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
  }

  .labels-col-container {
    .labels {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
    }
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 20%;
    margin-bottom: 0;
    .textSizeSmall;
    .textWeightBold;
  }
}
