@import "../../../styles/variables";

.AtBasicCardHeader {
  width: 100%;
  border: solid #eaeaea 1px;

  border-radius: 8px 8px 0 0;
  .AtTitle {
    padding: @padding-md;
    margin-bottom: 0;
  }
}
