@import "../../styles/variables";

.ExpandableRadioInput {
  .defaultInputBorderStyles;

  > label {
    cursor: pointer;
    padding: @padding-sm;
    margin-bottom: 0;
    width: 100%;
    display: flex;

    .custom-control {
      display: inline;

      .custom-control-label {
        &::before {
          top: 5px;
        }

        &::after {
          top: 5px;
        }
      }
    }

    .label {
      .textSizeMedium;
      .textWeightMedium;
      line-height: 29px;
      margin-left: 16px;
      margin-bottom: 0;
    }
  }

  .content {
    padding: 0 @padding-sm @padding-sm;
  }
}
