@import "../../../global/styles/variables";

#ForgotPassword {
  width: 400px;
  margin: 0 auto;
  text-align: center;

  @media @screenWidthMaxSM {
    height: 100%;
    width: 100%;
    max-width: 400px;
    padding-bottom: @padding-sm;
  }

  form {
    text-align: left;

    h1 {
      font-family: Domine;
    }

    .email-input {
      margin-bottom: @margin-lg;
    }

    .info-container,
    #resend-link-container {
      color: @grey-shade-4;
      .textWeightMedium;

      &.info-container {
        margin-bottom: @margin-lg;
      }

      &#resend-link-container {
        margin-bottom: @margin-xxl;
      }
    }

    .AtButton[type="submit"] {
      margin-bottom: @margin-xs;
    }
  }
}
