@import "../../../styles/variables";

.ObjectiveApplication {
  display: flex;
  align-items: flex-start;
  background-color: white;
  .textSizeMedium;
  .textWeightMedium;

  @paddingBottom: (@padding-sm + @padding-xxs);

  .content {
    flex: 1 1 auto;

    > .AtBadge {
      display: inline-block;
      margin-bottom: @margin-xs;
    }

    > .content-description {
      text-align: justify;
    }
  }

  &:not(:last-child) > div:last-child {
    padding-bottom: @paddingBottom;
    margin-bottom: @margin-sm;
    border-bottom: @layout-border;
  }

  @media print {
    .textSizeLarge;

    &:not(:last-child) > div:last-child {
      margin-bottom: 0;
    }

    .AtCheckboxBase {
      margin-right: (@margin-sm + @margin-xxs);
    }
  }
}
