@import "../../../styles/variables";

.AtHint {
  .cardBorder(@style: dashed);
  .borderRadius();

  .textSizeMedium;
  .textWeightSemiBold;

  &.color-primary {
    border-color: @primary-color-base;
    background-color: @primary-color-bg;
  }

  &.color-secondary {
    border-color: @grey-color-base;
    background-color: @grey-color-bg;
  }

  &.color-success {
    border-color: @green-color-base;
    background-color: @green-color-bg;
  }

  &.color-warning {
    border-color: @yellow-color-base;
    background-color: @yellow-color-bg;
  }

  &.color-danger {
    border-color: @red-color-base;
    background-color: @red-color-bg;
  }

  &.color-ghost {
    border-color: @grey-shade-9;
    background-color: @almost-white-color;
  }
}
