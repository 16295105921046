@import "../../styles/variables";

.CompetencyResultLabel {
  padding: 2px (@padding-xxs + @padding-xs) 2px 2px;
  display: inline-flex;
  align-items: center;
  .layoutBorder();
  .borderRadius();

  .AtValueColor {
    margin-right: @margin-xs;

    & ~ .AtIconTooltip {
      margin-left: -@margin-xxs;
      margin-right: @margin-xs;
    }
  }

  > .name {
    .textWeightSemiBold;
    .textSizeMedium;
  }

  > .score {
    .flexLayout();
  }

  @media @screenWidthMaxSM {
    > .name {
      .textSizeSmall;
    }
  }
}
