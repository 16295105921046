@import "../../../styles/variables";

.AtCollapse {
  border-bottom: @layout-border;

  &:first-child {
    border-top: @layout-border;
  }

  // These rules apply to AtCollapse present under a top-level AtCollapse
  .AtCollapse {
    &:first-child {
      border-top-width: 0;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }

  > .AtCollapse-toggle-row {
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: @almost-white-color;
    }

    &.is-expanded {
      border-bottom: @layout-border;
    }

    > .rendered-chevron {
      margin-right: @margin-sm;
      flex: 0 0 auto;
    }
  }

  &.left-collapse-content-offset {
    > .AtCollapse-toggle-row {
      &.is-expanded {
        background-color: @almost-white-color;
      }
    }

    > .AtCollapse-collapse-content {
      background-color: @almost-white-color;

      > .content {
        background-color: white;
        margin-left: @padding-sm;
        border-left: @layout-border;
      }
    }
  }

  @media print {
    border: none !important;

    > .AtCollapse-toggle-row {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: none !important;

      > svg {
        display: none;
      }
    }

    .AtCollapse-collapse-content {
      border: none !important;

      .content {
        background-color: white !important;
      }
    }
  }

  @media @screenWidthMaxSM {
    &.left-collapse-content-offset {
      > .AtCollapse-toggle-row {
        &.is-expanded {
          background-color: @almost-white-color;
        }
      }

      > .AtCollapse-collapse-content {
        background-color: @almost-white-color;

        > .content {
          background-color: white;
        }
      }
    }
  }
}
