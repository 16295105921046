@import "../../../styles/variables";

.AtSubScaleNavigation {
  .filters-header {
    .flexStartLayout();
    align-items: center;
    margin-bottom: @margin-sm;
  }
  .content {
    text-align: justify;
    .textSizeMedium;
    .textWeightMedium;

    ul {
      padding-left: @padding-sm;
    }
  }
}
