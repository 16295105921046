@import "../../../../styles/variables";

.CompetencySetInformationForm {
  text-align: left;

  .LocalizedTextInput {
    margin-bottom: @margin-xs;
  }

  .AtLink {
    display: block;
    margin-bottom: @margin-sm;
  }

  .set-name-section,
  .set-description-section,
  .add-description-link {
    margin-bottom: @margin-lg;
  }
}
