@import "../../../styles/variables";

.StepsContainer {
  display: inline-block;

  .Steps {
    width: @alternate-pixels-l;
    height: @pixels-xxs;
    margin-right: 1px;

    &.requested {
      background-color: #d6d6d6;
    }

    &.completed {
      background-color: @green-shade-1;
    }
  }
}
