@import "../../styles/variables";

.CardSaveButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .AtButton {
    .icon {
      margin-right: (@margin-xs + @margin-xxs);
    }

    &:not(:last-child) {
      margin-right: @margin-xs;
    }
  }
}
