@import "../../../../../../styles/variables";

.AtTableRowCard {
  .borderRadius();

  display: flex;
  flex: 1 0 21%;
  margin: @margin-xxs;
  flex-direction: column;
  justify-content: space-between;
  max-width: 32%;

  .cardsItem {
    padding: @alternate-pixels-xs @padding-xs;

    .TableItemTitle {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: @alternate-pixels-xs;
    }

    .customCardContent {
      padding-top: @alternate-pixels-xs;
    }
  }
}
