@import "../../../styles/variables";

.AverageScoreDisplayWithLabel {
  min-width: 0;

  .AtValueColor {
    margin-right: @margin-xs;
  }

  span {
    color: @grey-color-base;
    .textOverflow;
  }
}
