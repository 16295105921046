@import "../../styles/variables";

.AtStyleTeamOverview {
  display: flex;
  margin-bottom: @margin-xs;

  .AtStyleTeamOverviewCard {
    width: 100%;
    padding-right: @padding-md;
    margin-right: @margin-xs;
  }

  .AtCard {
    .bottom-section {
      justify-content: start;
      flex-direction: column;
      .AtStyleTeamLead {
        margin-bottom: @margin-sm;
      }
    }
  }

  @media print {
    .distributionDescription {
      padding-bottom: @padding-md;
    }
  }
}
