@import "../../../styles/variables";

.CandidateInterpretationHeader {
  .flexStartLayout();
  margin-bottom: @margin-xs;

  .InitialsAvatar {
    margin-right: 8px;
  }

  .CandidatePicture {
    margin-right: 4px;
  }

  .name {
    .textSizeMedium;
    .textWeightSemiBold;

    @media print {
      .textSizeExtraLarge;
    }
  }
}
