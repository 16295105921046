@import "../../styles/variables";

.ReportLegendContainer {
  .flexLayout(center, left);
  flex-wrap: wrap;
  margin-bottom: @margin-xxs;

  .ReportLegendItem {
    .cardBorder();
    .borderRadius(@pixels-sm);
    .flexLayout();
    margin-right: @margin-lg;
    margin-top: @margin-xs;
    margin-bottom: @margin-xs;
    margin-left: @margin-md;
    padding: 6px (@padding-xs + @padding-xxs) 6px 6px;

    .legend-icon {
      margin-right: @margin-xs;
    }

    .legend-text {
      .textSizeMedium;
      .textWeightBold;
      color: @grey-color-base;
    }

    .legend-close-icon {
      display: flex;
      margin-left: (@margin-xs + @margin-xxs);
      cursor: pointer;

      @media print {
        display: none;
      }
    }
  }
}
