@import "../../../styles/variables";

.AtInputBase.form-control {
  &.small {
    padding: @padding-xxs @padding-xs;
  }

  &.medium {
    padding: @padding-xs + 1px @padding-sm;
  }
  &.large {
    padding: @padding-sm @padding-lg;
  }
}
