@import "../../../styles/variables";

.AtLabelTitle {
  > .label {
    .AtTitle,
    .AtSubtitle {
      .textWeightSemiBold;
      margin-bottom: @margin-xxs;
    }
  }
  > .title {
    .AtTitle {
      .textWeightBold;
    }
  }
}
