@import "../../styles/variables";

#AddCredits {
  .bundle-products-section {
    margin-bottom: @margin-xl;

    .section-description {
      margin-bottom: @margin-sm;
    }

    .bundle-products-container {
      .flexLayout(@align-items: stretch, @justify-content: space-between);
      column-gap: @pixels-xs;

      .AtBaseSelectableCard {
        flex: 1 1 auto;

        .AtTitleSubtitle {
          margin-top: @margin-lg;
        }
      }
    }
  }

  .content-section {
    .section-title {
      margin-bottom: @margin-md;
    }

    .product-packages-container {
      .flexLayout(@justify-content: space-between);
      column-gap: @pixels-xs;

      .PackageBundleItem {
        flex: 1 1 auto;
      }
    }

    .customize-bundle-link-container {
      margin-top: @margin-sm;

      .customize-bundle-link {
        text-decoration: underline;
      }
    }
  }

  #payment-method-step {
    .save-card-checkbox-label {
      .textSizeMedium;
      .textWeightMedium;
      display: flex;
      align-items: flex-end;
    }

    .label-field-container {
      display: flex;
      margin-top: 8px;

      .cardLabel-input {
        flex: 0 0 50%;
      }
    }

    .credit-card-label {
      .card-logo {
        .cardBorder();
        .borderRadius(2px);
        margin-right: 16px;
      }
    }

    .ExpandableRadioInput {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .text-summary {
      padding: @padding-sm;
      margin: 8px;
      .dashedBoxContainer-info;
    }
  }
}
