@import "../../../../../styles/variables";

#CompetencySetInformationStep {
  .flexLayout(flex-start);

  > .information-container {
    margin-right: @margin-sm;

    > .IconTitleSubtitle {
      align-items: flex-start;
      justify-content: center;
      margin-bottom: @margin-md;
    }
  }
}
