@import "../../../../styles/variables";

.CompetencyGroupFormSection {
  &.fullWidth {
    border-radius: 0;
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 0;

    &:last-child {
      .layoutBorder();
      border-left: 0 none;
      border-right: 0 none;
    }

    margin-bottom: 0;
  }

  .group-header {
    .flexLayout(center, space-between);
    padding: @padding-sm @padding-md;
    cursor: pointer;

    .left-section {
      .title-container {
        .flexStartLayout();

        > .AtTitle,
        > .icon-container {
          margin-right: (@margin-xs + @margin-xxs);
        }
      }
    }

    .right-section {
      .selected-competencies-count {
        .textSizeMedium;
        .textWeightMedium;
      }
    }
  }

  .collapse-container {
    padding: @padding-sm @padding-lg;
    border-top: @card-border;

    .competency-group-footer {
      .flexStartLayout();
    }
  }
}
