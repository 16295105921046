@import "../variables";

.swal2-container {
  .swal2-popup.swal2-modal {
    width: 600px;
    .fontDefault;
    padding: @padding-xxl @padding-xxxl;
    .borderRadius();

    .swal2-header {
      padding: 0;

      .swal2-title {
        color: @grey-color-base;
        font-size: @pixels-md;
        .textWeightBold;
        font-family: "Quicksand", sans-serif;
        margin-bottom: 16px;
      }
    }

    .swal2-content {
      color: @grey-shade-2;
      .textSizeLarge;
      .textWeightMedium;
    }

    .swal2-actions {
      margin-top: 32px;
      .textWeightMedium;

      > :not(:last-child) {
        margin-right: @padding-xs;
      }

      @media @screenWidthMD {
        margin-top: @margin-lg;

        > :not(:last-child) {
          margin-right: @padding-md;
        }
      }
    }
  }

  @media @screenWidthMaxSM {
    padding: 0 !important;

    > .swal2-popup.swal2-modal {
      margin-bottom: 0 !important;
      margin-top: auto !important;
      padding: @padding-md;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .swal2-header {
        .swal2-title {
          font-size: 20px;
          margin-bottom: (@margin-xs + @margin-xxs);
        }
      }

      .swal2-content {
        padding: 0 @padding-xs;
      }
    }
  }
}

body.swal2-height-auto {
  height: 100% !important;
}

html body.swal2-height-auto {
  height: 100% !important;
}
