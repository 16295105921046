@import "../../styles/variables";

@verticalContentPadding: @padding-md;

.AtSidePanelLayout {
  height: 100%;
  display: flex;

  overflow-y: hidden;

  @defaultHeaderHeight: 127px;

  .main-content#team-people-panel {
    .content {
      overflow-y: scroll;
    }
  }

  .main-content {
    flex: 1;
    position: relative;

    > .content {
      .absoluteLayout(@defaultHeaderHeight, 0, @defaultHeaderHeight, 0);

      &.no-header {
        .absoluteLayout(0, 0, @defaultHeaderHeight, 0);
        overflow-y: scroll;
        height: 100%;
      }
    }

    > .content,
    > .header {
      padding: @padding-sm;
      border-bottom: @layout-border;
    }

    > .header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: @defaultHeaderHeight;
    }
  }

  .SidePanelContainer {
    border-right: 1px solid @grey-shade-9;
    background-color: white;
  }
}
