@import "../../../../../styles/variables";

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
}

.AtTempModal {
  z-index: 1000;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;

  .title {
    border-bottom: @layout-border;
    padding: @padding-sm @padding-md;
  }

  .close {
    position: absolute;
    top: @padding-sm;
    right: @padding-sm;

    &:hover {
      cursor: pointer;
    }
  }

  .footer {
    .flexLayout();

    padding: @padding-sm @padding-md;

    .mainAction {
      margin-left: auto;
    }
  }

  .AtCollapse {
    .titleLink {
      .AtCheckboxBase {
        display: none;
      }
    }
  }
}
