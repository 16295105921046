@import "../../styles/variables";

.LoginComponentHeader {
  display: flex;
  align-items: center;

  .title {
    font-family: Domine;
    margin-bottom: 0;
  }

  .icon-container {
    .borderRadius(12px);
    .flexLayout();
    background-color: @red-color-bg;
    margin-right: 12px;
    .square(40px);
  }

  &.size-sm {
    margin-bottom: @margin-xs;

    .icon-container {
      .square(@pixels-lg);
    }
  }

  &.size-md {
    margin-bottom: @margin-sm;

    .icon-container {
      .square(@pixels-xxl);
    }
  }

  &.size-lg {
    margin-bottom: @margin-sm;

    .icon-container {
      .square(@pixels-xxxl);
    }
  }
}
