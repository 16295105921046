@import "../../styles/variables";

.AtSelectButton {
  display: flex;
  align-items: center;

  .AtSelect {
    margin-bottom: 0;
    width: 280px;
    margin-right: @margin-xs;
  }

  .value-container {
    display: flex;

    .icon-container {
      .flexLayout();
      margin-right: @margin-xs;
    }
  }

  .action-btn {
    line-height: 24px;
  }
}
