@import "../../../styles/variables";

.ApplicationsLearningResourcesContainer {
  padding: @padding-sm @padding-xl;

  .AtLabelTitle {
    h3 {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-bottom: @margin-md;
    }
  }

  .AtSubsection {
    &:not(:last-child) {
      margin-bottom: @margin-md;
    }

    .Atsubsection-content {
      > .AtCard {
        &:not(:last-child) {
          margin-bottom: @margin-xs;
        }

        .AtParagraph {
          .textWeightMedium;
        }
      }
    }

    &.applications-container {
      .AtCard {
        width: 100%;
      }
    }

    &.learning-resources-container {
      .Atsubsection-content {
        display: block;

        > div {
          &:not(:last-child) {
            margin-bottom: @margin-xxs;
          }

          .LearningResource {
            height: 122px;
            width: calc(25% - @margin-xs);

            &:not(:last-child) {
              margin-right: @margin-xs;
              margin-bottom: @margin-xs;
            }
          }
        }
      }
    }

    .notes-input-container {
      width: 100%;
      margin-top: @margin-xs;
      margin-bottom: @margin-sm;

      .note-text-input {
        border-color: @grey-shade-9;
        box-shadow: 0px 1px 2px @box-shadow-color;
      }
    }
  }

  @media @screenWidthMaxSM {
    .AtSubsection {
      &.learning-resources-container {
        .Atsubsection-content {
          > div {
            .LearningResource {
              width: 100%;

              &:not(:last-child) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
