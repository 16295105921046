.filtersContainerMixin() {
  .filters-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .filter-element {
      position: relative;
      margin: 0 12px;
      .textWeightSemiBold;
      color: @grey-color-base;
      .textSizeMedium;
      cursor: pointer;

      &:hover {
        .active-indicator {
          background-color: @primary-shade-5;
        }
      }

      .active-indicator {
        margin-top: 8px;
        height: 3px;
        width: 100%;
        bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: transparent;

        &.active {
          background-color: @primary-color-base;
        }
      }
    }
  }
}
