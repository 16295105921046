@import "../../../../styles/variables";

.AtWizardHeader {
  min-height: 56px;
  .flexLayout(@justify-content: initial);
  padding: (@padding-xs + @padding-xxs) (@padding-sm + @padding-xxs);
  background-color: white;
  border-bottom: @layout-border;

  .left-content {
    justify-content: flex-start;
    margin-right: @margin-xxs;
  }

  .right-content {
    justify-content: flex-end;
  }

  .middle-content {
    max-width: calc(100% - @container-max-width-sm);
    padding-right: (@padding-xxl + @padding-xxs);
    flex: 1;

    .step-header-elements-container {
      max-width: @container-max-width-lg;
      margin: auto;
      .flexLayout(@justify-content: flex-start);

      .step-header-element {
        display: flex;
        align-items: center;
        .textWeightBold;

        &.step-navigation {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &.active {
          color: @primary-color-base;
        }

        .chevron-icon {
          margin: 0 @margin-sm;
          font-size: 8px;
        }
      }
    }
  }
}
