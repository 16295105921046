@import "../../../../styles/variables";

.ActionPlanTablesRowContentApplication {
  padding: @pixels-xl @pixels-eighty;

  .actionPlanTablesRowContentApplicationSection,
  .actionPlanTablesRowContentApplicationSectionLearnings,
  .actionPlanTablesRowContentApplicationSectionNotes {
    margin: @margin-md 0;
    .AtNotes {
      margin-top: 0;
      overflow-wrap: anywhere;
    }
  }

  .actionPlanTablesRowContentApplicationSectionLearnings > .application-learning-ressources-notes-display {
    margin-top: @margin-xs;
  }

  .actionPlanTablesRowContentApplicationSectionMain {
    .flexLayout(start, space-between, row);
    gap: @pixels-xl;
  }

  .AtContainer.actionPlanLearningResources {
    .flexLayout(center, center,column);
    border-color: #efefef;
  }

  .actionPlanLearningResourceItem:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  @media print {
    padding: @padding-xs 0;

    /*.actionPlanTablesRowContentApplicationSectionMain {
      padding-top: @pixels-xxxl;
    } */

    .AtContainer.actionPlanLearningResources {
      border: initial;
    }

    .actionPlanTablesRowContentApplicationSectionMain,
    .print-only-description-action-plan {
      margin: @margin-xl 0;
    }

    .actionPlanTablesRowContentApplicationSectionLearnings {
      margin: 0;
      .multiplePagePrintHack(@spacing: 80px, @offset: 112px);
      .AtContainer.actionPlanLearningResources {
        page-break-inside: auto;
      }
    }

    .actionPlanTablesRowContentApplicationSectionNotes {
      margin: 0;
      .multiplePagePrintHack(@spacing: 80px);
    }

    .actionPlanLearningResourceItem {
      display: flex;
      flex-direction: row;
      border: 1px solid #efefef;
      break-inside: avoid;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    .actionPlanTablesRowContentObjectiveSectionApplication {
      padding-top: @page-top-padding;
      break-inside: avoid;
    }

    .actionPlanTablesRowContentObjectiveSectionLearning {
      padding-top: @page-top-padding;
      break-inside: avoid;
    }

    .actionPlanTablesRowContentApplicationSectionNotes {
      break-inside: avoid;
    }

    .print-only-description-action-plan {
      display: flex;
      .competency-wrapper,
      .type-wrapper {
        width: 45%;
      }
    }
  }
}
