@import "../../../styles/variables";

.LabelValueContainer {
  flex: 1;

  .AtContainer-header {
    .IconTitleSubtitle {
      justify-content: center;

      .icon-container {
        margin-right: @margin-xs !important;
      }
    }
  }
}
