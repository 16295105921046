@import "../../styles/variables";

.AtStyleTeamDistribution {
  margin-bottom: @margin-xs;

  .dominantStyleSubtitle {
    margin-bottom: @margin-sm;
  }

  .hightlightDescription {
    display: flex;
    .AtIcon {
      margin-right: @margin-xxs;
    }
  }

  .DominantStyleRepartition {
    margin-bottom: @margin-xxl;
  }

  .distributionHighlightText {
    margin-bottom: @margin-xs;

    .icon-container {
      margin-right: @margin-xxs;
    }
  }

  .columnsContainer {
    display: flex;

    .column {
      flex: 1 1 (100% / 6);

      &:not(:last-child) {
        padding-right: @padding-xxs;
      }

      &:not(:first-child) {
        padding-left: @padding-xxs;
      }

      .TeamScorePercentage {
        width: 100%;
      }

      .columnHeader {
        display: block;
        margin-left: auto;
        text-align: center;
        margin-bottom: @margin-sm;

        @media print {
          .AtButton {
            display: none;
          }
        }

        .AtButton {
          &.active {
            background-color: @grey-color-bg;
            color: @grey-color-base;
            border: none;
          }
        }

        .AtSvgIcon {
          margin-right: @margin-xxs;
        }

        .column-title {
          padding-left: @padding-xs;
          text-align: center;
          text-transform: lowercase;
        }
      }

      .memberCard {
        &:not(:last-child) {
          margin-bottom: @margin-xxs;
        }
      }
    }
  }

  &.AtCard {
    .bottom-section {
      display: block;
    }
  }

  @media print {
    .styleDistributionTopContent {
      page-break-before: always;

      .AtTitle {
        margin-bottom: @pixels-xs;
      }
    }

    .DominantStyleRepartition {
      page-break-after: always;
    }

    .dominantStyleSubtitle {
      margin: 0 0 @margin-lg 0;
      font-size: x-large;
    }

    &.AtCollapsibleCard.is-open > .header {
      border-bottom: none;
    }

    .columnsContainer {
      display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      .paddingMarginHack;

      .column {
        @border: 1px solid @grey-shade-7;
        border-right: @border;

        &:first-child {
          border-left: @border;
        }

        > .columnHeader {
          margin-bottom: -@page-top-padding;
          border-bottom: @border;
          border-top: @border;

          .AtStyleSvgs {
            img {
              width: auto;
              height: 1em;
            }
          }
        }

        .memberCard {
          .multiplePagePrintHack();
        }
      }
    }
  }
}
