@import "../../../../styles/variables";

.SidePanelContainer {
  position: relative;
  overflow: hidden;
  min-width: 240px;
  max-width: 400px;
  width: 25%;

  .AtTabs {
    .AtButtonTabs {
      margin: @margin-xs @margin-sm;
      width: calc(100% - (@margin-sm * 2));
    }
  }

  .SidePanelSearch {
    margin: @margin-xs @margin-sm 0 @margin-sm;
  }

  .IconTitleSubtitle {
    flex-direction: column;
    .icon-container {
      padding-bottom: @padding-md;
    }
  }

  .sorters {
    display: flex;
    justify-content: space-between;
  }

  .sidePanelElementWrapper,
  .AtTabs {
    height: auto;
    -ms-overflow-style: none; /* this is to hide the scrollbar for Internet Explorer, Edge */
    scrollbar-width: none; /* this is to hide the scrollbar for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* this is to hide the scrollbar for Chrome, Safari, and Opera */
    }
    &.animate__fadeInRight,
    &.animate__fadeInLeft {
      pointer-events: none !important;
      opacity: 0.4;
    }
  }

  .animate-wrapper {
    animation-duration: 0.7s;
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    top: 0;
    bottom: 0;

    &::-webkit-scrollbar {
      display: none; /* this is to hide the scrollbar for Chrome, Safari, and Opera */
    }

    &.animate__fadeInRight,
    &.animate__fadeInLeft {
      pointer-events: none !important;
      opacity: 0.4;
    }
  }
}
