@import "../../../../../../styles/variables";

.SidePanelSortWrapper {
  display: flex;

  .sidePanelSort {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: @pixels-xs;

    .AtParagraph {
      margin-left: @pixels-sm;
      font-size: 12px;
      text-transform: uppercase;
    }

    > * {
      cursor: pointer;
    }
  }
}
