@import "../../../../../../styles/variables";

.AtTableFreeTextFilter {
  > * {
    &:first-child {
      margin-left: 0;
    }
    margin-right: @margin-xs;
    margin-top: @margin-xs;
    margin-bottom: @margin-xs;
  }

  .flexLayout();

  .AtInput {
    width: 100%;
  }
}
