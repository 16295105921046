@import "../../../../../../../../../styles/variables";

.TableHeader {
  &.manager-note-col {
    .AtPersonAvatar {
      .border(@grey-color-base, 2px);
    }
    .AtTitle {
      margin-bottom: 0;
    }
  }
}
