@import "../../styles/variables";

#AtHeader {
  background: #ffffff;
  border-bottom: @layout-border;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.-device-mobile {
    height: 56px;
    padding: 0 @padding-sm;

    .left-section {
      display: flex;
      align-items: center;

      .icon-container {
        cursor: pointer;
        align-content: center;
        justify-content: center;
        padding: 10px 15px;
        margin-left: -15px;
        font-size: 16px;

        color: @grey-shade-2;

        &:hover {
          color: darken(@grey-shade-2, 5%);
        }
      }
    }

    .right-section {
      display: flex;
      align-items: center;
    }
  }

  &.-device-default {
    padding: @padding-xs @padding-md;
    //background: @bg-color;

    .left-section {
      display: flex;
      flex: 1 0 auto;
      align-items: center;

      #AtGlobalSearch {
        margin-right: @margin-sm;
      }
    }

    .right-section {
      display: flex;
      align-items: center;

      #AtBalance,
      #AccountDropdown {
        margin: 0 6px;
      }

      #AtBalanceDropdownToggle,
      #chatButton {
        font-size: 20px;
      }

      .help-dropdown {
        margin: 0 6px;

        .dropdown-item {
          .textSizeMedium;
          .textWeightSemiBold;
          color: @grey-color-base;
        }
      }

      .chat-button-container {
        position: relative;

        .unread-count {
          position: absolute;
          top: -8px;
          right: 10px;
          font-size: 12px;
          .square(18px);
          .borderRadius(50%);
          background: @red-color-base;
          border: 1px solid @red-color-base;
          color: white;
        }

        #chatButton {
          margin: 0 6px;
        }
      }

      > .AtButton {
        margin: 0 6px;
      }
    }
  }
}
