@import "../../global/styles/variables";

#UnauthenticatedContainer {
  height: 100%;
  background-color: white;

  .row {
    height: 100%;
    .content-column {
      background-color: white;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
      height: 100%;

      padding: @padding-md @padding-sm;

      @media @screenWidthSM {
        padding: @padding-lg @padding-xxl;
      }

      @media @screenWidthMD {
        padding: @padding-lg @padding-xxxl;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      > *:not(.AtButtonGroup) {
        margin: 0 auto;

        width: 300px;

        @media @screenWidthSM {
          width: 400px;
        }
      }

      .header {
        align-self: flex-start;
        display: flex;
        align-items: center;
        margin-bottom: @margin-sm;

        @media @screenHeightSM {
          margin-top: @margin-md;
        }

        @media @screenHeightMD {
          margin-top: @margin-xxl;
        }

        @media @screenHeightLG {
          margin-top: @margin-xxxl;
        }

        .environment-title {
          display: inline-block;
          margin-left: @margin-sm;
          margin-bottom: 0;
        }
      }

      .content {
        @media @screenHeightSM {
          min-height: 400px;
        }

        @media @screenHeightMD {
          min-height: 480px;
        }

        #ProductSelector {
          margin-bottom: @margin-sm;
        }

        .action-button {
          margin-bottom: @margin-sm;
        }
      }

      .footer {
        &,
        a {
          .textSizeSmall;
          .textWeightMedium;
          color: @grey-shade-2;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    .news-box-column {
      position: relative;
      padding: 0;
      background-size: cover;
      background-position: center;

      .overlay {
        height: 100%;
        width: 100%;
        background-color: fade(@grey-color-base, 72%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .content {
          max-width: @container-max-width-sm;
          margin-bottom: @margin-eighty;

          > * {
            color: white;
          }

          .news-box-title {
            font-family: Domine;
            font-size: 32px;
            line-height: 37px;
            margin-bottom: @margin-sm;
          }

          .news-box-subtitle {
            .textSizeMedium;
            .textWeightMedium;
            margin-bottom: @margin-md;
          }

          .news-box-link {
            color: @atman-orange !important;
            .textWeightBold;
          }
        }
      }
    }

    @media @screenWidthMaxSM {
      min-height: 100%;
    }
  }
}
