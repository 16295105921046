@import "../../styles/variables";

#BillingInformationForm {
  .AtTabCard {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 1 0 50%;
    }

    .CustomTextInput,
    .AtSelect {
      max-width: 47.5%;
      text-align: left;
    }
  }

  .contact-information-form {
  }

  .billing-address-form {
    .country-container,
    .state-container {
      max-width: 47.5%;
      text-align: left;
    }
  }
}
