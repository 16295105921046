@import "../../../styles/variables";

.UnlimitedBalanceMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px @padding-md;
  background: #fafafa;
  .cardBorder();
  .borderRadius();

  label {
    .textSizeSmall;
    .textWeightMedium;
    margin-bottom: 0;
    margin-right: 16px;
  }

  svg {
    color: @atman-orange;
  }
}
