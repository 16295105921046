@import "../../../styles/variables";

.AtTabBarItem {
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;

  a {
    flex: 1 0 auto;
    margin: 0 auto;
    justify-content: center;

    padding: 0;

    position: relative;

    color: @grey-color-base;
    margin: initial;
    .textWeightSemiBold;
    .textSizeMedium;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
    }
  }

  .active-indicator {
    position: absolute;
    .boxedLayout(18px, 0, 18px, "");
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;

    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: @primary-color-base;
  }

  .icon-container {
    @marginSize: 8px;

    &.icon-placement-left {
      margin-right: @marginSize;
    }

    &.icon-placement-right {
      margin-left: @marginSize;
    }
  }

  &:hover {
    a {
      color: @primary-color-base;
    }
  }

  &.active {
    a {
      color: @primary-color-base;
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    a {
      color: @grey-shade-6;
    }
  }
}
