@import "../../styles/variables";

#EditConsumptionScheduling {
  #edit-consumption-settings-form {
    .main-section {
      display: flex;
      justify-content: space-between;

      > * {
        flex: 0 0 48.5%;
      }

      .date-range {
        label {
          .textSizeSmall;
          .textWeightMedium;
        }

        .DateInput_input {
          padding: 11px 10px;
          line-height: 20px;
        }

        &.past-start-date {
          .DateInput_1 {
            cursor: not-allowed;

            #scheduleStartDate {
              pointer-events: none;
            }
          }
        }
      }
    }

    .expectedConsumption-input {
      max-width: 200px;
    }
  }
}
