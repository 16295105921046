@import "../../../../../styles/variables";

.TableItemBadges {
  display: flex;
  align-items: center;
  gap: @pixels-xxs;

  .badge {
    padding: 2px @padding-xxs;
    background: #fafafa;
    color: @grey-shade-2;
    border-radius: 4px;
    margin: 0;
  }

  .truncatedBadge {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: @pixels-eighty;
  }
}
