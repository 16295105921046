@import "../../../../../../../styles/variables";

.TableHeaderRow {
  display: flex;
  align-items: center;
  .verticalLayoutBorder();
  min-height: (@pixels-xxl + @pixels-xxs);
  padding: @padding-xs @padding-lg;

  .TableHeader {
    &.competency-col {
      justify-content: flex-start;
    }
  }

  @media @screenWidthMaxSM {
    align-items: flex-end;

    .TableHeader {
      &:not(.competency-col) {
        flex-direction: column;
        justify-content: center;

        > svg {
          margin-left: 0;
          margin-top: @margin-xxs;
        }
      }

      &.competency-col {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}
