@import "../../../styles/variables";

.AtBadge {
  svg {
    margin-right: @margin-xxs;
  }
  &.bordered {
    .border(@grey-shade-9);
  }
  .borderRadius();
  color: @grey-shade-2;
  background: white;
  line-height: 1 !important;

  &.pill {
    .borderRadius(@pixels-sm);
  }

  &.fill {
    border-color: @grey-shade-9;
    background: @grey-shade-9;
  }

  &.size-xs {
    .textWeightBold;
    padding: 2px @padding-xxs;

    @fontSize: 10px;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-sm {
    .textWeightSemiBold;
    padding: 3px 6px;

    @fontSize: @alternate-pixels-xs;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-md {
    .textWeightMedium;
    padding: 5px @padding-xs;

    @fontSize: @alternate-pixels-xs;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-lg {
    .textWeightSemiBold;
    padding: @padding-xs (@padding-xs + @padding-xxs);

    @fontSize: @alternate-pixels-s;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.color-success {
    color: @green-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @green-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @green-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @green-shade-8;

        border-color: @green-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @green-color-base !important;
    }
  }

  &.color-success-alt {
    color: @green-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @green-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @green-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @green-shade-8;

        border-color: @green-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @green-color-base !important;
    }
  }

  &.color-warning {
    color: @yellow-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @yellow-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @yellow-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @yellow-shade-9;

        border-color: @yellow-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @yellow-color-base !important;
    }
  }

  &.color-warning-alt {
    color: @warning-alt-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @warning-alt-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @warning-alt-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @warning-alt-shade-8;

        border-color: @warning-alt-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @warning-alt-color-base !important;
    }
  }

  &.color-danger {
    color: @red-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @red-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @red-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @red-shade-8;

        border-color: @red-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @red-color-base !important;
    }
  }

  &.color-danger-alt {
    color: @danger-alt-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @danger-alt-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @danger-alt-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @danger-alt-shade-8;

        border-color: @danger-alt-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @danger-alt-color-base !important;
    }
  }

  &.color-danger-light {
    color: @danger-light-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @danger-light-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @danger-light-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @danger-light-shade-8;

        border-color: @danger-light-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @danger-light-color-base !important;
    }
  }

  &.color-primary {
    color: @primary-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @primary-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        @bgColor: @primary-shade-8;

        border-color: @bgColor;
        background: @bgColor;
      }

      &.fill-medium {
        @bgColor: @primary-shade-8;

        border-color: @primary-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @primary-color-base !important;
    }
  }

  &.color-secondary {
    color: @grey-color-base;

    &.fill {
      &.fill-heavy {
        @bgColor: @grey-shade-1;

        border-color: @bgColor;
        background: @bgColor;
        color: #ffffff;
      }

      &.fill-light {
        border-color: @grey-shade-9;
        background: @almost-white-color;
      }

      &.fill-medium {
        @bgColor: @grey-shade-9;

        border-color: @grey-shade-1;
        background: @bgColor;
        color: @grey-color-base;
      }
    }

    &.bordered {
      border-color: @grey-color-base !important;
    }
  }
}
