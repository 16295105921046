@import "../../../styles/variables";

.AtParagraph {
  text-align: left;
  line-height: 1.5;

  &.size-sm {
    font-size: @alternate-pixels-xs;

    @media print {
      font-size: (@alternate-pixels-xs + 4px);
    }
  }

  &.size-md {
    font-size: @alternate-pixels-s;

    @media print {
      font-size: (@alternate-pixels-s + 4px);
    }
  }

  &.size-lg {
    font-size: @pixels-sm;

    @media print {
      font-size: (@pixels-sm + 4px);
    }
  }

  &.size-xl {
    font-size: @alternate-pixels-m;

    @media print {
      font-size: (@alternate-pixels-m + 4px);
    }
  }
}
