@import "../../../styles/variables";

.TableHeader {
  .flexLayout();
  .textSizeSmall;
  .textWeightBold;
  text-transform: uppercase;
  user-select: none;
  color: @grey-color-base;

  svg {
    margin-left: @margin-xs;
  }

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    padding-right: @padding-xxs;
  }

  @media @screenWidthMaxSM {
    margin-bottom: @margin-xxs;
  }
}
