@import "../../../styles/variables";

.AtCheckboxBase {
  label:before {
    border-color: @grey-shade-4;
  }

  &.size-xs {
    .customControlCheckboxInputSizeStyles(12px, 6px);
  }

  &.size-sm {
    .customControlCheckboxInputSizeStyles(16px, 4px);
  }

  &.size-md {
    .customControlCheckboxInputSizeStyles(20px, 2px);
  }

  &.size-lg {
    .customControlCheckboxInputSizeStyles(24px, 0px);
  }

  &.custom-checkbox {
    margin: 0 8px 0 0;
    position: relative;
    z-index: 0;

    &.disabled {
      .custom-control-input {
        &:checked {
          & ~ .custom-control-label {
            &::before {
              border-color: rgb(193, 193, 193);
              background-color: rgb(193, 193, 193);
            }
          }
        }
      }
    }

    .custom-control-input {
      &:hover {
        & ~ .custom-control-label {
          &::before {
            .inputHoverStyles(2px);
          }
        }
      }

      &:focus {
        & ~ .custom-control-label {
          &::before {
            .inputBorder(2px);
            .formBoxShadow();
          }
        }
      }

      &:checked {
        & ~ .custom-control-label {
          &::before {
            .border(@primary-color-base, 2px);
            background-color: @primary-color-base;
          }
        }
      }
    }

    .custom-control-label {
      &::before {
        .borderRadius();
        .inputBorder(@width: 2px);
      }

      &::after {
      }
    }
  }

  &.disabled {
    &.custom-checkbox {
      .custom-control-input {
        &:hover {
          & ~ .custom-control-label {
            &::before {
              border-color: rgb(193, 193, 193);
            }
          }
        }
      }
    }
  }

  &:not(.disabled) {
    label {
      cursor: pointer;
    }
  }
}

.customControlCheckboxInputSizeStyles(@size, @top) {
  .custom-control-label {
    &::before,
    &::after {
      width: @size;
      height: @size;
      top: @top;
    }

    &::after {
      background-size: 60%;
    }
  }
}
