@import "../../../styles/variables";

.AtIconTooltipListItemActive {
  display: flex;
  justify-content: flex-start;
  &.active {
    svg {
      color: @grey-color-base;
    }
  }
  &.inactive {
    svg {
      color: @grey-shade-6;
    }
  }
}
