@import "../../styles/variables";

.PageHeader {
  display: flex;
  text-align: left;
  margin-bottom: 12px;
  align-items: center;

  .info-container {
    justify-self: flex-start;
    margin-right: auto;

    .entity-name {
      .textSizeSmall;
      .textWeightMedium;
      text-transform: uppercase;
      color: @grey-shade-4;
      margin-bottom: 4px;
    }
  }

  .right-container {
    justify-self: flex-end;
    margin-left: auto;
  }
}
