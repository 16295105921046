@import "../../../styles/variables";

.StepHeader {
  margin-bottom: 16px;

  .title-container {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  &.-device-mobile {
    .title-container {
      justify-content: center;
      text-align: center;
    }

    > label {
      text-align: center;
    }
  }

  &.-device-default {
    text-align: left;

    .title-container {
      justify-content: flex-start;
    }

    > label {
      text-align: left;
    }

    .step-number-container {
      .flexLayout();
      flex: 0 0 auto;
      width: @pixels-md;
      height: @pixels-md;
      background-color: #818181;
      .borderRadius(100%);
      color: #ffffff;
      margin-right: (@margin-xs + @margin-xxs);
      .textSizeSmall;
      .textWeightMedium;
    }
  }
}
