@import "../../../styles/variables";

.AtButton {
  &.has-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(.icon-only) {
      &.icon-position-left {
        .icon-container {
          margin-right: 8px;
        }
      }

      &.icon-position-right {
        .icon-container {
          margin-left: 8px;
        }
      }
    }
  }

  &.highlight-icon {
    .icon-container {
      color: @primary-color-base;
    }
  }

  .icon-container {
    .flexLayout();
  }

  &.color-danger {
    @bgColor: white;

    &.has-icon {
      @textColor: @grey-color-base;

      color: @textColor;

      svg {
        color: @red-color-base;
      }

      &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus {
          color: @textColor;
        }

        &:active,
        &:active:focus {
          background-color: @bgColor;
          color: @textColor;
        }
      }
    }
  }
}
