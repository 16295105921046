@import "../../../styles/variables";

.AtIconLabelTitle {
  .flexLayout();

  .AtIcon {
    margin-right: @margin-xs;
  }

  .AtSvgIcon {
    margin-right: @margin-sm;
  }

  .AtmanIcon {
    margin-right: @margin-sm;
  }
}
