@import "../../../../../styles/variables";

.LearningResourceFilter {
  gap: 8px;
  svg {
    margin-right: @margin-xs;
  }
  span {
    .textWeightSemiBold;
  }
}
