@import "../../../styles/variables";

.AtDateRangePickerPopover {
  .date-range-picker-popover-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-range-label {
      color: @grey-shade-5;
      margin-right: @margin-xxs;
    }
  }
}

.date-range-picker-popover {
  max-width: initial;
  font-family: "Quicksand", sans-serif;

  .date-range-picker-popover-body {
    padding: 0;

    .popover-body-top-section {
      display: flex;

      .presets-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 160px;
        padding: @padding-md @padding-xs;
        border-right: @card-border;

        .AtButton {
          text-align: left;
          padding: @padding-xs !important;

          &:not(:last-child) {
            margin-bottom: @margin-xxs;
          }

          &.active {
            color: @grey-color-base;
            background-color: @grey-color-bg;
            border-color: @grey-color-bg;
          }
        }
      }

      .rdp {
        padding: @padding-md;
        margin: 0 auto;
      }
    }

    .popover-body-footer {
      padding: @alternate-pixels-xs @padding-sm;
      border-top: @card-border;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date-inputs {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .input-separator {
          margin: 0 @margin-xs;
        }

        .date-input {
          max-width: 120px;
          .textSizeMedium;
        }
      }

      .actions-container {
        margin-left: @margin-md;
      }
    }
  }
}
