@import "../../../styles/variables";

.AtValueColor {
  width: fit-content;
  height: fit-content;
  .flexLayout();
  .textSizeMedium;
  .textWeightBold;

  &.render-background {
    min-width: @pixels-lg;
    min-height: @pixels-lg;
    .borderRadius();
  }

  &.render-left-square {
    min-width: (@pixels-lg + @pixels-xxs);
    height: @pixels-lg;
    justify-content: flex-start;

    &.empty {
      justify-content: center;
    }

    .square {
      .square(@pixels-xs);
      .borderRadius(2px);
      margin-right: @margin-xxs;
    }
  }
}

.internetExplorer {
  .AtValueColor {
    &.render-background {
      height: @pixels-lg;
    }
  }
}
