@import "../../../styles/variables";

.AtTitle {
  color: #303030;
}

h1.AtTitle,
h2.AtTitle,
h3.AtTitle,
h4.AtTitle,
h5.AtTitle,
h6.AtTitle {
  .fontQuicksandBold;

  @media print {
    .fontDomineBold;
  }
}

h1.AtTitle {
  font-size: @pixels-md;
  line-height: @pixels-lg;

  @media print {
    font-size: @pixels-xxl;
    line-height: @alternate-pixels-xxl;
  }
}

h2.AtTitle {
  font-size: @alternate-pixels-l;
  line-height: @pixels-md;

  @media print {
    font-size: @pixels-lg;
    line-height: @pixels-xl;
  }
}

h3.AtTitle {
  font-size: @alternate-pixels-m;
  line-height: @pixels-md;

  @media print {
    font-size: @pixels-md;
    line-height: @pixels-lg;
  }
}

h4.AtTitle {
  font-size: @pixels-sm;
  line-height: @pixels-md;

  @media print {
    font-size: @alternate-pixels-l;
    line-height: @pixels-md;
  }
}

h5.AtTitle {
  font-size: @alternate-pixels-s;
  line-height: @alternate-pixels-l;

  @media print {
    font-size: @alternate-pixels-l;
    line-height: @pixels-md;
  }
}

h6.AtTitle {
  font-size: @alternate-pixels-xs;
  line-height: @pixels-sm;

  @media print {
    font-size: @alternate-pixels-s;
    line-height: @alternate-pixels-l;
  }
}
