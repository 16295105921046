@import "../../styles/variables";

.AtLabelledComponent {
  display: flex;
  flex-direction: column;

  > label {
    .flexStartLayout();
    color: @grey-shade-5;

    .AtIconTooltip {
      margin-left: @margin-xs;
    }
  }

  > .content {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .textSizeMedium;
    .textWeightBold;
  }
}
