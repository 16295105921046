@import "../../../styles/variables";

.AtFilter {
  .flexLayout(@justify-content: center);

  .FitIndicator {
    margin-right: @margin-sm;
  }

  .AtBadge {
    margin-left: @margin-sm;
  }
}
