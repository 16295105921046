@import "../../../styles/variables";

.TextCopyToClipboard {
  .AtTitle {
    .textWeightBold;
  }

  .AtIconTooltip {
    margin-left: @margin-xxs;
    margin-bottom: 0.5rem;
  }

  > div {
    span {
      .textSizeLarge;
      .textWeightSemiBold;
    }

    .AtButton {
      margin-left: @pixels-xs;
    }
  }
}
