@import "../../../../../styles/variables";

.TableItemList {
  display: flex;
  align-items: center;
  gap: @pixels-xxs;

  .truncatedList {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: @pixels-eighty;
  }
}
