@import "../../styles/variables";

.BaseModalHeader.modal-header {
  height: 56px;
  border-bottom: @card-border;
  display: flex;
  align-items: center;
  padding: @padding-sm;

  @media @screenWidthMD {
    padding: @padding-md;
  }

  .modal-title {
    color: @grey-color-base;
    .textWeightSemiBold;
  }
}

.BaseModalBody.modal-body {
  padding: 1.25rem 2.5rem !important;
  background: @grey-color-bg;
  overflow-y: auto;
  color: @grey-shade-1;
  //height: ~"calc(100vh - 56px)";

  .AtTabCard {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  form input:disabled {
    cursor: not-allowed;
  }

  //.form-check {
  //  padding-left: 0;
  //
  //  &-label {
  //    margin-left: 1.25rem;
  //    font-size: 16px;
  //    font-weight: normal;
  //  }
  //
  //  &-input {
  //    margin-top: 0.4rem;
  //  }
  //}

  .check-section {
    margin-bottom: 10px;
  }

  .ModalButtons {
    margin-top: 24px;
  }

  > .ModalButtons {
    margin-bottom: 24px;
  }
}

.BaseModalFooter.modal-footer {
  background: #fafafa;
}
