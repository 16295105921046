@import "../../../global/styles/variables";

#MfaSignInContainer {
  .AtTitle {
    margin-bottom: @margin-sm;
    font-family: Domine, sans-serif;
  }

  .AtSubtitle {
    margin-bottom: @margin-md;
  }

  .info-container {
    margin-top: @margin-xs;
    padding: @padding-sm @padding-md;
    background-color: @primary-color-bg;
    .borderRadius(4px);
  }
}
