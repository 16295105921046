@import "../../../styles/variables";

#AccountDetails {
  #personal-information-column {
    padding-right: 0;
    margin-bottom: 16px;

    @media @screenWidthXL {
      padding-right: @padding-sm;
      margin-bottom: 0;
    }
  }

  #change-password-column {
    padding-left: 0;

    @media @screenWidthXL {
      padding-left: @padding-sm;
    }
  }
}
