@import "../../../styles/variables";

.CompDevTabHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: @padding-md @padding-lg;

  .left-section {
    text-align: left;
    flex: 1 1 auto;
    padding-right: @padding-md;

    .sub-title {
      .textSizeMedium;
      .textWeightMedium;
      color: @grey-shade-2;
    }
  }

  .right-section {
    display: flex;
    flex: 0 0 auto;
  }

  .ActionPlaneSubHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 -@padding-xs;
    .countItem {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }
  }

  @media print {
    padding: @padding-sm 0;
    margin-bottom: 10px;

    .left-section {
      .header-title {
        font-family: Domine;
        font-size: 40px;
        line-height: 46px;
        .textWeightBold;
        margin-bottom: (@margin-xs + @margin-xxs);
      }

      .sub-title {
        font-size: 18px;
        line-height: 24px;
        .textWeightMedium;
      }
    }

    .right-section {
      display: none;
    }
  }
}
