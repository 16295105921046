@import "../../../styles/variables";

.AtQuickActionsContainer {
  display: flex;
  width: 100%;

  &.list-action {
    justify-content: flex-end;
    align-items: center;
  }

  .AtButton {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
