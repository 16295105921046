@import "../../styles/variables";

.SidePanelElement {
  background-color: white;
  text-align: left;
  border-bottom: @layout-border;
  padding: @padding-sm;

  width: 100%;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: pointer;
  }

  &.bottomBorderLess {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;

    .elementContainer {
      .tooltipDescription {
        opacity: 1;
      }
    }
  }

  &.isActive,
  &.active {
    background-color: #f3f3f3;
  }

  .elementContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tooltipDescription {
      opacity: 0;

      .tooltipIcon {
        svg {
          color: #8d8d8d;
        }
      }
    }

    .inside {
      display: flex;
      align-items: center;

      .customTitleSubtitle {
        display: flex;
        padding: 0 @pixels-xs;
        flex-direction: column;
        text-align: left;

        span {
          display: flex;
          align-items: center;

          .starred {
            margin-right: @pixels-xxs;
            text-align: center;

            p {
              .textSizeSmall;
              .textWeightBold;
              background: darken(@grey-shade-2, 10%);
              color: @almost-white-color;
              text-transform: capitalize;
              padding: 0 3px;
              .borderRadius(2px);
            }
          }
        }

        .AtTitle {
          margin-bottom: 0;
        }
      }

      .icon-container {
        .flexLayout();
        .square(40px);
        .borderRadius(12px);

        background: #fff;
        border: @grey-shade-9 1px solid;
        margin-right: @pixels-xs;
        flex-shrink: 0;
        box-shadow: rgb(000 / 8%) 0px 1px 0px 1px;

        svg {
          color: @grey-color-base;
        }
      }

      .icon-container.white {
        background: none;
      }

      .CircleContainer {
        margin-right: @pixels-xs;
      }
    }
  }

  .AtBadge {
    display: inline-block !important;
    margin-top: @margin-xs;
  }
}
