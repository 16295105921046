@import "../../../../styles/variables";

.BaseOverviewCardItem {
  padding: 12px @padding-xs;
  .cardBorder();
  .borderRadius();
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 32px;
    margin-right: 0;
  }

  &:not(:first-child) {
    margin-left: 0;
  }

  .icon-container {
    height: 32px;
    width: 32px;
    .textSizeSmall;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    .cardBorder();
    .borderRadius();
    flex-shrink: 0;

    svg {
      color: @atman-orange;
    }
  }

  .info-container {
    width: 100%;
    text-align: left;

    label {
      .textSizeSmall;
      .textWeightRegular;
      color: #818181;
      margin-bottom: 0;
    }

    .content {
      .textWeightMedium;
      .textSizeLarge;
    }
  }

  @media @screenWidthXL {
    padding: @padding-sm @padding-xs;

    .icon-container {
      height: 48px;
      width: 48px;
      .textSizeLarge;
    }

    .label {
      margin-bottom: @padding-xs;
    }

    .content {
      font-size: 20px;
    }
  }
}
