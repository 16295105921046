@import "../../styles/variables";
.AtPrintNotes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  white-space: pre-wrap;
  color: var(--Neutral-N800, #303030);
  font-family: @quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  .AtPrintNotesParagraph {
    width: 100%;
    overflow-wrap: anywhere;
    background-color: #f5f5f5;
    padding: @padding-xs;

    &:first-child {
      border-top-right-radius: @padding-sm;
      -moz-border-radius-topright: @padding-sm;
    }

    &:last-child {
      border-bottom-left-radius: @padding-sm;
      border-bottom-right-radius: @padding-sm;
      -moz-border-radius-bottomleft: @padding-sm;
      -moz-border-radius-bottomright: @padding-sm;
    }
  }

  @media print {
    .AtPrintNotesParagraph {
    }
  }
}
