@import "../../styles/variables";

.AppContextInfo {
  .flexStartLayout();

  > .icon-container {
    .flexLayout();
    .square(36px);
    .borderRadius(2px);
    font-size: 20px;
    background-color: fade(@atman-orange, 25%);
    margin-right: 12px;

    svg {
      color: @atman-orange;
    }
  }

  h4 {
    .textSizeLarge;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}
