@import "../../global/styles/variables";

#EmployeeContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-container {
    max-width: @container-max-width-md;
    margin: 5% auto;
    flex: 1 0;
    display: flex;
    flex-direction: column;

    @media @screenWidthMaxSM {
      margin: 0;
      padding: @padding-md 0;
      .borderRadius(0px);
      background-color: white;

      > *:not(.LoginFlowSwitcher) {
        border-width: 0;
        margin-top: @margin-sm;
      }
    }

    @media @screenWidthSM {
      min-width: @container-max-width-md;
    }

    .LoginFlowSwitcher {
      margin: 0 auto;
      margin-bottom: @margin-md;
    }

    .view-container {
      padding: @padding-xs @padding-sm;
      margin-bottom: @margin-md;
      flex: 1 0;

      > img {
        margin-bottom: @margin-md;
      }

      @media @screenWidthSM {
        .boxShadow(8px, 0.08, 2px);
        padding: @padding-sm @padding-md;

        > img {
          margin-bottom: @margin-lg;
        }
      }

      @media @screenWidthMD {
        padding: @padding-md @padding-lg;

        > img {
          margin-bottom: @margin-xl;
        }
      }

      @media @screenWidthLG {
        padding: @padding-xxl @padding-xxxl;

        > img {
          margin-bottom: @margin-xxl;
        }
      }
    }

    .links-container {
      text-align: center;
      margin: 0 auto;
      text-transform: uppercase;

      > .AtLink {
        &:not(:last-child) {
          margin-right: @margin-md;
        }
      }
    }
  }
}
