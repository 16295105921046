@import "../../../styles/variables";

#AtBalance {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown-menu {
    .cardBorder();
    .borderRadius();
    color: @grey-color-base;
    .textWeightMedium;

    &.show {
      &,
      &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      }
    }

    span {
      display: inline;
    }
  }

  .dropdown-menu {
    padding: 0;
  }

  .balance-overview {
    position: relative;

    .balance-indicator {
      position: absolute;

      bottom: 1px;
      right: 1px;

      .square(16px);

      font-size: 10px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;

      .borderRadius(50%);

      &.product-count {
        background-color: @primary-color-base;
        color: white;

        .three-chars {
          font-size: 8px;
        }
      }

      &.unlimited {
        background-color: #ffffffcc;
        svg {
          color: @atman-orange;
        }
      }

      &.blocked {
        background-color: @red-color-base;
        svg {
          color: white;
        }
      }
    }
  }

  .product-balance-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px @padding-sm;

    .product-quantity {
      color: @grey-shade-2;
      .textSizeLarge;
      .textWeightBold;
    }
  }

  .transactions-link-container {
    padding: @padding-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: @card-border;

    .AtButton {
      margin-bottom: 8px;
      width: 100%;
    }
  }
}
