@import "../../styles/variables";

#NewConsumptionScheduling {
  #new-consumption-settings-form {
    .main-section {
      display: flex;
      justify-content: space-between;

      > * {
        flex: 0 0 48.5%;
      }

      .date-range {
        .DateInput_input {
          padding: 11px 10px;
          line-height: 20px;
        }
      }
    }

    .expectedConsumption-input {
      max-width: 200px;
    }
  }
}
