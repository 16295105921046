@import "../../styles/variables";

#JobsContainer {
  .AtListHeader,
  .TableItemHeading {
    margin-bottom: @margin-sm;
  }
  .AtTable {
    width: 100%;
    .AtTableRowCell {
      .TableItemButtons {
        overflow: visible;
      }
    }
  }

  .cards-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .JobCard {
      margin: @margin-xs;

      @marginToRemoveFromFlex: @margin-xs * 2;
      flex: 0 0 ~"calc(33.3% - @{marginToRemoveFromFlex})";

      @media @screenWidthXL {
        @marginToRemoveFromFlex: @margin-xs * 2;
        flex: 0 0 ~"calc(25% - @{marginToRemoveFromFlex})";
      }
    }
  }
}

.internetExplorer {
  #JobsContainer {
    .cards-container {
      .JobCard {
        @marginToRemoveFromFlex: @margin-xs * 9;
        flex-basis: ~"calc(33.3% - @{marginToRemoveFromFlex})";

        @media @screenWidthXL {
          flex-basis: ~"calc(25% - @{marginToRemoveFromFlex})";
        }
      }
    }
  }
}
