@import "../../../styles/variables";

#AccountDropdown {
  .account-dropdown {
    .account-dropdown-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }

    .dropdown-menu {
      padding: 0;
      .cardBorder();
      .borderRadius();
      color: @grey-color-base;
      .textWeightMedium;

      &.show {
        &,
        &:hover {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
        }
      }

      .header {
        padding: 20px @padding-sm @padding-sm;
        border-bottom: @layout-border;
        .flexStartLayout();

        .info-container {
          margin-left: 12px;

          .display-name {
            .textSizeLarge;
            .textWeightBold;
          }

          .role {
            .textSizeSmall;
            .textWeightMedium;
          }
        }
      }

      .links-container {
        .dropdown-item {
          padding: 10px @padding-sm;
          .textWeightMedium;

          &:active {
            background-color: @grey-shade-9;
            color: @grey-color-base;
            .textWeightBold;
          }

          &:focus {
            outline-width: 1px;
          }
        }
      }
    }
  }
}
