@import "../../styles/variables";

.CreditsBalanceSelector {
  .cardBorder();
  .borderRadius;

  .table-headers {
    display: flex;
    align-items: center;
    padding: @padding-md @padding-md 12px;
    border-bottom: @card-border;

    .table-header {
      .textSizeSmall;
      .textWeightMedium;
      color: #818181;
    }

    .product-info-header {
      flex: 1 0 30%;
    }

    .quantity-selection-header {
      flex: 1 0 35%;
    }

    .recipient-balance-header,
    .new-balance-header {
      flex: 1 0 20%;
    }

    .current-balance-header {
      flex: 1 0 15%;
    }
  }
}
