@import "../../../styles/variables";

.AtSwitch {
  text-align: left;
  min-height: @pixels-md;

  .custom-control-label {
    .textSizeMedium;
  }

  .custom-control-input {
    outline-color: @grey-shade-2;

    &:disabled {
      pointer-events: none;

      ~ .custom-control-label {
        &::before {
          pointer-events: none;
          opacity: 0.5;
        }

        &::after {
          pointer-events: none;
        }
      }

      &:checked {
        ~ .custom-control-label {
          &::before {
            background-color: @primary-shade-1;
          }

          &::after {
          }
        }
      }
    }

    &:hover,
    &:focus {
      ~ .custom-control-label {
        &::before {
          .inputHoverStyles(2px);
        }
      }
    }

    &:focus {
      ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }

        &::after {
        }
      }
    }

    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: @primary-color-base;
          background-color: @primary-shade-1;
          padding-left: 8px;
        }

        &::after {
          transform: translateX(16px);
          background: white;
          border-color: @primary-color-base;
        }
      }
    }

    &:disabled,
    &.disabled {
      ~ .custom-control-label {
        &::before {
          cursor: initial;
        }

        &::after {
          cursor: initial;
        }
      }
    }

    ~ .custom-control-label {
      &::before {
        width: @pixels-xl;
        height: @pixels-md;

        .inputBorder(@width: 2px);
        .borderRadius(50px);
        font-size: 13px;
        padding-top: 5px;
        padding-left: 33px;
        font-weight: 500;
        cursor: pointer;
      }

      &::after {
        top: ~"calc(0.25rem + 3px)";
        left: ~"calc(-2.25rem + 5px)";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        .inputBorder(@width: 2px);
        background: @grey-shade-9;
        cursor: pointer;
      }
    }
  }
}
