@import "../../styles/variables";

#AccountContainer {
  height: 100%;

  &.-device-mobile {
    padding: @default-container-padding;

    .AtTabNavigationLayout {
      height: 100%;

      .tab-content {
        height: 100%;
        .fixedLayout(56px);
      }
    }
  }

  &.-device-default {
  }
}
