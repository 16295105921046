@import "../../../../styles/variables";

.FrequenciesNotificationEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  min-height: 40px;

  .frequencies {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RadioFormInput {
      margin-bottom: 0 !important;

      &:not(:last-child) {
        margin-right: 16px;
      }

      .custom-control {
        padding-right: 0.25rem;
      }
    }
  }
}
