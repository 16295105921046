@import "../../styles/variables";

.AtEditableElementList {
  text-align: left;

  .elements-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .AtEditableElement,
    .AtButton {
      margin-bottom: 4px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    .add-container {
      > * {
        height: 32px;
      }

      .AtSelect {
        min-width: 180px;
        margin-bottom: 0;
      }
    }
  }
}
