@import "../../../global/styles/variables";

#HelpCenterLogin {
  width: 400px;
  margin: 0 auto;
  text-align: center;

  h1 {
    text-align: left;
    margin-bottom: @margin-lg;
    font-family: Domine;
    font-size: @pixels-lg;
  }

  form {
    .password-input {
      margin-bottom: @margin-md;
    }

    .AtButton[type="submit"] {
      margin-bottom: @margin-xs;
    }

    .login-silently-container {
      text-align: center;

      #login-silently-btn {
        margin-top: @margin-sm;
        margin-bottom: @margin-xs;
      }
    }
  }
}
