@import "../../../styles/variables";

.StripeCardNumber {
  flex: 0 0 50%;

  label {
    .textSizeSmall;
    .textWeightMedium;
  }

  &:not(:last-child) {
    margin-right: 32px;
  }

  .StripeElement {
    .defaultInputBorderStyles;
    padding: (@padding-xs + @padding-xxs) @padding-sm;

    &.StripeElement--focus {
      .border(@primary-color-base);
    }
  }
}
