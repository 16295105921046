@import "../../../styles/variables";

.AtPersonLabelCompare {
  .flexStartLayout();
  align-items: center;

  > *:not(:last-child) {
    margin-right: (@margin-md - @margin-xxs);
  }

  .PersonLabel {
    min-width: initial;

    .IconTitleSubtitle {
      justify-content: center;
    }
  }

  .initialAndName {
    display: flex;
    align-items: center;
    margin-right: @margin-sm;
    .InitialsAvatar {
      margin-right: @margin-xs;
    }
  }
}
