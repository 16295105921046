@import "../../../styles/variables";

.AtCardSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: @padding-md @padding-sm;
  margin-bottom: @margin-xxs;
  .cardSelect-title-desc-icon-container {
    display: flex;
    .icon-container {
      .flexLayout();
      .square(40px);
      .borderRadius(12px);

      background: #fafafa;
      margin-right: @pixels-xs;
      flex-shrink: 0;
    }

    .cardSelect-title-desc-badge-container {
      .AtBadge {
        width: fit-content;
        margin-top: @margin-xs;
        font-weight: 600;
      }
    }
  }
}
