.inputStyles {
  .inputBorder();
  .borderRadius();
  color: @grey-color-base;
  background: #ffffff;
  .textWeightMedium;
}

.defaultInputBorderStyles {
  .borderRadius();
  .inputBorder();

  &:hover,
  &:focus {
    .inputHoverStyles;
  }

  &:focus {
    .formBoxShadow();
  }
}

.inputHoverStyles(@borderWidth: 1px) {
  .border(@input-border-hover-color, @borderWidth);
}
