@import "../../../styles/variables";

.LearningResource {
  .top-section {
    justify-content: space-between;

    .AtBadge {
      .textWeightBold;
      text-transform: uppercase;
    }
  }
  &.AtContainer.AtCard.AtCheckableCard {
    .AtContainer-header {
      align-items: center;
      display: flex;
    }
  }

  .bottom-section {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    span,
    .AtLinkBase {
      &.v2Link {
        display: flex !important;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        .AtIcon {
          display: flex;
          flex: none;
        }

        .resourceName {
          display: -webkit-box;
          flex: 1;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }

      .textWeightSemiBold;
      .textMaxLines();
    }

    .resourceAuthors {
      .textSizeSmall;
      .textWeightMedium;
      color: @grey-shade-3;
    }
  }

  @media print {
    page-break-inside: avoid;
    width: 284px;
    text-decoration: none !important;
  }
}
