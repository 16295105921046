@import "../../../styles/variables";

.AtCustomizableProperty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .property-label {
    .textWeightBold;
    color: @grey-shade-6;
    margin-bottom: 0;
    font-size: 12px;
    text-transform: uppercase;
  }

  .property-value-container {
    &.string-property {
      .textSizeMedium;
      .textWeightBold;
    }
  }

  &.no-label {
    .property-value-container {
      width: 100%;
    }
  }
}
