@import "../../../styles/variables";

.AtCheckboxTitleSubtitle {
  .flexLayout(@align-items: flex-start);
  text-align: left;

  &.checkbox-left {
    justify-content: flex-start;

    .AtSwitch {
      margin-right: @margin-sm;
    }
  }

  &.checkbox-right {
    justify-content: space-between;

    .AtCheckboxBase {
      margin: 0;
    }
  }

  &.text-clickable {
    .AtTitleSubtitle {
      cursor: pointer;
    }
  }

  .AtCheckboxBase {
    user-select: none;
  }
}
