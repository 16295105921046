@import "../../../../../../styles/variables";

#CompetencyDevelopmentAssessmentThankYouScreen {
  .layoutBorder();
  .borderRadius();
  background-color: white;
  max-width: @container-max-width-lg;
  text-align: center;
  padding: @padding-md @padding-lg;

  @media @screenWidthSM {
    padding: @padding-md (@padding-md + @padding-lg);
  }

  @media @screenWidthMD {
    padding: @padding-md @padding-eighty;
  }

  @media @screenWidthLG {
    padding: @padding-lg (@padding-eighty * 2);
  }

  .top-container {
    .completed-icon-container {
      .square(160px);
      .borderRadius(100%);
      .flexLayout();
      background-color: @grey-color-bg;
      margin: 0 auto;
      font-size: 60px;
      margin-bottom: @margin-xl;
    }

    .completed-title {
    }

    .completed-description {
      margin-bottom: @margin-lg;
    }
  }

  .bottom-container {
    margin-top: @margin-lg;
    border-top: @layout-border;
    padding-top: @padding-lg;

    .next-eval-title {
    }

    .next-eval-description {
      margin-bottom: @margin-lg;
    }
  }
}
