@import "../../../../styles/variables";

#CompetencyDevelopmentAssessmentWizard {
  .AtWizard {
    > .content > .main-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .competency-dev-assessment-substep-container {
    .AtWizardStepHeader {
      margin-bottom: @margin-md;

      @media @screenWidthMaxSM {
        margin-bottom: 0;
        padding: @padding-xs @padding-xl @padding-sm;
      }
    }
  }
}
