@import "../../../../../../../styles/variables";

.TableRow {
  .toggle-row {
    min-height: (@pixels-xxl + @pixels-xs);

    &.is-expanded {
      .competency-rank,
      .competency-name {
        color: @grey-color-base;
      }
    }

    &.in-error {
      .competency-col {
        .competency-name {
          color: @red-color-base;
        }
      }
    }

    .content-col {
      &.competency-col {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .AtIcon {
          margin-right: @pixels-xs;
        }
        .AtCheckboxBase {
          margin-right: (@margin-xs + @margin-xxs);
        }

        > .chevron-container {
          .flexLayout();
          margin-right: @margin-xs;
          .square(20px);
        }

        .competency-rank,
        .competency-name {
          .textWeightSemiBold;
          margin-bottom: 0;
        }

        .competency-rank {
          min-width: @pixels-lg;
        }

        .competency-name {
          margin-right: @margin-xs;
        }

        .tags-container {
          .flexStartLayout();

          .AtBadge:not(:last-child) {
            margin-right: @margin-xxs;
          }
          .AtSubtitle {
            color: #838383;
          }
        }
      }

      &.average-note-scale-col {
        display: flex;
        position: relative;
        .flexLayout();

        .ScaleRow .results-col {
          padding: 0;

          .scale-container {
            margin: 0;
          }
        }
      }
    }

    .competencyNameCell {
      display: flex;
      flex-direction: column;
    }
  }

  @media @screenWidthMaxSM {
    > .toggle-row {
      align-items: flex-start;

      > .chevron-container {
        margin-top: 2px;
        margin-right: (@margin-xs + @margin-xxs);
      }

      .competency-content-container {
        max-width: calc(100% - @pixels-md);
        flex: 1;

        .content-col {
          &.competency-col {
            max-width: 100%;
            flex-wrap: wrap;
            margin-bottom: @margin-xxs;

            > * {
              margin-right: @pixels-xs;
              margin-bottom: @margin-xxs;
            }

            .competency-title {
              max-width: 100%;
              .textWeightBold;
              overflow-wrap: break-word;
            }

            .AtBadge {
              .textWeightBold;
            }
          }
        }

        .scores-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .score {
            &.potential-col {
              flex: 0 1 50%;
            }
          }
        }
      }
    }

    .table-row-content-container {
      .DetailedSubCompetencySubRow {
        > .toggle-row {
          > .chevron-container {
            margin-top: @margin-xs;
            margin-right: (@margin-xs + @margin-xxs);
          }
        }
      }
    }
  }

  @media print {
    &:not(.is-expanded) {
      page-break-inside: avoid;

      &:not(:first-child) {
        padding-top: @page-top-padding;
      }
      &:not(:last-child) {
        padding-bottom: @padding-sm;
        margin-bottom: -(@page-top-padding + @padding-sm);
      }
    }

    .AtBadge {
      margin-bottom: 0 !important;
    }

    .AtCollapse-toggle-row {
      min-height: @pixels-lg;
      padding: @padding-xxs 0 !important;

      .average-note-scale-col {
        .ScaleRow {
          .results-col {
            .scale-container {
              > .col {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
