@import "../../../styles/variables";

#TeamDevelopmentActionPlan {
  .objectives {
    &-container {
      width: 100%;
    }

    > .TeamDevelopmentActionPlanObjectivesSection:last-child {
      > .CompDevActionPlanObjectiveRow:last-child {
        border-bottom-width: 0;
      }
    }
  }

  > .AtContainer:not(:last-child) {
    margin-bottom: @margin-xs;
  }

  @media print {
    page-break-before: always;

    .AtParagraph {
      text-align: justify;
    }
  }
}
