@import "../../../../../styles/variables";

.SubStepProgressionComponent {
  .textWeightBold;

  .sub-step-progress-label {
    .textSizeMedium;
    margin-bottom: 8px;
    text-align: center;
  }

  .progress {
    max-width: 320px;
    margin: 0 auto;

    &,
    .progress-bar {
      height: 8px;
      .borderRadius(12px);
    }
  }
}
