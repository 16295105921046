@import "../../../styles/variables";

.AtBipolarScaleLegend {
  position: relative;
  height: 24px;

  .legend-col {
    height: 100%;

    .legend-label {
      position: absolute;
      margin-bottom: 0;
      .textSizeSmall;
    }
  }
}
