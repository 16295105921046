/**
 * This file includes all the main colors used in the design system.
 **/
/* #region Grey Colors */
/* #endregion */
/* #region Primary Colors */
/* #endregion */
/* #region Green Colors */
/* #endregion */
/* #region Yellow Colors */
/* #endregion */
/* #region Red Colors */
/* #endregion */
/* Global Colors */
/**
 * This file includes all the colors used by the organization.
 **/
/* #region 'Atman' Orange Colors */
/* #endregion */
/* #region 'Atman' Product Gradiant */
/* #endregion */
/* #region 'Atman' Assessment Colors  */
/* #endregion */
/* #region Alternative base colors */
/* #endregion */
/* #region Light base colors */
/* #endregion */
/**
 * This file includes all the generic pixel sizes used in the design system.
 * We keep distinct variables for padding & margin in case we decide to have different values for each of them.
 **/
/**
 * This file includes all the media queries used in the design system and shared ui-components.
 **/
/**
 * This file includes all the font styling variables used in the design system.
 **/
@import "../../ui-kit/lib/styles/~bootstrap/dist/css/bootstrap.min.css";
@import "../../ui-kit/lib/styles/~animate.css/animate.min.css";
label {
  font-size: 14px;
  font-weight: 600;
}
.lineHeightDefault {
  line-height: 1.2;
}
.textSizeExtraSmall {
  font-size: 10px;
}
.textSizeSmall {
  font-size: 12px;
}
.textSizeMedium {
  font-size: 14px;
}
.textSizeLarge {
  font-size: 16px;
}
.textSizeExtraLarge {
  font-size: 18px;
}
.textSizeH6 {
  font-size: 12px;
  text-transform: uppercase;
}
.textSizeH5 {
  font-size: 14px;
}
.textSizeH4 {
  font-size: 16px;
}
.textSizeH3 {
  font-size: 18px;
}
.textSizeH2 {
  font-size: 20px;
}
.textSizeH1 {
  font-size: 24px;
}
.textWeightRegular {
  font-weight: 500;
}
.textWeightMedium {
  font-weight: 500;
}
.textWeightSemiBold {
  font-weight: 600;
}
.textWeightBold {
  font-weight: 700;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fontDefault {
  font-family: "Quicksand", sans-serif;
}
.fontDomine {
  font-family: "Domine", sans-serif;
}
.fontQuicksand {
  font-family: "Quicksand", sans-serif;
}
.fontDomineBold {
  font-family: "Domine", sans-serif;
  font-weight: 700;
}
.fontQuicksandBold {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}
.fontDomineSemiBold {
  font-family: "Domine", sans-serif;
  font-weight: 600;
}
.fontQuicksandSemiBold {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}
/**
 * This file includes all the border variables & mixins used in the design system.
 **/
/**
 * This file includes all the layout mixins used in the design system.
 **/
.baseDashedBoxContainer {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media only screen and (min-width: 768px) {
  .fullPageTableMediaQuery {
    max-width: none;
    width: 100%;
    margin: 0 auto;
    padding: 8px 24px;
  }
}
@media only screen and (min-width: 992px) {
  .fullPageTableMediaQuery {
    max-width: 900px;
    margin: 0 auto;
    padding: 8px 0;
  }
}
@media only screen and (min-width: 1200px) {
  .fullPageTableMediaQuery {
    max-width: 1050px;
    margin: 0 auto;
    padding: 8px 0;
  }
}
@media only screen and (min-width: 1400px) {
  .fullPageTableMediaQuery {
    max-width: 1200px;
    margin: 0 auto;
    padding: 8px 0;
  }
}
@media only screen and (min-width: 1600px) {
  .fullPageTableMediaQuery {
    max-width: 1400px;
    margin: 0 auto;
    padding: 8px 0;
  }
}
.inputStyles {
  border: solid 1px #c1c1c1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #303030;
  background: #ffffff;
  font-weight: 500;
}
.defaultInputBorderStyles {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #c1c1c1;
}
.defaultInputBorderStyles:hover,
.defaultInputBorderStyles:focus {
  border: solid 1px #989898;
}
.defaultInputBorderStyles:focus {
  box-shadow: 0 0 0 2px #a4bdf7;
}
.reportsPaddingTop {
  margin-top: 100px;
}
.hideCollapsibleHeader > .header {
  display: none;
}
.coverPageFit #coverpage-content .title-company-name-dates > #coverpage-title span.coverPage-title,
.coverPageFit #coverpage-content .title-company-name-dates > #coverpage-title span.jobFit-domain-title {
  font-family: Quicksand;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -2px;
  text-align: left;
}
.coverPageFit #coverpage-content #coverpage-candidate-name {
  margin-bottom: 48px;
  padding-top: 8px;
}
.coverPageFit #coverpage-content #coverpage-company-name .printStyle-client-name-container {
  margin-bottom: 16px;
}
.coverPageFit #coverpage-content #coverpage-footer {
  top: 1310px;
}
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.detailed .results-container .AtScaleLegend.Unipolar.container .legend-category.d-flex.flex-column.col,
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.summary .results-container .AtScaleLegend.Unipolar.container .legend-category.d-flex.flex-column.col {
  border: none;
}
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.detailed .results-container .AtScaleLegend.Unipolar.container > .row.main-row,
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.summary .results-container .AtScaleLegend.Unipolar.container > .row.main-row {
  margin: 0 60px 0 20px;
}
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.detailed .results-container .AtScaleLegend.Unipolar.container > .row.main-row .first-col,
.unipolarScaleInPrintHack .H3SectionLayout.AtUnipolarScaleGroup.summary .results-container .AtScaleLegend.Unipolar.container > .row.main-row .first-col {
  max-width: 493.5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: end;
}
.allCompetenciesUnipolarScaleHack .allCompetencies > .scale-legend-label > .AtScaleLegend.Unipolar .main-row > .first-col {
  max-width: 487px;
}
.allCompetenciesUnipolarScaleHack .allCompetencies > .OneCompetencie > .ScoreLineContainer {
  max-width: 492px;
}
.teamCompetenciesContentUnipolarHack .AtCollapsibleCard.cardContentSummary .first-col {
  max-width: 491px;
}
.teamCompetenciesContentUnipolarHack .H3SectionLayout.AtUnipolarScaleGroup.summary .results-container .ScoreLineContainer {
  max-width: 492px;
}
.teamReportFitCoverPage #coverpage-content .title-company-name-dates {
  top: 740px;
}
.teamReportFitCoverPage #coverpage-content .title-company-name-dates #coverpage-candidate-name {
  margin-bottom: 32px;
}
.teamReportFitCoverPage #coverpage-content #coverpage-footer {
  top: 1400px;
}
.personalityDetailedScaleFitHack .groups .AtScaleLegend.Bipolar .main-row.row {
  margin: 0 90px 0 40px;
}
.personalityDetailedScaleFitHack .groups .AtScaleLegend.Bipolar .main-row.row .first-col {
  max-width: 470px;
}
.personalityDetailedScaleFitHack .groups .AtScaleLegend.Bipolar .main-row.row .first-col .inner-row {
  max-width: 435px;
  text-align: center;
}
.inputStyles {
  border: solid 1px #c1c1c1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #303030;
  background: #ffffff;
  font-weight: 500;
}
.defaultInputBorderStyles {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #c1c1c1;
}
.defaultInputBorderStyles:hover,
.defaultInputBorderStyles:focus {
  border: solid 1px #989898;
}
.defaultInputBorderStyles:focus {
  box-shadow: 0 0 0 2px #a4bdf7;
}
.form-control {
  border: solid 1px #c1c1c1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #303030;
  background: #ffffff;
  font-weight: 500;
}
.form-control:hover {
  border: solid 1px #989898;
}
.form-control:focus {
  border: solid 1px #c1c1c1;
  box-shadow: 0 0 0 2px #a4bdf7;
}
.form-control::placeholder {
  color: #838383;
}
.form-control:disabled {
  background-color: #e4e4e4;
  color: #838383;
}
.form-control:disabled,
.form-control:disabled:hover {
  border: solid 1px #c1c1c1;
}
.form-text.text-muted {
  color: #595959 !important;
}
.badge {
  font-size: 12px;
  font-weight: 500;
  border: solid 1px #e4e4e4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 6px 12px;
  background: #fafafa;
  color: #595959;
}
.badge:not(:last-child) {
  margin-bottom: 4px;
  margin-right: 4px;
}
.tooltip-inner {
  font-family: "Quicksand", sans-serif;
  color: #595959;
  background: white;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  border: #f3f3f3 1px solid;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top[x-placement^="top"] .arrow,
.bs-tooltip-right[x-placement^="top"] .arrow,
.bs-tooltip-bottom[x-placement^="top"] .arrow,
.bs-tooltip-left[x-placement^="top"] .arrow,
.bs-tooltip-auto.bs-tooltip-top .arrow,
.bs-tooltip-top.bs-tooltip-top .arrow,
.bs-tooltip-right.bs-tooltip-top .arrow,
.bs-tooltip-bottom.bs-tooltip-top .arrow,
.bs-tooltip-left.bs-tooltip-top .arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f3f3f3;
}
.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top[x-placement^="top"] .arrow:before,
.bs-tooltip-right[x-placement^="top"] .arrow:before,
.bs-tooltip-bottom[x-placement^="top"] .arrow:before,
.bs-tooltip-left[x-placement^="top"] .arrow:before,
.bs-tooltip-auto.bs-tooltip-top .arrow:before,
.bs-tooltip-top.bs-tooltip-top .arrow:before,
.bs-tooltip-right.bs-tooltip-top .arrow:before,
.bs-tooltip-bottom.bs-tooltip-top .arrow:before,
.bs-tooltip-left.bs-tooltip-top .arrow:before {
  border-top-color: white;
  display: none;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-top[x-placement^="right"] .arrow,
.bs-tooltip-right[x-placement^="right"] .arrow,
.bs-tooltip-bottom[x-placement^="right"] .arrow,
.bs-tooltip-left[x-placement^="right"] .arrow,
.bs-tooltip-auto.bs-tooltip-right .arrow,
.bs-tooltip-top.bs-tooltip-right .arrow,
.bs-tooltip-right.bs-tooltip-right .arrow,
.bs-tooltip-bottom.bs-tooltip-right .arrow,
.bs-tooltip-left.bs-tooltip-right .arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #f3f3f3;
}
.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-top[x-placement^="right"] .arrow:before,
.bs-tooltip-right[x-placement^="right"] .arrow:before,
.bs-tooltip-bottom[x-placement^="right"] .arrow:before,
.bs-tooltip-left[x-placement^="right"] .arrow:before,
.bs-tooltip-auto.bs-tooltip-right .arrow:before,
.bs-tooltip-top.bs-tooltip-right .arrow:before,
.bs-tooltip-right.bs-tooltip-right .arrow:before,
.bs-tooltip-bottom.bs-tooltip-right .arrow:before,
.bs-tooltip-left.bs-tooltip-right .arrow:before {
  border-right-color: white;
  display: none;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-top[x-placement^="bottom"] .arrow,
.bs-tooltip-right[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom[x-placement^="bottom"] .arrow,
.bs-tooltip-left[x-placement^="bottom"] .arrow,
.bs-tooltip-auto.bs-tooltip-bottom .arrow,
.bs-tooltip-top.bs-tooltip-bottom .arrow,
.bs-tooltip-right.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom.bs-tooltip-bottom .arrow,
.bs-tooltip-left.bs-tooltip-bottom .arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f3f3f3;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-top[x-placement^="bottom"] .arrow:before,
.bs-tooltip-right[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom[x-placement^="bottom"] .arrow:before,
.bs-tooltip-left[x-placement^="bottom"] .arrow:before,
.bs-tooltip-auto.bs-tooltip-bottom .arrow:before,
.bs-tooltip-top.bs-tooltip-bottom .arrow:before,
.bs-tooltip-right.bs-tooltip-bottom .arrow:before,
.bs-tooltip-bottom.bs-tooltip-bottom .arrow:before,
.bs-tooltip-left.bs-tooltip-bottom .arrow:before {
  border-bottom-color: white;
  display: none;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-top[x-placement^="left"] .arrow,
.bs-tooltip-right[x-placement^="left"] .arrow,
.bs-tooltip-bottom[x-placement^="left"] .arrow,
.bs-tooltip-left[x-placement^="left"] .arrow,
.bs-tooltip-auto.bs-tooltip-left .arrow,
.bs-tooltip-top.bs-tooltip-left .arrow,
.bs-tooltip-right.bs-tooltip-left .arrow,
.bs-tooltip-bottom.bs-tooltip-left .arrow,
.bs-tooltip-left.bs-tooltip-left .arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #f3f3f3;
}
.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-top[x-placement^="left"] .arrow:before,
.bs-tooltip-right[x-placement^="left"] .arrow:before,
.bs-tooltip-bottom[x-placement^="left"] .arrow:before,
.bs-tooltip-left[x-placement^="left"] .arrow:before,
.bs-tooltip-auto.bs-tooltip-left .arrow:before,
.bs-tooltip-top.bs-tooltip-left .arrow:before,
.bs-tooltip-right.bs-tooltip-left .arrow:before,
.bs-tooltip-bottom.bs-tooltip-left .arrow:before,
.bs-tooltip-left.bs-tooltip-left .arrow:before {
  border-left-color: white;
  display: none;
}
.custom-radio .custom-control-input:hover ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:hover ~ .custom-control-label::before {
  border: solid 2px #989898;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1b59ea;
  background-color: #1b59ea;
}
.custom-radio .custom-control-input:focus ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: solid 2px #c1c1c1;
  box-shadow: 0 0 0 2px #a4bdf7;
}
.custom-radio .custom-control-label::before,
.custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: solid 2px #c1c1c1;
}
.custom-radio .custom-control-label::after,
.custom-checkbox .custom-control-label::after {
  width: 20px;
  height: 20px;
}
.dropdown > span {
  display: flex;
}
.dropdown .dropdown-menu .dropdown-header {
  padding: 8px 16px;
  color: #595959;
}
.dropdown .dropdown-menu > button,
.dropdown .dropdown-menu > a {
  font-size: 12px;
  padding: 8px 16px;
  color: #595959;
  font-weight: 500;
}
.dropdown .dropdown-menu > button.disabled,
.dropdown .dropdown-menu > a.disabled {
  color: #838383;
}
.dropdown .dropdown-menu > button.disabled:hover,
.dropdown .dropdown-menu > a.disabled:hover {
  background-color: #ffffff;
}
.dropdown .dropdown-menu > button:hover,
.dropdown .dropdown-menu > a:hover {
  background-color: #efefef;
}
.dropdown .dropdown-menu > button:focus,
.dropdown .dropdown-menu > a:focus {
  outline: none;
}
.dropdown.size-md .dropdown-menu > button {
  font-size: 14px;
}
.modal {
  font-family: "Quicksand", sans-serif;
}
.modal.show.centered-modal > .modal-dialog {
  margin-top: 160px;
}
.modal.show.centered-modal > .modal-dialog > .modal-content {
  border: solid 1px #e4e4e4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 0 0;
}
.modal.show.centered-modal > .modal-dialog > .modal-content > .modal-header {
  padding: 8px 32px;
  border-bottom-width: 0;
}
.modal.show.centered-modal > .modal-dialog > .modal-content > .modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
}
.modal.show.centered-modal > .modal-dialog > .modal-content > .modal-header .close {
  padding: 0;
  margin-top: calc(-1rem + 12px);
}
.modal.show.centered-modal > .modal-dialog > .modal-content > .modal-body {
  padding: 8px 32px;
}
.modal-backdrop.no-backdrop {
  background-color: transparent;
}
.react-bootstrap-table th.sortable {
  cursor: pointer;
}
.react-bootstrap-table th.sortable:hover {
  text-decoration: underline;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}
.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}
.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}
.react-bootstrap-table .filter,
.react-bootstrap-table .react-bs-table-tool-bar {
  display: none !important;
}
.react-bootstrap-table table {
  width: 100%;
  height: 100%;
  background: #ffffff;
  margin-bottom: 0;
  border: solid 1px #e4e4e4;
}
.react-bootstrap-table table thead,
.react-bootstrap-table table tbody {
  display: block;
  width: 100%;
}
.react-bootstrap-table table thead tr,
.react-bootstrap-table table tbody tr {
  display: inline-flex;
  width: 100%;
  border-bottom: solid 1px #e4e4e4;
}
.react-bootstrap-table table thead tr th,
.react-bootstrap-table table tbody tr th,
.react-bootstrap-table table thead tr td,
.react-bootstrap-table table tbody tr td {
  width: 100%;
  display: flex;
  align-items: center;
}
.react-bootstrap-table table tbody {
  height: calc(100% - 40px);
  overflow-y: auto;
}
.react-bootstrap-table table thead tr {
  height: 40px;
  padding-right: 17px;
}
.react-bootstrap-table table thead tr th {
  border-width: 0;
  color: #818181;
  font-size: 12px;
  font-weight: 500;
}
.react-bootstrap-table table thead tr th:focus {
  outline: none;
}
.react-bootstrap-table table tbody tr {
  font-size: 17px;
  line-height: 24px;
  color: #595959;
  cursor: pointer;
}
.react-bootstrap-table table tbody tr.no-click-action {
  cursor: default;
}
.react-bootstrap-table table tbody tr.no-click-action:hover td:first-child {
  text-decoration: none;
}
.react-bootstrap-table table tbody tr:not(:last-child) {
  border-bottom: solid 1px #e4e4e4;
}
@media only screen and (min-width: 768px) {
  .react-bootstrap-table table tbody tr .atman-btn,
  .react-bootstrap-table table tbody tr .AtQuickActionsContainer {
    opacity: 0;
  }
}
.react-bootstrap-table table tbody tr:hover {
  background: #ffffff;
}
.react-bootstrap-table table tbody tr:hover .atman-btn,
.react-bootstrap-table table tbody tr:hover .AtQuickActionsContainer {
  opacity: 1;
}
.react-bootstrap-table table tbody tr:hover td:first-child {
  text-decoration: underline;
}
.react-bootstrap-table table tbody tr:hover td.reset-expansion-style {
  text-decoration: none;
  cursor: default;
}
.react-bootstrap-table table tbody tr td {
  border-width: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 12px;
}
.react-bootstrap-table table tbody tr td.reset-expansion-style {
  background-color: #fafafa;
}
.internetExplorer .react-bootstrap-table,
.firefox .react-bootstrap-table {
  overflow-y: auto;
}
.safari .react-bootstrap-table table tbody {
  min-height: 0.1px;
}
.swal2-container .swal2-popup.swal2-modal {
  width: 600px;
  font-family: "Quicksand", sans-serif;
  padding: 48px 64px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.swal2-container .swal2-popup.swal2-modal .swal2-header {
  padding: 0;
}
.swal2-container .swal2-popup.swal2-modal .swal2-header .swal2-title {
  color: #303030;
  font-size: 24px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 16px;
}
.swal2-container .swal2-popup.swal2-modal .swal2-content {
  color: #595959;
  font-size: 16px;
  font-weight: 500;
}
.swal2-container .swal2-popup.swal2-modal .swal2-actions {
  margin-top: 32px;
  font-weight: 500;
}
.swal2-container .swal2-popup.swal2-modal .swal2-actions > :not(:last-child) {
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .swal2-container .swal2-popup.swal2-modal .swal2-actions {
    margin-top: 32px;
  }
  .swal2-container .swal2-popup.swal2-modal .swal2-actions > :not(:last-child) {
    margin-right: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .swal2-container {
    padding: 0 !important;
  }
  .swal2-container > .swal2-popup.swal2-modal {
    margin-bottom: 0 !important;
    margin-top: auto !important;
    padding: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .swal2-container > .swal2-popup.swal2-modal .swal2-header .swal2-title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .swal2-container > .swal2-popup.swal2-modal .swal2-content {
    padding: 0 8px;
  }
}
body.swal2-height-auto {
  height: 100% !important;
}
html body.swal2-height-auto {
  height: 100% !important;
}
.DateRangePicker .DateRangePickerInput__withBorder {
  border-color: #c1c1c1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.DateRangePicker .DateRangePickerInput__withBorder:hover,
.DateRangePicker .DateRangePickerInput__withBorder:focus {
  border-color: #989898;
}
.DateRangePicker .DateRangePickerInput__withBorder:active,
.DateRangePicker .DateRangePickerInput__withBorder.active {
  border-color: #1b59ea;
}
.DateRangePicker .DateInput {
  min-width: 147px;
  width: inherit;
}
.DateRangePicker .DateInput:first-child,
.DateRangePicker .DateInput:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.DateRangePicker .DateInput:last-child,
.DateRangePicker .DateInput:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.DateRangePicker .DateInput .DateInput_input {
  text-align: center;
  padding: 11px 10px 10px;
  font-size: 14px;
}
.DateRangePicker .DateInput .DateInput_input__focused {
  border-bottom-color: #1b59ea;
}
.DateRangePicker .CalendarDay__selected_start,
.DateRangePicker .CalendarDay__selected_end {
  color: white;
  border: solid 1px #1b59ea;
  background-color: #497aee;
}
.DateRangePicker .CalendarDay__hovered_span,
.DateRangePicker .CalendarDay__hovered_span:hover,
.DateRangePicker .CalendarDay__default:hover,
.DateRangePicker .CalendarDay__selected_span {
  color: white;
  border: solid 1px #1b59ea;
  background-color: #1b59ea;
}
.DateRangePicker .CalendarDay__blocked_out_of_range:not(.CalendarDay__selected_span),
.DateRangePicker .CalendarDay__blocked_out_of_range:not(.CalendarDay__selected_span):hover {
  background: #fafafa !important;
  color: #cacccd !important;
  border-color: #e4e7e7 !important;
}
#ct-container {
  top: 40px;
}
#ct-container .ct-toast {
  border-bottom: 4px solid transparent;
  justify-content: flex-start;
  position: relative;
  padding: 12px 24px;
  min-width: calc(100% - 16px);
}
@media (max-width: 768px) {
  #ct-container .ct-toast {
    margin: 0;
  }
}
@media only screen and (min-width: 768px) {
  #ct-container .ct-toast {
    min-width: 400px;
  }
}
#ct-container .ct-toast .ct-text-group,
#ct-container .ct-toast .ct-text-group-heading {
  width: 100%;
  margin-left: 15px;
}
#ct-container .ct-toast .ct-text-group .ct-text,
#ct-container .ct-toast .ct-text-group-heading .ct-text {
  font-weight: 600;
}
#ct-container .ct-toast .ct-text-group-heading .close-icon-container {
  position: absolute;
  top: calc(50% - 12px);
  right: 24px;
}
#ct-container .ct-toast.ct-toast-success {
  border-bottom-color: #48b084;
}
#ct-container .ct-toast.ct-toast-success > svg {
  color: #48b084;
}
#ct-container .ct-toast.ct-toast-info {
  border-bottom-color: #1b59ea;
}
#ct-container .ct-toast.ct-toast-info > svg {
  color: #1b59ea;
}
#ct-container .ct-toast.ct-toast-loading {
  border-bottom-color: #1b59ea;
}
#ct-container .ct-toast.ct-toast-loading > svg {
  color: #1b59ea;
}
#ct-container .ct-toast.ct-toast-warn {
  border-bottom-color: #fac13a;
}
#ct-container .ct-toast.ct-toast-warn > svg {
  color: #fac13a;
}
#ct-container .ct-toast.ct-toast-error {
  border-bottom-color: #eb5757;
}
#ct-container .ct-toast.ct-toast-error > svg {
  color: #eb5757;
}
.cards-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #595959;
  height: 150px;
  flex: 1 1 180px;
  max-width: 180px;
  margin: 12px 16px;
}
.cards-list-item .count {
  font-size: 12px;
}
.cards-list-item .name {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (min-width: 768px) {
  .cards-list-item {
    height: 160px;
    flex: 1 1 200px;
    max-width: 200px;
    margin: 14px 20px;
  }
  .cards-list-item .name {
    font-size: 15px;
  }
  .cards-list-item .count {
    font-size: 13px;
  }
}
@media only screen and (min-width: 992px) {
  .cards-list-item {
    height: 170px;
    flex: 1 1 230px;
    max-width: 230px;
    margin: 16px 22px;
  }
  .cards-list-item .name {
    font-size: 16px;
  }
  .cards-list-item .count {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) {
  .cards-list-item {
    height: 180px;
    flex: 1 1 260px;
    max-width: 260px;
    margin: 18px 25px;
  }
  .cards-list-item .name {
    font-size: 18px;
  }
  .cards-list-item .count {
    font-size: 14px;
  }
}
.cards-list-item .actions-menu {
  position: absolute;
  top: -25px;
  right: 2px;
}
.cards-list-item .actions-menu .dropdown .toggle-btn {
  background: transparent;
  padding: 0;
  height: 20px;
  color: #595959;
}
.cards-list-item .actions-menu .dropdown .toggle-btn:focus {
  -webkit-box-shadow: 0 0 0 0;
  -moz-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}
.cards-list-item .actions-menu .dropdown .toggle-btn .svg-inline--fa {
  vertical-align: 0;
}
.cards-list-item .actions-menu .dropdown .dropdown-menu {
  min-width: 60px;
  text-align: center;
}
.cards-list {
  display: flex;
  flex-flow: row wrap;
  padding: 40px 20px;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
}
@media only screen and (min-width: 768px) {
  .cards-list {
    padding: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .cards-list {
    padding: 40px;
  }
}
.items-list {
  padding: 0;
  height: calc(100vh - 320px);
  overflow-y: auto;
  position: relative;
  overflow-x: auto;
}
.items-list .btn {
  margin-right: 10px;
}
.items-list .toggle-btn {
  padding: 0 6px;
}
.row.double-margin {
  margin-right: -30px;
  margin-left: -30px;
}
.modal.modal-left-pane .modal-dialog,
.modal.modal-right-pane .modal-dialog {
  max-width: 100vw;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .modal.modal-left-pane .modal-dialog,
  .modal.modal-right-pane .modal-dialog {
    max-width: calc(100vw - 64px);
  }
}
@media only screen and (min-width: 1200px) {
  .modal.modal-left-pane .modal-dialog,
  .modal.modal-right-pane .modal-dialog {
    max-width: 75vw;
  }
}
@media only screen and (min-width: 1400px) {
  .modal.modal-left-pane .modal-dialog,
  .modal.modal-right-pane .modal-dialog {
    max-width: 60vw;
  }
}
@media only screen and (min-width: 1800px) {
  .modal.modal-left-pane .modal-dialog,
  .modal.modal-right-pane .modal-dialog {
    max-width: 50vw;
  }
}
.modal.modal-left-pane.show .modal-dialog,
.modal.modal-right-pane.show .modal-dialog {
  transform: translate(0, 0);
}
.modal.modal-left-pane .modal-content,
.modal.modal-right-pane .modal-content {
  height: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.modal.modal-left-pane .modal-dialog {
  transform: translate(-100%, 0);
  margin: 0 auto 0 0;
}
.modal.modal-right-pane .modal-dialog {
  transform: translate(100%, 0);
  margin: 0 0 0 auto;
}
.internetExplorer body > div[tabindex="-1"] {
  position: static !important;
}
/**
 * This file sets the default typography styles used in the design system.
 **/
body {
  font-family: "Quicksand", sans-serif;
  line-height: 1.2;
  font-size: 14px;
  color: #303030;
  font-weight: 500;
}
@media print {
  body {
    font-size: 18px;
  }
}
li:not(:last-of-type) > p {
  margin-bottom: 12px;
}
p {
  margin-bottom: 0;
}
p:not(:last-of-type) {
  margin-bottom: 12px;
}
a:not(.disabled) {
  cursor: pointer;
}
/**
 * This file sets the default layout styles used in the design system.
 **/
body {
  margin: 0;
  padding: 0;
}
body,
body html,
body #react-root {
  overflow-y: hidden !important;
}
html,
body,
#react-root,
.App {
  height: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  html,
  body,
  #react-root,
  .App {
    overflow-y: auto;
  }
}
html.internetExplorer {
  width: 100%;
}
