@import "../../../../styles/variables";
@import "../mixins";

#SwitchAppModalBody {
  padding: 0;

  .header-section {
    padding: @padding-xs @padding-lg 0;
    border-bottom: @layout-border;

    .connected-to-container {
      margin-bottom: @margin-md;

      label {
        .textSizeSmall;
        .textWeightBold;
        color: @grey-shade-2;
        text-transform: uppercase;
      }

      .context-info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          color: @grey-color-base;
          .textWeightMedium;
          text-decoration: underline;

          &:hover {
            color: @primary-color-base;
          }
        }
      }
    }

    .search-input-container {
    }

    .filtersContainerMixin();
  }

  .search-results-section {
    overflow-y: auto;
    max-height: 350px;
    min-height: 32px;

    .loading-indicator-container {
      height: 32px;
      .flexLayout();
    }

    .SearchResultEntry {
      padding: @padding-xs @padding-sm;
    }
  }
}
