@import "../../styles/variables";

#UsersAuthenticationSettings {
  padding: @padding-md @padding-xl;

  .AtTitleSubtitle {
    margin-bottom: @margin-md;
  }

  .cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: @margin-md;

    > .AtCheckableCard {
      flex: 1 1 calc(100% / 3);
      text-align: left;

      &:not(:first-child) {
        margin-left: @margin-xxs;
      }

      &:not(:last-child) {
        margin-right: @margin-xxs;
      }
    }
  }

  .generic-url-container {
    text-align: left;
    padding: @padding-sm @padding-md;
    .dashedBoxContainer-info();
    margin-bottom: @margin-md;

    .custom-url-link {
      display: block;
      margin-top: @margin-xxs;
    }
  }
}
