@import "../../../styles/variables";

.AtSvgIcon {
  position: relative;
  .circleBorder();
  .border(@grey-shade-9);
  .flexLayout();

  .image {
    width: 60%;
    height: 60%;
  }

  .secondaryIcon {
    .AtIcon {
      position: absolute;
      width: 50%;
      height: 50%;
      .circleBorder();
      .border(@grey-shade-9);
      background-color: #fff;
      left: 50%;
    }
  }

  &.size-lg {
    .square(56px);
  }

  &.size-md {
    .square(45px);
  }

  &.size-sm {
    .square(32px);
  }

  &.size-xs {
    .square(18px);
  }
}
