@import "../../styles/variables";

.ConsumptionRateProgress {
  display: flex;
  align-items: center;

  .percentage {
    margin-right: 12px;
  }

  .progress-bar-container {
    min-width: 5vw;

    .progress {
      background: #efefef;

      .progress-bar {
        background: #343434;
      }
    }
  }

  &.size-sm {
    .progress {
      height: 0.4rem;
    }
  }

  &.size-md {
    .progress {
      height: 0.5rem;
    }
  }

  &.size-lg {
    .progress {
      height: 0.6rem;
    }
  }
}
