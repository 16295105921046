@import "../../../../styles/variables";

.TabNavItem {
  a {
    color: @grey-shade-1;
    margin: initial;
    .textWeightSemiBold;
    .textSizeMedium;
    text-decoration: none !important;

    .active-indicator {
      top: auto;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 6px;
      background-color: @primary-color-base;
    }
  }

  .icon-container {
    @marginSize: 8px;

    &.icon-placement-left {
      margin-right: @marginSize;
    }

    &.icon-placement-right {
      margin-left: @marginSize;
    }
  }

  &:hover {
    a {
      color: @primary-color-base;
    }
  }

  &.active {
    a {
      color: @primary-color-base;
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    a {
      color: @grey-shade-6;
    }
  }

  .active-indicator {
  }
}
