@import "../../styles/variables";

.AtSubMenuNavigationLayout {
  display: flex;
  position: relative;
  height: 100%;

  .sub-menu {
    overflow-y: auto;
    height: 100%;
    border-right: @layout-border;
    background-color: #ffffff;
    text-align: left;
    flex: 0 0 48px;

    @media @screenWidthMD {
      flex: 0 0 187px;
      padding: @padding-md;
    }

    @media @screenWidthLG {
      flex: 0 0 240px;
      padding: @padding-md;
    }

    @media @screenWidthXL {
      flex: 0 0 265px;
    }

    @media @screenWidthXXL {
      flex: 0 0 260px;
    }

    @media @screenWidthXXXL {
      flex: 0 0 320px;
    }

    .sub-menu-header {
      margin-bottom: @margin-xl;

      h2 {
        margin-bottom: 0;
      }
    }

    .nav-item-group {
      label {
        margin-bottom: @margin-xs;
        .textSizeSmall;
        .textWeightSemiBold;
        text-transform: uppercase;
        color: @grey-shade-4;
      }

      &:not(:last-child) {
        margin-bottom: @margin-xl;

        @media @screenWidthLG {
          margin-bottom: @margin-lg;
        }
      }
    }
  }
}

.firefox {
  .AtSubMenuNavigationLayout {
    .sub-menu {
      > *:last-child {
        margin-bottom: @margin-md;
      }
    }
  }
}
