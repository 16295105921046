@import "../../styles/variables";

.AtmanIcon {
  position: relative;
  .circleBorder();
  .border(@grey-shade-9);
  .flexLayout();

  .atmanIconSvg {
    width: 60%;
    height: 60%;

    enable-background: new 0 0 592 602;
  }

  .st2 {
    fill: #ffffff;
  }

  .secondaryIcon {
    .AtIcon {
      position: absolute;
      width: 50%;
      height: 50%;
      .circleBorder();
      .border(@grey-shade-9);
      background-color: #fff;
      left: 50%;
    }
  }

  &.size-lg {
    .atmanIconSvg {
      margin-right: 2.9px;
    }
    .square(56px);
  }

  &.size-md {
    .atmanIconSvg {
      margin-right: 2.5px;
    }
    .square(45px);
  }

  &.size-sm {
    .atmanIconSvg {
      margin-right: 2px;
    }
    .square(32px);
  }

  &.size-xs {
    .atmanIconSvg {
      margin-right: 2px;
    }
    .square(24px);
  }
}
