@import "../../../styles/variables";

.AtProfileHeader {
  margin-bottom: @margin-xxs;
  box-shadow: none;

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }
}
