@import "../../../styles/variables";

.AtScoreReviewIndicator {
  margin-right: (-@margin-md);
  margin-left: @margin-xs;

  @media @screenWidthMaxSM {
    display: none;
  }
}

.score-review-tooltip {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  .textSizeMedium;

  > span {
    .textWeightMedium;
    margin-bottom: @margin-xs;
  }

  .previous-score {
    .flexLayout(@align-items: center, @justify-content: space-between);

    > span {
      .textWeightBold;
      text-transform: uppercase;
    }
  }
}
