@import "../../styles/variables";

.CustomValidatedTextInput {
  text-align: left;

  label {
    .textSizeSmall;
    .textWeightBold;
  }

  .form-control {
    .textSizeMedium;
    padding: (@padding-xs + @padding-xxs) @padding-sm;
    height: inherit;
  }
}
