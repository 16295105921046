@import "../../../styles/variables";

.AtTabs {
  .AtButtonTabs {
    margin-bottom: @margin-xxs;
    // flex: 0 0;
  }

  .AtTable {
    height: 100%;
  }
  .buttonTabsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .AtButton {
      margin-right: @margin-sm;
    }
  }
}
