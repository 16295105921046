@import "../../../../../../../../styles/variables";

.DetailedSubCompetencySubRow {
  > .toggle-row {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.is-expanded {
      .sub-competency-rank,
      .sub-competency-name {
        color: @primary-color-base;
      }
    }

    .content-col {
      &.competency-col {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          > .chevron-container {
            .flexLayout();
            margin-right: @margin-xs;
            .square((@pixels-sm + @pixels-xxs));
          }

          .sub-competency-rank,
          .sub-competency-name {
            margin-bottom: 0;
          }

          .sub-competency-rank {
            min-width: @pixels-lg;
          }

          .sub-competency-name {
            margin-right: @margin-xs;
          }
        }

        .right-container {
          display: flex;
          align-items: center;

          .AtButton {
            .textSizeSmall;

            &:not(:hover) {
              text-decoration: none;
            }
          }
        }
      }

      &.average-note-col {
        display: flex;
        .flexLayout();
      }

      &.manager-note-col {
        display: flex;
        .flexLayout();
      }

      &.spread-col {
        .textWeightBold;
      }

      &.employee-note-col {
        display: flex;
        .flexLayout();
      }
    }
  }

  .observable-behaviors-container {
    > .content {
      padding: @padding-sm @padding-lg;

      .DetailedObservableBehaviorSubRow {
        &:not(:last-child) {
          padding-bottom: @padding-sm;
          border-bottom: 2px solid @grey-color-bg;
        }

        &:not(:first-child) {
          padding-top: @padding-sm;
        }

        .observable-behaviors-label {
          font-size: 12px;
          line-height: 15px;
          color: @grey-shade-1;
          .textWeightBold;
          text-transform: uppercase;
          margin-bottom: @margin-xs;
        }
      }
    }
  }

  // The following rules serve to account for the offset while maintaining the columns alignment
  @offsetWidth: @pixels-sm;
  @rowHorizontalPadding: @pixels-lg;
  @diffBetweenOffsetAndPadding: (@rowHorizontalPadding - @offsetWidth);

  > .toggle-row {
    padding-left: @diffBetweenOffsetAndPadding !important;

    .content-col.competency-col {
      margin-left: @offsetWidth;
      flex-wrap: wrap;
    }
  }

  .observable-behaviors-container {
    > .content {
      padding-left: @diffBetweenOffsetAndPadding;

      .observable-behaviors-label {
        margin-left: @offsetWidth;
      }

      .DetailedObservableBehaviorSubRow {
        margin-left: -@offsetWidth;

        .content-col.competency-col {
          margin-left: (@offsetWidth * 2); // to account for 2 levels of Collapse offset
        }
      }
    }
  }

  @media @screenWidthMaxSM {
    > .toggle-row {
      align-items: flex-start;
      padding-left: @padding-sm !important;

      .sub-competency-content-container {
        max-width: calc(100% - @pixels-md);
        flex: 1;

        .content-col.competency-col {
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-left: 0;
          margin-bottom: (@margin-xs + @margin-xxs);

          .left-container {
            max-width: 100%;

            .sub-competency-title {
              max-width: 100%;
              .textWeightBold;
              overflow-wrap: break-word;
              margin-bottom: 0;
            }
          }

          .right-container {
            flex: 0;
          }
        }
      }
    }

    .observable-behaviors-container {
      > .content {
        padding: (@padding-xs + @padding-xxs) @padding-sm;

        .observable-behaviors-label {
          .textWeightBold;
          margin-left: 0;
        }

        .DetailedObservableBehaviorSubRow {
          margin-left: 0;

          .content-col.competency-col {
            margin-left: 0;
          }
        }
      }
    }
  }
}
