@import "../../../styles/variables";

#EntityOverviewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-section {
    text-align: left;

    .entity-info {
      display: flex;
      align-items: center;
      margin: 8px 0 16px;

      @media @screenWidthXXL {
        margin: 16px 0 24px;
      }

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        font-size: 24px;
        .cardBorder();
        .borderRadius(100%);
        background: #ffffff;
        margin-right: 16px;
        color: @grey-shade-2;
      }

      @media @screenWidthXXL {
        .logo {
          height: 64px;
          width: 64px;
          font-size: 32px;
        }
      }
    }
  }
}
