@import "../../../styles/variables";

.AssessmentAlerts {
  margin: @margin-md 0;
  h2.AtTitle {
    margin-bottom: @margin-sm;
  }
  .assessment-alerts-elements {
    display: flex;
    align-items: center;
    padding-bottom: @padding-sm;
    border-bottom: 1px solid @grey-color-bg;
    margin-bottom: @margin-md;

    .AtBadge.alert-percentage {
      min-height: 100px;
      min-width: 100px;
      border-radius: 50%;
      font-size: 38px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: @margin-xs;
      span.percentage {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: @margin-xs;
      }
    }
  }

  .assessment-alerts-elements:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .alert-title-and-description {
    margin-left: @alternate-pixels-xs;
    .AtTitle {
      margin-right: @margin-xs;
      font-family: "Quicksand", sans-serif;
      font-size: @pixels-md;
      margin-bottom: 0;
    }
  }

  .HighlightedText {
    display: flex;
    flex-direction: column;
    margin-bottom: @margin-md;
    .alerts-highlighted-text-title {
      margin-bottom: @margin-xs;
    }
  }
}
