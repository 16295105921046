@import "../../styles/variables";

#CustomDeleteAlertContent {
  display: flex;
  flex-direction: column;
  padding: @padding-sm;

  .item-card {
    display: flex;
    align-items: center;
    padding: @padding-sm;
    margin: 0 auto 12px;
    max-width: 100%;

    svg {
      margin-right: 8px;
    }

    label {
      .textWeightMedium;
      margin-bottom: 0;
      .textOverflow;
    }
  }

  .alert-content {
    .textSizeMedium;
    .textWeightMedium;
    margin-bottom: 0;
  }
}
