@import "../../styles/variables";

.BaseListHeader {
  display: flex;
  align-items: center;
  padding: (@padding-sm - @padding-xxs) @padding-sm;
  background: #ffffff;
  border-top: @card-border;
  border-left: @card-border;
  border-right: @card-border;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: @margin-xs;

  .left-section {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    margin-right: auto;

    h3 {
      margin-bottom: 0;
    }

    svg {
      color: @atman-orange;
      margin-right: 12px;
    }
  }

  .right-section {
    justify-self: flex-end;
    margin-left: auto;

    .AtButton,
    .AtMenuButton,
    .AtMenuButton {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .AtButton,
    .AtMenuButton {
      display: inline-block;
    }
  }
}
