@import "../../../styles/variables";

.ErrorFallback {
  max-width: @container-max-width-lg;
  margin: @margin-xxl auto 0;

  .main-subtitle {
    margin-bottom: @margin-sm;
  }

  .AtLabelledComponentList {
    margin-bottom: @margin-md;
  }

  .actions-subtitle {
    margin-bottom: @margin-sm;
  }

  .buttons-container {
    > .AtButton:not(:last-child) {
      margin-right: @margin-xs;
    }
  }
}
