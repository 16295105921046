@import "../../styles/variables";

.HelpCenterInfoLink {
  a {
    .flexLayout();
    .textSizeSmall;
    .textWeightBold;

    .icon-container {
      margin-right: @margin-xs;
    }
  }

  @media print {
    display: none;
  }
}
