@import "../../../styles/variables";

.AtCollapsibleCard {
  .cardBorder();
  .borderRadius(8px);
  background: white;

  &.is-open {
    > .header {
      border-bottom: @card-border;
    }
  }

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: (@padding-xs + @padding-xxs) @padding-xl;

    .AtTitle {
      margin-bottom: 0;
    }

    .left-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        margin-right: (@margin-xs + @margin-xxs);
      }

      &.disabled {
        cursor: auto;
      }
    }
  }

  > * > .content {
    padding: @padding-sm 0;
  }

  // For nested cards
  .AtCollapsibleCard {
    margin: 0;
    margin-top: @margin-md;
    border: 0px;

    > .header {
      border-bottom: @card-border;
      border-top: @card-border;
    }

    &:last-child {
      > .header {
        padding-bottom: 0;
        border-bottom: none;
      }

      &.is-open {
        > .header {
          border-bottom: @card-border;
          padding-bottom: @padding-sm;
        }
      }
    }
  }

  @media print {
    border: none;
    background: transparent;
    padding-top: @page-top-padding;

    > .header {
      padding: @padding-sm 0;
      .avoidPageBreakAfter(300px);
    }

    .left-section > svg {
      display: none;
    }

    > * > .content {
      padding: 0;
    }
  }
}
