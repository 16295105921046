@import "../../../styles/variables";

@ScaleDotIndicatorSize: 7px;

.ScaleCell {
  text-align: center;

  .dot-indicator-container {
    height: @ScaleDotIndicatorSize;
    margin-top: -@ScaleDotIndicatorSize;

    .ScaleDotIndicator {
      &:not(:last-child) {
        margin-right: @margin-xxs;
      }
    }
  }

  .custom-radio.custom-control {
    margin-left: 3px; // Hack to make the reactstrap's RadioButton centered in its container and therefore centered in the scale cell.
  }

  @media @screenWidthMaxSM {
    margin-bottom: @margin-xxs;

    .dot-indicator-container {
      margin-top: 0;
    }
  }
}
