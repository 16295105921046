@import "../../../styles/variables";

.ScoreInfo {
  text-align: center;

  h6 {
    display: block;
    margin-top: -@margin-xs;
    margin-bottom: 0;
  }

  svg {
    text {
      tspan {
        &:nth-child(2) {
          fill: @grey-shade-2 !important;
          font-size: (@pixels-lg + @pixels-xxs) !important;
        }
        &:nth-child(3) {
          fill: @grey-shade-2 !important;
          font-size: (@pixels-md + @pixels-lg) !important;
        }
      }
    }
    overflow: initial;
  }

  .na-score-container {
    > span,
    > a {
      font-size: 12px;
      line-height: 16px;
      .textWeightBold;
    }

    > span {
      margin-right: 4px;
    }
  }

  .subtitle {
    color: @grey-color-base;
  }

  .score-color-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .border(transparent, 4px);
    .borderRadius(8px);
    padding: 2px 0;

    &:not(.has-score) {
      .border(@card-border-color, 4px);
    }

    &:not(.is-average) {
      justify-content: center;
    }

    .label {
      font-size: 8px;
      .textWeightBold;
      color: @grey-shade-4;
    }

    .value-container {
      .flexLayout();
      .textSizeMedium;
      .textWeightBold;
      margin-bottom: 0;
      margin-top: -2px;
    }
  }

  .score-stanine {
    .flexLayout();
    flex-direction: column;
    border-radius: 50%;
    border: solid 1px;
    .textWeightBold;

    &.green {
      border-color: @green-color-base;

      &.color-alt {
        border-color: @success-alt-color-base;
      }
    }

    &.yellow {
      border-color: @yellow-color-base;

      &.color-alt {
        border-color: @warning-alt-color-base;
      }
    }

    &.red {
      border-color: @red-color-base;

      &.color-alt {
        border-color: @danger-alt-color-base;
      }
    }

    h1 {
      color: @grey-color-base;
      margin: 0;
    }
  }
}
