@import "../../../styles/variables";

.AtPersonContainer {
  .flexLayout();
  justify-content: space-around;

  .AtButton {
    margin-left: auto;
  }
}
