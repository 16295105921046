@import "../../styles/variables";

.AtNewTabNavigationLayout {
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    flex: 1;
    overflow-y: auto;
  }

  @media @screenWidthMaxSM {
    > .TabNav {
      padding: 0 @padding-xs;

      .header-view {
        padding-top: @padding-xs;
      }
    }
  }
}
