@import "../../../styles/variables";

.ToastContentContainer {
  .flexLayout(@align-items: center, @justify-content: space-between);

  .close-icon-container {
    .square(@pixels-md);
    margin: -2px 0;
    .flexLayout();
    cursor: pointer;
    color: @grey-shade-2;

    &:hover {
      color: @grey-color-base;
    }
  }
}
