@import "../variables";

.cards-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: @grey-shade-2;
  height: 150px;
  flex: 1 1 180px;
  max-width: 180px;
  margin: 12px 16px;

  .count {
    font-size: 12px;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media @screenWidthMD {
    height: 160px;
    flex: 1 1 200px;
    max-width: 200px;
    margin: 14px 20px;

    .name {
      font-size: 15px;
    }

    .count {
      font-size: 13px;
    }
  }

  @media @screenWidthLG {
    height: 170px;
    flex: 1 1 230px;
    max-width: 230px;
    margin: 16px 22px;

    .name {
      font-size: 16px;
    }

    .count {
      font-size: 14px;
    }
  }

  @media @screenWidthXL {
    height: 180px;
    flex: 1 1 260px;
    max-width: 260px;
    margin: 18px 25px;

    .name {
      font-size: 18px;
    }

    .count {
      font-size: 14px;
    }
  }

  .actions-menu {
    position: absolute;
    top: -25px;
    right: 2px;

    .dropdown {
      .toggle-btn {
        background: transparent;
        padding: 0;
        height: 20px;
        color: @grey-shade-2;

        &:focus {
          -webkit-box-shadow: 0 0 0 0;
          -moz-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
        }

        .svg-inline--fa {
          vertical-align: 0;
        }
      }

      .dropdown-menu {
        min-width: 60px;
        text-align: center;
      }
    }
  }
}

.cards-list {
  display: flex;
  flex-flow: row wrap;
  padding: 40px 20px;
  overflow-y: auto;
  max-height: ~"calc(100vh - 230px)";

  @media @screenWidthMD {
    padding: 20px;
  }

  @media @screenWidthLG {
    padding: 40px;
  }
}

.items-list {
  padding: 0;
  height: ~"calc(100vh - 320px)";
  overflow-y: auto;
  position: relative;
  overflow-x: auto;

  .btn {
    margin-right: 10px;
  }

  .toggle-btn {
    padding: 0 6px;
  }
}

.row.double-margin {
  margin-right: -30px;
  margin-left: -30px;
}
