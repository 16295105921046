@import "../../../styles/variables";

.AtLabel {
  .textWeightBold;
  margin-bottom: @margin-xxs;

  & + .AtSubLabel {
    margin-top: 0;
  }
}
