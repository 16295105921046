@import "../../../../../../styles/variables";

.AtTableRowCell {
  &.isHidden {
    display: none;
  }

  &.displayOnHover {
    > * {
      display: none;
    }
  }

  &.size-sm {
    padding: @padding-xs;
  }
  &.size-md {
    padding: @padding-sm;
  }
  &.size-lg {
    padding: @padding-md;
  }

  .TableItemButtons {
    justify-content: flex-end;
  }

  &.checkbox {
    display: flex;
    justify-content: center;
  }

  &.iconDropdown {
    padding: 0 @padding-xs;
  }
}
