@import "../../../styles/variables";

.AtCheckableCard {
  &.is-disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }
}
