@import "../../../styles/variables";

#ImproveAssessmentContent {
  .AtWizardStepHeader {
    margin-bottom: @margin-md;

    .description-section {
      max-width: 100%;
    }
  }

  .improvement-objectives-container {
    > .AtParagraph {
      padding: @padding-sm @padding-xl @padding-xs @padding-xl;
      margin: @margin-xs 0;
      text-align: center;
    }
  }

  .AtContainer {
    .IconTitleSubtitle {
      padding: @padding-sm @padding-xl @padding-xs;
    }
  }

  .ResultTableContainer {
    .table-filter-header {
      padding: @padding-md;
    }
  }
  @media @screenWidthMaxSM {
    .AtWizardStepHeader {
      margin-bottom: 0;
      padding: @padding-md @padding-sm (@padding-xs + @padding-xxs) @padding-sm;
    }
  }
}
