@import "../../../styles/variables";

.AtIconTooltip {
  .flexLayout();

  &.circle {
    .borderRadius(50%);
    .cardBorder;
    background-color: white;
  }

  svg:focus {
    outline: none;
  }
}
