@import "../../../../styles/variables";

.TeamDevelopmentActionPlanObjectivesSectionLeverage {
  > .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: @padding-md @padding-lg;
    // border-bottom: @layout-border;
    border-radius: @pixels-xs @pixels-xs 0 0;

    > .icon-container {
      .flexLayout();
      .square(@pixels-xxl);
      font-size: @pixels-md;
      color: @grey-shade-2;
      margin-right: @margin-xs;
    }

    > .section-header-details {
      flex: 1;

      > .section-title {
        .textWeightMedium;
        margin-bottom: 0;
      }

      > .section-description {
        .textSizeMedium;
        .textWeightMedium;
      }
    }
  }

  .footerContainer {
    display: flex;
    width: 100%;
    height: 80px;
    border-top: 1px solid #e5e5e5;
    align-items: center;
    justify-content: center;

    .AtButton {
      transition: border 1s;
      border: 1px solid #e0e0e0 !important;

      &:hover {
        border: 1px solid #303030 !important;
      }

      &:active {
        border: 2px solid #e0e0e0 !important;
      }
    }
  }

  @media print {
    & ~ .TeamDevelopmentActionPlanObjectivesSectionLeverage {
      page-break-before: always;
      padding-top: @page-top-padding;
    }

    > .section-header {
      padding: @padding-md 0;
      margin-bottom: -@page-top-padding + @margin-md;
    }

    .ReportPageHeader {
      padding-top: 0;
    }

    .actionPlanTablesRow-container {
      // .ActionPlanTablesRow:not(:first-child) {
      //   page-break-before: always;
      // }

      .ActionPlanTablesRow:first-child {
        position: relative;
      }
    }

    > .CompDevActionPlanObjectiveRow {
      &:not(:last-child) {
        page-break-after: always;
      }

      .AtCollapse-collapse-content {
        > .content {
          .scores-section {
            margin-bottom: -@margin-lg;
            border: none !important;
          }

          > .AtSubsection-details {
            padding-top: @page-top-padding;
            padding-bottom: 0;
          }
        }

        .applications-subsection {
          margin-bottom: -@margin-lg;
          padding-top: @padding-lg;

          .AtCollapse-collapse-content {
            > .content {
              > .AtSubsection-details {
                page-break-after: avoid;
              }

              > .Atsubsection-content {
                page-break-inside: auto;

                .ObjectiveApplication {
                  &:not(:first-child) {
                    padding-top: @page-top-padding;
                  }

                  &:not(:last-child) {
                    margin-bottom: -@page-top-padding;
                  }

                  page-break-inside: avoid;
                }
              }
            }
          }
        }

        .learning-resources-subsection {
          padding-top: @padding-xxl;

          .AtCollapse-collapse-content {
            page-break-inside: avoid;

            > .content {
              .learning-resources {
                padding-top: (@padding-sm + @padding-xxs);
                page-break-inside: avoid;

                .learning-resource-cell {
                  flex-basis: auto;
                }
              }
            }
          }
        }
      }
    }

    .footerContainer {
      visibility: hidden;
    }
  }
}

.internetExplorer {
  .TeamDevelopmentActionPlanObjectivesSection {
    > .CompDevActionPlanObjectiveRow {
      .AtCollapse-collapse-content {
        .AtCollapse {
          .learning-resources {
            margin: -@margin-xxs;

            > .learning-resource-cell {
              margin: @margin-xxs;
              flex: 0 0 32%;

              > .LearningResource {
                flex: 1 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
