@import "../../styles/variables";

.DocumentSection {
  padding: @padding-sm @padding-xl;

  @media print {
    page-break-inside: avoid;
    padding: @padding-sm 0;

    & ~ .DocumentSection {
      padding-top: 0 !important;
    }

    &:last-child {
      padding-bottom: 0 !important;
    }
  }
}
