@import "../../styles/variables";

#DashboardContainer {
  height: 100%;
  padding: @default-container-padding;

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .DateRangePicker {
      margin-right: 16px;
    }
  }

  .scroll-container {
    height: ~"calc(100vh - 270px)";
    overflow-y: auto;

    #DashboardGraph {
      margin-bottom: 16px;
    }
  }
}
