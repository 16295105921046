@import "../../styles/variables";

.AtStyleTeamLead {
  width: 100%;
  .style-legend-and-score {
    display: flex;
    justify-content: space-between;
    margin-top: @margin-sm;
    align-items: center;
    padding-bottom: @padding-xxs;
    border-bottom: 1px solid @grey-color-bg;
  }
}
