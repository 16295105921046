@import "../../../../styles/variables";

.ActionPlanHeaderRow {
  display: flex;
  align-items: center;
  min-height: @pixels-xl;
  padding: @padding-xxs @padding-lg;
  border-top: 1px solid #e4e4e4;
  .objective-action-row {
    flex: 1;
    text-align: left;
  }

  .competencyRow {
    flex: 0 0 20%;
  }

  .sub-comp-or-type {
    flex: 0 0 20%;
  }

  .actionsCounters {
    flex: 0 0 10%;
    display: flex;
    justify-content: center;
  }

  .iconCol {
    flex: 0 0 3%;
  }

  @media print {
    position: relative;
    top: 75px;
  }
}
