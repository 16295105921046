@import "../../../styles/variables";

.AtTooltip {
  .borderRadius();
  .fontDefault();
  .textSizeMedium();

  &.show {
    opacity: 1 !important;
  }

  &.tooltip-display-dark {
    .tooltip-inner {
      border-color: @grey-color-base;
      background-color: @grey-color-base;
      color: white;
    }
  }

  &.tooltip-display-light {
    .tooltip-inner {
      background-color: white;
      color: @grey-color-base;
    }
  }

  &.size-xs {
    .tooltip-inner {
      .textSizeSmall;
      max-width: 232px;
      padding: @padding-xs (@padding-xs + @padding-xxs);
    }
  }

  &.size-sm {
    .tooltip-inner {
      max-width: 240px;
      padding: @padding-xxs @padding-xs;
    }
  }

  &.size-md {
    .tooltip-inner {
      max-width: 280px;
      padding: 6px (@padding-xs + @padding-xxs);
    }
  }

  &.size-lg {
    .tooltip-inner {
      max-width: 320px;
      padding: (@padding-xs + @padding-xxs) @padding-sm;
    }
  }

  .tooltip-inner {
    .borderRadius();
    max-width: 320px;
    text-align: left;
    padding: (@padding-xs + @padding-xxs) @padding-sm;
  }
}
