@import "../../styles/variables";

#ProductSelector {
  width: 400px;

  @media @screenWidthMaxSM {
    width: 100%;
    max-width: 400px;
    padding: @padding-xs @padding-md;
  }

  .products-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .AtSimpleCard {
      margin: 1px 8px;
      min-width: 180px;
      transition: flex-grow 1s linear;
      user-select: none;

      &.active {
        margin: 0 8px;
        .border(@primary-color-base, 2px);
      }
    }
  }
}
