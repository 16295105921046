@import "../../../../styles/variables";

.CompetencyEntryInput {
  text-align: left;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: @margin-xs;
  }

  .AtContainer-header {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .name-tags {
    .flexLayout(center, flex-start);
    gap: @pixels-sm;
    margin-bottom: @margin-xs;
  }

  .AtParagraph {
    padding-right: @padding-xl;
    margin-bottom: @margin-xs;
  }

  .bottom-section {
    .borderRadius(0) !important;
    border-top: @layout-border;

    .subCompetenciesTags {
      .flexLayout(center, flex-start);
      flex-wrap: wrap;
      gap: @pixels-xxs;
      margin-bottom: @margin-sm;
    }
  }
}
