@import "../../../styles/variables";

.AtStyleTeamMemberCard {
  .AtCard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;

    .AtContainer-header {
      justify-content: center;
      margin-bottom: @margin-xs !important;

      .avatarLeadContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .AtBadge {
          background-color: @grey-color-base;
          color: #fff;
          position: absolute;
          top: @pixels-md;
          border-radius: @pixels-xxs;
        }
      }
    }

    .bottom-section {
      text-align: center;
      flex-direction: column;

      .memberCardIconContainer {
        margin-top: 7px;
        display: flex;

        .AtmanIcon {
          background-color: #fff;
          margin: auto 0;

          @negativePosition: -5px;

          &:nth-child(2) {
            left: @negativePosition;
          }

          &:nth-child(3) {
            left: @negativePosition * 2;
          }
        }
      }

      .AtBadge {
        margin-top: @margin-xs;
      }
    }
  }

  @media print {
    border-bottom: 1px solid @grey-shade-7;

    .flexContainer {
      display: flex;
    }

    .AtCard {
      margin: 0 auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
      height: auto;

      .bottom-section {
        > .AtText {
          // (font-size * line-height * 2) to account for 2 lines of text at all times
          min-height: (18px * 1.25 * 2);
        }
      }
    }
  }
}
