@import "../../../../styles/variables";

.SidePanelNavigation {
  display: flex;
  align-items: center;
  padding: @pixels-xs @pixels-sm;
  border-bottom: 1px solid @grey-shade-9;
  margin-bottom: @margin-xs;
  position: sticky;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 0;

  .AtButton {
    border: none !important;
    margin: 0 @pixels-xs;
    .icon-container {
      svg {
        color: @grey-shade-1;
      }
    }
  }

  .AtButton:hover {
    background-color: @grey-shade-9;
    border-radius: 6px;
  }

  .AtTitle {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
