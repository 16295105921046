@import "../../global/styles/variables";

#AuthenticatedContainer {
  height: 100%;
  background-color: white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: @padding-xs @padding-md;
    margin-bottom: 120px;
    border-bottom: @layout-border;

    .AtSelect {
      margin-bottom: 0;
      min-width: 80px;
    }
  }

  .content {
    width: 400px;
    margin: 0 auto;
  }

  @media @screenWidthMaxSM {
    height: 100%;

    .header {
      margin-bottom: @margin-md;
    }

    //TODO: Create a Wrapper component to handle responsive layout
    .content {
      height: calc(100% - 80px);
      width: 100%;
      max-width: 400px;
      padding: @padding-xs @padding-md;
      overflow-y: scroll;
    }
  }
}
