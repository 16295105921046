@import "../../../styles/variables";

@media print {
  .AtUnipolarScaleGroup ~ .AtUnipolarScaleGroup {
    .scale-group-header {
      padding-top: @page-top-padding;
    }

    &.detailed {
      page-break-before: always;
    }
  }
}
