@import "../../../styles/variables";

.AssessmentDocumentsContainer {
  .AtProfileHeader {
    margin-bottom: @margin-xxs;
  }

  .AssessmentDocumentsHeader {
    margin-bottom: @margin-xxs;
  }
}
