@import "../../../../styles/variables";

.AtCompetenciesAverageScore {
  width: 180px;
  height: 170px;
  .flexStartLayout();
  align-items: center;
  flex-direction: column;
  padding: @padding-md 26px 0 26px;

  .score-average {
    .flexLayout();
    .minSquare((@pixels-xxl + @pixels-xs));
    .borderRadius(50%);
    font-size: 24px;
    color: @grey-color-base;
    .textWeightMedium;
    margin-bottom: (@margin-xs + @margin-xxs);

    &.StronglyDisagree {
      .border(@red-color-base, 2px);
    }

    &.Disagree {
      .border(@red-shade-4, 2px);
    }

    &.NeitherAgreeNorDisagree {
      .border(@yellow-color-base, 2px);
    }

    &.Agree {
      .border(@green-shade-4, 2px);
    }

    &.StronglyAgree {
      .border(@green-color-base, 2px);
    }

    &.empty {
      background-color: @grey-color-bg;
    }
  }

  .competency-source-label,
  .competency-source-title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    .textMaxLines();
  }

  .competency-source-label {
    .textSizeSmall;
    .textWeightMedium;
    text-align: center;
  }

  .competency-source-title {
    text-align: center;
    color: @grey-color-base;
    .textSizeLarge;
    .textWeightMedium;
  }
}
