@import "../../../styles/variables";

.RadioInputGroup {
  .InputHeader {
    margin-bottom: @margin-sm;
  }

  .invalid-feedback {
    display: block;
    .textWeightSemiBold;
  }
}
