@import "../../styles/variables";

#UpdateCard {
  .card-logo {
    .cardBorder();
    .borderRadius(2px);
  }

  #card-information-section {
    .fields-container {
      display: flex;
      justify-content: space-between;

      .label-input {
        flex: 0 0 50%;
      }

      .expirationMonth-input,
      .expirationYear-input {
        flex: 0 0 20%;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;

      .AtButton:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
