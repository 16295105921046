@import "../../../../styles/variables";

#OrgBaseCustomizationForm {
  padding: @padding-md @padding-xl;

  .AtTitleSubtitle {
    margin-bottom: @margin-md;
  }

  .base-customization-form {
    > .CustomValidatedTextInput {
      width: 50%;
    }

    > .LocalizedTextInput {
      margin-bottom: @margin-sm;
    }

    > .CardSaveButtons {
      margin-top: @margin-lg;
    }
  }
}
