@import "../../../styles/variables";

.RadioFormInput {
  max-width: 400px;
  margin-left: 0;
  color: @grey-color-base;

  .custom-control {
    padding-right: 0.5rem;
    display: inline;
    line-height: 24px;
    margin-bottom: 0;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &.label-sm label {
    .textSizeSmall;
    .textWeightMedium;
  }

  &.label-md label {
    .textSizeMedium;
    .textWeightMedium;
  }

  &.label-lg label {
    .textSizeLarge;
    .textWeightSemiBold;
  }

  &.inner-padding-sm {
    .custom-radio {
      padding-left: 1.5rem;

      label {
        &::before,
        &::after {
          left: -1.5rem;
        }
      }
    }

    .form-text {
      padding-left: 1.5rem;
    }
  }

  &.inner-padding-md {
    .custom-radio {
      padding-left: 2rem;

      label {
        &::before,
        &::after {
          left: -2rem;
        }
      }
    }

    .form-text {
      padding-left: 2rem;
    }
  }

  &.inner-padding-lg {
    .custom-radio {
      padding-left: 2.5rem;

      label {
        &::before,
        &::after {
          left: -2.5rem;
        }
      }
    }

    .form-text {
      padding-left: 2.5rem;
    }
  }

  .form-text {
    .textWeightMedium;

    &.size-sm {
      .textSizeSmall;
    }

    &.size-md {
      .textSizeMedium;
    }

    &.size-lg {
      .textSizeLarge;
    }
  }
}
