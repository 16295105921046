@import "../../styles/variables";

.CustomAlertContent {
  // Custom confirmation alert for Competency Development.
  .compdev-confirmation-alert-content {
    display: flex;
    flex-direction: column;

    > .content-details {
      color: @grey-shade-3;
      margin-bottom: @padding-md;
    }

    .sync-warning {
      display: flex;
      padding: 10px 16px;
      align-items: center;
      background-color: fade(@yellow-color-base, 29%);
      .borderRadius;
      .border(@yellow-color-base, 1px, dashed);

      > svg {
        margin-right: @margin-xs;
      }

      > span {
        color: @grey-color-base;
        .textSizeMedium;
      }
    }
  }

  > .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: @margin-lg;

    > .AtButton:not(:last-child) {
      margin-right: @margin-md;
    }
  }

  @media @screenWidthMaxSM {
    .compdev-confirmation-alert-content {
      > .content-details {
        .textSizeMedium;
        margin-bottom: @margin-sm;
      }

      .sync-warning {
        padding: @padding-xs @padding-sm;

        .icon-container {
          margin-right: @margin-xs;
        }
      }
    }

    > .buttons-container {
      flex-direction: column;
      margin-top: (@margin-md - @margin-xxs);

      > .AtButton {
        width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: @margin-xs;
        }
      }
    }
  }
}
