@import "../../../styles/variables";

.AtTableInfinite {
  width: 100%;
  display: flex;
  flex-direction: column;

  .AtTable {
    height: 100%;
  }

  .AtTableBase {
    overflow-x: hidden;
    overflow-y: scroll;

    .AtTableRow > .AtTableRowCell {
      .job-name {
        margin-top: 6px;
      }

      .name {
        margin-top: 2px;
      }
    }
  }
}
