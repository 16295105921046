@import "../../../styles/variables";

.AtTabCard {
  &.padding-sm {
    > .AtContainer-header {
      padding: @padding-xs @padding-md;
    }

    > .content {
      padding: @padding-sm @padding-md;
    }
  }

  &.padding-md {
    > .AtContainer-header {
      padding: @padding-sm @padding-lg;
    }

    > .content {
      padding: @padding-md @padding-lg;
    }
  }

  &.padding-lg {
    > .AtContainer-header {
      padding: @padding-sm @padding-xl;
    }

    > .content {
      padding: @padding-md @padding-xl;
    }
  }

  .AtContainer-header {
    border-bottom: @card-border;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .left-section {
      *:not(:last-child) {
        margin-right: (@margin-xs + @margin-xxs);
      }
    }

    .buttons {
      > * {
        &:not(:last-child) {
          margin-right: @margin-sm;
        }
      }
    }
  }
}
