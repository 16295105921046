@import "../../../../../styles/variables";

.ScoreRangeHighlight {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: @orange-shade-7;
  border: 1px @atman-orange solid;
  .borderRadius(4px);
}
