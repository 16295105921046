@import "../../../styles/variables";

.AtTitleSubtitle {
  text-align: left;
  width: 100%;

  .AtTitle {
    margin-bottom: @margin-xxs;
  }
}
