@import "../../../../../../../../../styles/variables";

.content-col {
  &.potential-col {
    display: flex;
    justify-content: center;
  }

  &.average-note-col {
    display: flex;
    position: relative;
    .flexLayout();
  }

  &.employee-note-col,
  &.manager-note-col {
    display: flex;
    .flexLayout();
    .textWeightMedium;
  }

  &.spread-col {
    .textWeightBold;
  }
}

@media @screenWidthMaxSM {
  .DetailedScoreColumns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .textSizeLarge;

    .section-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > .score-container {
        .flexLayout();

        &:not(:last-child) {
          margin-right: @margin-lg;
        }

        .AtPersonAvatar {
          margin-right: @margin-xs;
        }

        .score {
          .textWeightMedium;

          &.spread {
            .textWeightBold;
          }
        }
      }
    }

    .section-right {
      .flexLayout();
    }
  }
}
