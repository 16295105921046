@import "../../../styles/variables";

.AtInput {
  text-align: left;
  position: relative;

  label {
    .textSizeSmall;
    .textWeightBold;
  }

  .left-icon-container {
    position: absolute;
    display: inline-block;
    left: 10px;
    top: ~"calc(50% - 7px)";
    pointer-events: none;
  }

  &.has-icon {
    .form-control,
    .form-control.medium {
      padding-left: @padding-lg;
    }
  }

  &.invalidEmail,
  &.invalidEvaluatorName,
  &.noEmail {
    border: solid #ff0000 0.8px;
    border-radius: 5px;
  }

  &.invalidEmail:focus {
    border: solid #ff0000;
  }

  .form-control {
    .textSizeMedium;
    padding: 9px @padding-sm;
    height: inherit;
  }
}
