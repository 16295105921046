@import "../../styles/variables";

.AtProcessGuidancePanel {
  padding: @padding-md @padding-lg;
  text-align: center;

  .icon-container {
    display: inline-block;
    .square(80px);
    .borderRadius(50%);
    line-height: 80px;
    vertical-align: middle;
    font-size: 26px;
    margin-bottom: @margin-sm;
  }

  > .AtButton {
    margin-top: @margin-sm;
  }
}
