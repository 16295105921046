@import "../../../../../node_modules/react-loading-skeleton/dist/skeleton.css";
@import "../../styles/variables";

.SidePanelElementSkeleton {
  padding: @padding-md @padding-lg;
  border-bottom: @layout-border;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .skeletonContainer {
    width: 100%;
    text-align: left;

    .upper {
      width: 85%;
      margin-bottom: @pixels-xs;
      margin-right: @pixels-xl;
    }

    .lower {
      width: 60%;
      line-height: 0.85;
    }
  }
}
