@import "../../styles/variables";

.AtStyleScoreDescription {
  &.chunky,
  &.threeStyles {
    width: 49%;
    padding: @padding-sm @padding-md;
    flex-direction: column;
    margin-bottom: @margin-sm;

    align-items: flex-start;
    .svg-text-wrapper {
      width: 100%;
    }

    .atStyleScoreDescription-percentage {
      line-height: 70px;
    }
  }
  &.threeStyles {
    width: 33%;
  }
  margin-bottom: @margin-md;
  padding: @padding-xs @padding-lg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: @pixels-sm;

  .svg-text-wrapper {
    width: 80%;
    p {
      font-weight: 500;
      margin-top: @margin-xxs;
      font-size: @pixels-sm + 2px;
      line-height: @pixels-md;
    }
  }

  .atStyleScoreDescription-percentage {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 137px;
  }
}
