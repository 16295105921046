@import "../../../styles/variables";

.AtImageCard {
  height: 160px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;

  &.AtContainer.AtCard.noImageTextAlign {
    .flexLayout(baseline);
  }

  &.AtContainer.AtCard {
    .flexLayout(end);
  }

  .borderRadius(12px);

  .bottom-section {
    width: 100%;
    overflow: hidden;

    .imageText {
      padding: @padding-sm;
      background: linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 0.64) 31.77%);
      text-align: left;
      width: 100%;
      .textWeightSemiBold;
    }

    .atImageCardTextColor {
      color: @almost-white-color;
    }
  }
}
