@import "../../../styles/variables";

.AssessmentReport {
  &.competences-report {
    .AtCollapsibleCard {
      .content {
        .results-container:last-child {
          padding-bottom: @padding-sm;
        }
      }
    }
  }

  .AtCollapsibleCard {
    margin-bottom: @margin-xxs;

    .AtCollapsibleCard {
      margin-bottom: 0;
    }

    .content {
      padding-bottom: 0;
    }

    .content-header {
      display: flex;
      padding: 0 @padding-xl @padding-xxs @padding-xl;
      text-align: left;
    }

    .score-container {
      padding-right: @margin-md;
      margin-right: @margin-md; // When we hide this score-container, we want to clear all spacing so the title can be aligned with the left side. That's why the margin is set here.
      border-right: solid 1px @layout-border-color;

      min-height: 90px;
      display: flex;
      align-items: center;
    }

    .cognitive-intro {
      text-align: left;
    }

    .cognitive-container-profile,
    .cognitive-container-team {
      & ~ .AtCollapsibleCard {
        margin-top: @margin-sm;
      }
    }

    .cognitiveExplanations {
      text-align: left;
    }

    .cognitive-container-profile {
      display: flex;
      align-items: center;
      text-align: left;
      padding: @padding-xxs 0;
      margin: 0 40px;

      .header-container {
        .title {
          color: @grey-color-base;
          font-size: 18px;
          line-height: 23px;
          .textWeightBold;
        }
      }
    }

    .cognitive-container-team {
      .BaseAtScale {
        padding-top: @padding-xs;
      }
    }

    .fit-header-side-components-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .AtBadge {
        .textWeightBold;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 14px;
      }

      > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    .card-content-scale-legend {
      padding: 0 @padding-xxxl;
    }

    &.dimension-drilldown-card {
      .at-scale-container {
        padding: 0 @padding-xxxl; // MARK: If the padding is changed, it needs to be reflected in BaseAtScale.less > .BaseAtScale > .results-col > .line-container > .fit-score-container > left;
      }
    }
  }
}

.tooltip-inner.document-tooltip {
  max-width: 320px;
  text-align: left;
  padding: 6px 12px;
  background-color: @grey-color-base;
  color: white;
  line-height: 18px;
  font-weight: 100;
  font-family: Quicksand, sans-serif;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  a print {
    padding-top: @page-top-padding;

    .AtCollapsibleCard:first-child {
      padding-top: 0;
    }
  }
}
