@import "../../../../styles/variables";

.AtDownChevronMenuButton {
  white-space: nowrap;

  .icon {
    margin-left: @margin-xxs;
    margin-right: @margin-xs;
  }

  .chevron {
    font-size: 8px;
    margin-left: @margin-xs;
    margin-right: @margin-xxs;
  }
}
