@import "../../../styles/variables";

#BlockedBalanceMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: (@padding-xs + @padding-xxs) @padding-md;
  background: #fafafa;
  .border(@red-color-base);
  .borderRadius();
  cursor: pointer;

  label {
    .textSizeSmall;
    .textWeightMedium;
    margin-bottom: 0;
    margin-right: 16px;
  }
}
