@import "../../styles/variables";

.AtWizard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @heightWithoutHeader: ~"calc(100% - 56px)";

  > .content {
    height: @heightWithoutHeader;
    flex: 1;

    > .main-content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .step-content-container {
    overflow-y: auto;
    padding: 0;

    @media @screenWidthSM {
      padding: @padding-sm @padding-md;
    }

    @media @screenWidthMD {
      padding: @padding-md @padding-lg;
    }

    @media @screenWidthLG {
      padding: @padding-lg @padding-xxl;
    }

    flex: 1 1 auto;

    > * {
      max-width: @container-max-width-xl;
      margin: 0 auto;
    }
  }

  &.has-side-panel {
    > .content {
      display: flex;

      > .main-content {
        width: auto;
        flex: 1 1 auto;
      }

      > .side-panel-content {
        flex: 0 1 480px;
        max-width: 480px;
        background-color: white;
        border-left: @layout-border;
      }
    }

    .step-content-container {
      > * {
        max-width: @container-max-width-lg;
      }
    }
  }
}

.firefox,
.safari {
  .AtWizard {
    .step-content-container {
      > * {
        @media @screenWidthSM {
          margin-bottom: @margin-sm;
        }

        @media @screenWidthMD {
          margin-bottom: @margin-md;
        }

        @media @screenWidthLG {
          margin-bottom: @margin-lg;
        }

        margin-bottom: @margin-lg;
      }
    }
  }
}
