@import "../../styles/variables";

#SsoConfigurationForm {
  > .AtContainer {
    padding: @padding-md @padding-xl;

    > .AtContainer-header {
      margin-bottom: (@margin-sm + @margin-xxs);
    }
  }

  #AuthMethodForm {
    margin-bottom: @margin-md;

    .auth-types {
      margin-bottom: @margin-lg;

      .AtCheckboxTitleDescription {
        &:not(:last-child) {
          margin-bottom: @margin-sm;
        }
      }
    }
  }

  #SamlConfigurationForm {
    .sso-form {
      margin-bottom: @margin-md;
    }

    .information-fields {
      margin-bottom: @margin-xl;

      .metadata-export {
        margin-bottom: @margin-sm;

        .AtTitle {
          margin-bottom: @margin-sm;
        }
      }

      .TextCopyToClipboard {
        &:not(:last-child) {
          margin-bottom: @margin-sm;
        }
      }
    }
  }
}
