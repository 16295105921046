@import "../../../../styles/variables";

#user-information-form {
  .CustomTextInput,
  .CustomValidatedTextInput,
  .AtSelect {
    label {
      .textSizeMedium;
    }
  }
}
