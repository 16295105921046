@import "../../../../../../../styles/variables";

.SubCompetencyContainer {
  padding: @padding-sm @padding-lg;

  .sub-competency-header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .sub-competency-header-title {
      margin-bottom: @margin-xs + @margin-xxs;
      .textSizeSmall;
      .textWeightBold;
      text-transform: uppercase;
    }

    .sub-competency-header-buttons {
      .flexStartLayout();
      flex-wrap: wrap;
      align-items: center;
      max-width: 100%;
      width: 100%;
      margin: -@margin-xxs -@margin-xxs @margin-xxs;

      .AtCard {
        margin: @margin-xxs;
        max-width: 32%;

        .AverageScoreDisplayWithLabel {
          span {
            .textSizeSmall;
            .textWeightBold;
          }
        }
      }
    }
  }

  // print version
  .table-row-content-container {
    .flexStartLayout();
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin: -@margin-xxs -@margin-xxs @margin-xxs;

    .sub-competency {
      .sub-competency-average-score {
        .flexStartLayout();
        align-items: center;
        margin-bottom: @margin-xs;

        span {
          .textSizeSmall;
          .textWeightBold;
          color: @grey-color-base;

          @media print {
            .textOverflow;
          }
        }
      }

      @media print {
        margin: @margin-xxs;
        flex-basis: 32%;

        .ObservableBehaviorsTable {
          display: none;
        }

        page-break-inside: avoid;
      }
    }
  }

  @media print {
    padding: 0;

    .sub-competency-header {
      padding-top: @padding-md;
    }

    .sub-competency {
      page-break-inside: avoid;

      &:not(:first-child) {
        padding-top: @page-top-padding;
      }

      &:not(:last-child) {
        margin-bottom: -@page-top-padding;
      }

      .sub-competency-average-score {
        display: flex;
        align-items: center;

        .AtValueColor {
          margin-right: (@margin-xs + @margin-xxs);
        }

        > span {
          .textWeightSemiBold();
        }
      }
    }
  }
}
