@import "../../../../../styles/variables";

.TableItemLink {
  display: flex;
  justify-content: space-between;
  width: 60px;
  align-items: center;

  .AtLink {
    text-decoration-line: underline;
  }
}

.TableItemLink:hover {
  .TextCopyToClipboard {
    display: block;
    .AtButton {
      display: block;
    }
  }
}
