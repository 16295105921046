@import "../../styles/variables";

#UserManagement {
  height: 100%;

  .dropDownMenu {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-121px, 32px, 0px);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .AtListHeader {
    margin-bottom: @margin-sm;
  }

  .filters {
    text-align: left;
    margin-bottom: 16px;

    .filter {
      .textSizeMedium;
      .textWeightMedium;
      margin: @padding-xxs @padding-xs;
      cursor: pointer;
      color: #818181;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &.selected {
        border-bottom: 2px solid @atman-orange;
        color: @grey-shade-2;
      }

      &:hover {
        color: @grey-shade-2;
      }
    }
  }

  .email {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 10vw;
  }
}
