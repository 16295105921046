@import "../../../styles/variables";

.LocalizedTextInput {
  text-align: left;
  position: relative;

  .left-icon-container {
    position: absolute;
    display: inline-block;
    left: 10px;
    top: ~"calc(50% - 12px)";
    pointer-events: none;
  }

  &.has-icon {
    .form-control {
      padding-left: 32px;
    }
  }

  .form-control {
    .textSizeMedium;
    padding: 9px @padding-sm;
    height: inherit;
  }

  .input-group {
    .input-group-text {
      .textWeightBold;
      background-color: @almost-white-color;
    }
  }
}
