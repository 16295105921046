@import "../../../styles/variables";

@separatorPadding: @padding-md;

.IconTitleSubtitle {
  .flexLayout(@justify-content: flex-start);
  text-align: left;

  .icon-container {
    .flexLayout();
    padding-right: (@padding-xs + @padding-xxs);

    &.separator {
      padding-right: @separatorPadding;
    }
  }

  .AtTitleSubtitle {
    .AtTitle {
      margin-bottom: @margin-xxs;
    }

    &.separator {
      padding-left: @separatorPadding;
      border-left: @layout-border;
    }
  }

  .HelpCenterInfoLink {
    display: flex;
    margin-top: @pixels-sm;
    a {
      justify-content: start;
      text-decoration: underline;
      .icon-container {
        display: none;
      }
    }
  }
}
