@import "../../styles/variables";

.FormGroupSection {
  background: #ffffff;
  .cardBorder;
  .borderRadius;
  margin-bottom: @margin-xs;

  .header {
    padding: @padding-sm;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: @card-border;

    .left-header-content {
      display: flex;
      align-items: center;

      .icon-container {
        display: flex;
        align-items: center;
        margin-right: 12px;
        font-size: 20px;
        color: @atman-orange;
      }

      h4 {
        .textWeightMedium;
        margin-bottom: 0;
      }
    }

    .right-header-content {
      display: flex;

      .AtButton:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .content {
    padding: @padding-md;
  }
}
