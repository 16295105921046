@import "../../../styles/variables";

#ProductUsageDashboard {
  text-align: center;

  .graph-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    position: relative;

    .total {
      position: absolute;
      top: calc(50% - 36px);
      left: calc(50% - 24px);
      min-width: 48px;

      label {
        margin-bottom: 0;
        color: @grey-shade-4;
        .textSizeMedium;
        .textWeightMedium;
      }

      h1 {
        margin-bottom: 0;
      }
    }
  }

  .products-list {
    .product-entry {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .usage-count {
        .textSizeMedium;
        .textWeightMedium;
      }
    }
  }
}
