@import "../../styles/variables";

#EntityNameForm {
  .inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 1 0 50%;
    }

    .CustomTextInput {
      max-width: 100%;
      text-align: left;

      @media @screenWidthXL {
        max-width: 47.5%;
      }
    }
  }
}
