@import "../../styles/variables";

.HelpCenterInfoBlock {
  .layoutBorder();
  .borderRadius();
  padding: @padding-sm @padding-md;
  text-align: left;
  max-width: 100%;

  @media @screenWidthMD {
    max-width: 280px;
  }

  @media @screenWidthLG {
    max-width: 320px;
  }

  @media @screenWidthXL {
    max-width: 380px;
  }

  .icon-container {
    .square(64px);
    .flexLayout();
    .borderRadius(2px);
    background-color: @primary-shade-9;
    font-size: 28px;
    margin-bottom: @margin-sm;
  }

  .content-section {
    margin-bottom: @margin-md;

    .description {
      .textSizeSmall;
      .textWeightMedium;
      color: @grey-shade-2;
    }
  }

  a {
    .textSizeSmall;
    .textWeightBold;

    svg {
      margin-left: @margin-xs;
    }
  }
}
