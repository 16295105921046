@import "../../styles/variables";

.BaseItemList {
  height: 100%;
  background: #ffffff;

  .react-bootstrap-table {
    height: 100%;

    .custom-control {
      z-index: 0;
    }
  }
}
