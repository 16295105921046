@import "../../styles/variables";

.AtPageHeader {
  text-align: left;

  .title {
    margin-bottom: @margin-xs;
  }

  .description {
    .textSizeMedium;
    .textWeightMedium;
    color: @grey-shade-2;
    margin-bottom: @margin-xs;
  }

  .HelpCenterInfoLink {
    display: inline-block;

    a {
      .textSizeSmall;
    }
  }
}
