@import "../../../styles/variables";

.AtLinkBase {
  cursor: pointer;
  .textWeightSemiBold;

  &.disabled {
    pointer-events: none;
    text-decoration: none;
    opacity: 0.5;
  }

  &.color-primary {
    @textColor: @primary-color-base;

    color: @textColor;

    &:not(:disabled):not(.disabled) {
      &:hover {
        text-decoration: underline;
      }

      &:active,
      &:focus,
      &:active:focus {
        color: @primary-shade-2;
      }
    }
  }

  &.color-secondary {
    @textColor: @grey-color-base;

    color: @textColor;

    &:not(:disabled):not(.disabled) {
      &:hover {
        text-decoration: underline;
      }

      &:active,
      &:focus,
      &:active:focus {
        color: @grey-shade-2;
      }
    }
  }

  &.color-danger {
    @textColor: @red-color-base;

    color: @textColor;

    &:not(:disabled):not(.disabled) {
      &:hover {
        text-decoration: underline;
      }

      &:active,
      &:focus,
      &:active:focus {
        color: @red-shade-2;
      }
    }
  }
}
