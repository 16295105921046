@import "../../../../../../styles/variables";

.DetailedSubCompetencyResultTable {
  .TableRow {
    // The following rules serve to account for the offset while maintaining the columns alignment
    @offsetWidth: @pixels-sm;
    @rowHorizontalPadding: @pixels-lg;
    @diffBetweenOffsetAndPadding: (@rowHorizontalPadding - @offsetWidth);

    .table-row-content-container {
      > .content {
        padding: @padding-sm @padding-lg;
        padding-left: @diffBetweenOffsetAndPadding;

        .AtTitle {
          margin-left: @offsetWidth;
        }

        .DetailedObservableBehaviorSubRow {
          .content-col.competency-col {
            margin-left: @offsetWidth;
          }

          &:not(:last-child) {
            padding-bottom: @padding-sm;
            border-bottom: 2px solid @grey-color-bg;
          }

          &:not(:first-child) {
            padding-top: @padding-sm;
          }

          .observable-behaviors-label {
            font-size: 12px;
            line-height: 15px;
            color: @grey-shade-1;
            .textWeightBold;
            text-transform: uppercase;
            margin-bottom: @margin-xs;
          }
        }
      }
    }
  }

  @media @screenWidthMaxSM {
    .DetailedSubCompetencyTableRow {
      .table-row-content-container {
        > .content {
          > .AtTitle {
            margin-left: 0;
          }
        }
      }
    }
  }
}
