@import "../../../../../../styles/variables";

.DevelopmentPlanStrategicObjectives {
  margin: @margin-xl @pixels-eighty;

  .AtSubsection {
    &.applications-container {
      display: flex;
      flex-direction: column;
      margin-bottom: @margin-sm;

      .Atsubsection-content {
        flex-direction: column;
        gap: @pixels-xxs;

        .AtCheckableCard {
          width: 100%;
        }

        .objective-row {
          display: flex;
          align-items: center;
          width: 100%;

          .AtParagraph {
            width: 100%;
            padding: 0 @pixels-md 0 0;
          }

          .showMoreBtn {
            border: none;
            visibility: hidden;
            overflow: hidden;
            white-space: nowrap;
            text-decoration: underline;
            margin-right: @pixels-lg;
          }

          .showMoreText {
            text-decoration: dashed underline;
          }

          .applications-numbers-and-icon {
            width: 120px;

            svg {
              margin: 0 @margin-xxs;
            }
          }

          &:hover .showMoreBtn {
            visibility: visible;
          }
        }
      }
    }

    &.learning-resources-container {
      .Atsubsection-content {
        justify-content: flex-start;
        margin-bottom: @margin-sm;

        .LearningResourceFilterContainer {
          width: 100%;
          margin: @margin-xs 0 @margin-sm;
        }

        .learningResourcesContainer {
          width: 100%;

          .LearningResource {
            width: 24%;
            height: 130px;
            margin-right: @margin-xs;
            margin-bottom: @margin-xs;
          }
        }

        .notes-input-container {
          margin: @margin-xs 0;
          width: 98%;
        }
      }
    }
  }

  .AtCard.AtContainer .AtContainer-header {
    align-items: center;
  }
}

.AtCollapse.left-collapse-content-offset > .AtCollapse-collapse-content > .content {
  margin-left: 0;
}
