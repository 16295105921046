@import "../../styles/variables";

#AtLayout {
  text-align: center;
  font-family: "Quicksand", sans-serif;
  background: @bg-color;
  overflow-y: hidden !important;
  display: flex;
  height: 100%;

  .app-container {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    z-index: 100;
    border-left: @layout-border;

    #app-content {
      background: @bg-color;

      //&.no-content-padding {
      //  padding: 0 !important;
      //}
    }
  }

  &.-device-mobile {
    height: 100%;

    .app-container {
      height: 100%;

      .sidebar-container {
        z-index: 1000;
        position: fixed;
        top: 56px;
        bottom: 0;
        left: 0;

        &.sidebar-container-open {
          right: 0;
        }
      }

      #app-content {
        //padding: @padding-md @padding-sm 0;
        .fixedLayout(56px);
      }
    }
  }

  &.-device-default {
    .sidebar-container {
      width: 64px;
      min-width: 64px;

      //@media @screenWidthXXL {
      //  width: 180px;
      //  min-width: 180px;
      //}
    }

    .app-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      #app-content {
        flex: 1;
        height: ~"calc(100% - 56px)";
      }
    }
  }
}
