@import "../../../styles/variables";

.LikertStatement {
  .statement {
    text-align: left;
    .textSizeMedium;
    .textWeightMedium;
  }

  &.-device-mobile {
    margin-bottom: 24px;

    .statement {
      padding: 0;
      .textSizeLarge;
      margin-bottom: 16px;
    }

    .options {
      display: flex;
      flex-direction: column;
      //align-items: center;
      justify-content: center;

      .RadioFormInput {
        width: 100%;
        text-align: left;

        > label {
          width: 100%;
          .border(#BBBBBB);
          .borderRadius();
          padding: 14px 12px;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.checked {
          > label {
            .border(@primary-color-base);
          }
        }
      }
    }
  }

  &.-device-default {
    .cardBorder();

    &:not(:first-child) {
      border-top-width: 0;
    }

    .statement {
      padding: (@padding-xs + @padding-xxs) 18px;
    }

    .options {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .radio-container {
        flex: 1 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        .CustomStandaloneRadioInput {
          padding-left: 0;
        }
      }
    }
  }
}

.custom-control-label {
  &::after {
    background: ~"no-repeat 50% / 70% 70%";
  }
}
