@import "../../../../styles/variables";

.CompetencyDevelopmentAssessmentContainer {
  height: 100%;

  > * {
    margin: 0 auto;
  }

  &:not(.in-wizard) {
    padding: @padding-xl (@padding-md + @padding-lg);

    > * {
      margin-bottom: @padding-xl;
    }

    .LoadingIndicator {
      margin-top: @margin-lg;
    }
  }

  &.in-wizard {
    > * {
      height: 100%;
    }
  }
}
