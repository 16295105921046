@import "../../../styles/variables";

.AtEditableElement {
  display: flex;
  align-items: center;

  .icon-container {
    margin-right: 8px;
  }

  .close-icon-container {
    margin-left: 6px;
    padding: 0 4px;
    cursor: pointer;

    &:hover {
      color: @primary-color-base;
    }
  }
}
