@import "../../../../styles/variables";

.TeamDevelopmentActionPlanObjectivesSectionImprove {
  > .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: @padding-md @padding-lg;
    border-radius: @pixels-xs @pixels-xs 0 0;

    > .icon-container {
      .flexLayout();
      .square(@pixels-xxl);
      font-size: @pixels-md;
      color: @grey-shade-2;
      margin-right: @margin-xs;
    }

    > .section-header-details {
      flex: 1;

      > .section-title {
        .textWeightMedium;
        margin-bottom: 0;
      }

      > .section-description {
        .textSizeMedium;
        .textWeightMedium;
      }
    }
  }

  > .CompDevActionPlanObjectiveRow {
    width: 100%;

    .AtCollapse-collapse-content {
      background-color: @almost-white-color;

      .AtCollapse {
        > .AtCollapse-toggle-row {
          .textSizeMedium;
          .textWeightSemiBold;
        }

        .action-plan-subsection-title {
          .textSizeLarge;
          .textWeightBold;
          margin-bottom: @margin-xxs;
        }

        .action-plan-subsection-description {
          .textSizeMedium;
          .textWeightMedium;
        }

        .notes-subsection-details {
          margin-bottom: @margin-sm;

          .action-plan-notes-subsection-title {
            .textWeightSemiBold;
          }

          .notes-container {
            .borderRadius(6px);
            .textWeightMedium;
            padding: @padding-sm @padding-md;
            background-color: @grey-color-bg;
          }
        }

        .learning-resources {
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          padding-bottom: @padding-md;

          @gapSpace: @margin-xxs;
          margin: -@gapSpace;

          .learning-resource-cell {
            margin: @gapSpace;
            box-sizing: border-box;
            flex-basis: 100%;

            > .LearningResource {
              height: 100%;
            }
          }

          @media @screenWidthXL {
            .learning-resource-cell {
              flex-basis: 49%;
            }
          }

          @media @screenWidthXXXL {
            .learning-resource-cell {
              flex-basis: 32%;
            }
          }
        }
      }

      .DocumentSection {
        background-color: white;

        &.scores-section {
          &:not(:last-child) {
            border-bottom: @layout-border;
          }
        }
      }
    }
  }

  .footerContainer {
    width: 100%;
    height: 80px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;

    .AtButton {
      transition: border 1s;
      border: 1px solid #e0e0e0 !important;

      &:hover {
        border: 1px solid #303030 !important;
      }

      &:active {
        border: 2px solid #e0e0e0 !important;
      }
    }
  }

  @media print {
    & ~ .TeamDevelopmentActionPlanObjectivesSection {
      page-break-before: always;
    }

    .ReportPageHeader {
      padding-top: 0;
    }

    .actionPlanTablesRow-container {
      .ActionPlanTablesRow:not(:first-child) {
        page-break-before: always;
      }

      .ActionPlanTablesRow:first-child {
        position: relative;
      }
    }

    .footerContainer {
      display: none;
    }
  }
}
