@import "../../styles/variables";

#UserFormModalContent {
  form input:disabled {
    cursor: not-allowed;
  }

  .FormGroupSection > .header {
    padding: 18px @padding-md;
  }

  #roles-group {
    .invalid-feedback {
      display: block;
    }
  }

  .additional-roles-selection {
    display: flex;
    flex-direction: column;

    .AtCheckboxTitleSubtitle {
      max-width: inherit;
      margin-bottom: 16px;
      padding-left: 0;
    }
  }

  #user-roles-form {
    > .content {
      padding: 0;

      .col-sm-12 {
        &:last-child > .AtCollapse {
          border-bottom: none;
        }

        .AtCollapse {
          border-top: none;

          &.selected {
            .AtCollapse-toggle-row {
              .border(@primary-color-base);
            }
          }

          .AtCollapse-toggle-row {
            .RadioFormInput {
              max-width: none;
              margin-bottom: 0;

              .custom-control {
                z-index: initial;
              }
            }
          }

          .content {
            padding: 0;

            .additional-roles-selection {
              padding: (@padding-md - @padding-xxs) @padding-md @padding-xs @padding-xxxl;

              .additional-roles-label {
                .textSizeLarge;
                .textWeightMedium;
                margin-bottom: 14px;
              }

              .additional-role-container {
                &:not(:last-child) {
                  margin-bottom: @margin-sm;
                  border-bottom: solid 1px @grey-shade-8;
                }

                .AtAsyncSelect {
                  padding-left: @padding-lg;
                }
              }
            }
          }
        }
      }
    }
  }

  .ModalButtons {
    justify-content: space-between;

    .AtButton {
      flex: 1;
    }
  }
}
