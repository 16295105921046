@import "../../styles/variables";

.DominantStyleRepartition {
  display: flex;
  justify-content: space-between;
  column-gap: @pixels-lg;

  .dominant-style-column {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    row-gap: @pixels-xs;
  }

  .mainContent {
    display: flex;
  }

  @media print {
    .dominant-style-column {
      flex-basis: 100%;
      row-gap: @pixels-sm;
    }
  }
}
