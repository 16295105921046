@import "../../../../../../../../styles//variables";

.AssessmentWizardStatementEntry {
  padding: @padding-sm 0;

  .left-section {
    margin-bottom: @margin-sm;

    @media @screenWidthMD {
      margin-bottom: 0;
    }

    .statement-label {
      .textWeightMedium;
      margin-bottom: 0;
    }

    .form-text {
      .flexLayout(@align-items: center);
      .textWeightMedium;

      .icon-container {
        .flexLayout();
        margin-right: @margin-xs;
      }
    }
  }

  &:not(:last-of-type) {
    border-bottom: @layout-border;
  }
}
