@import "../../styles/variables";

#ProductBalanceView {
  background: #ffffff;
  .cardBorder();
  .borderRadius();
  text-align: left;
  margin-top: 47px;

  .header {
    display: flex;
    align-items: center;
    padding: @padding-sm;
    border-bottom: @card-border;

    .icon-container {
      margin-right: 12px;

      svg {
        color: @atman-orange;
      }
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .content {
    padding: @padding-sm @padding-sm @padding-md;
    align-items: center;

    .products {
      padding-bottom: @padding-md;
    }

    .AtButton,
    .view-balance-history-link {
      display: block;
      text-align: center;
      margin: 0 auto;

      &.AtButton {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  @media @screenWidthXXL {
    margin-top: 51px;

    .header {
      padding: @padding-md;
    }

    .content {
      padding: @padding-lg @padding-md;

      .AtButton {
        margin-bottom: 24px;
      }
    }
  }
}
