@import "../../../../styles/variables";

.CompetencySetTemplateCard {
  display: flex;
  flex: 0 0 calc(100% / 3 - @margin-sm / 3);
  text-align: left;

  .bottom-section {
    flex: 1;
    .flexLayout(flex-start, space-between, column);

    > div {
      &:not(:last-child) {
        margin-bottom: @margin-sm;
      }

      > .AtParagraph {
        color: @grey-shade-3;
      }
    }

    > .competency-count {
      .flexLayout(center, flex-start);
    }
  }
}
