@import "../../styles/variables";

.EmailAccountComponent {
  padding: @padding-xxs;
  display: flex;
  align-items: center;
  .cardBorder();
  .borderRadius();

  .AtButton {
    margin-right: @margin-xxs;
  }

  .email {
    .textWeightBold;
    color: @grey-color-base;
  }
}
