@import "../../../styles/variables";

.BaseAtScale {
  width: 100%;

  .BaseAtScaleLabel {
    .textSizeMedium;
    .textWeightSemiBold;
  }

  .description {
    text-align: left;
  }

  &:hover {
    .actions-col {
      .show-more-link {
        opacity: 1;
      }
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .above-labels {
    margin-bottom: @margin-xs;
  }

  .dimension-label-col {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    > h4 {
      .textSizeMedium;
      .textWeightMedium;
      line-height: 1;
      margin-bottom: 0;
      margin-right: 0;
    }

    .AtBadge {
      .textWeightBold;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 14px;
    }

    &.bipolar-label-col {
      width: 24%;
    }

    &.unipolar-label-col {
      width: 46%;
      margin-right: @margin-xs;
    }

    &.right-align-label {
      text-align: right;
      justify-content: flex-end;
    }

    &.left-align-label {
      text-align: left;
      justify-content: space-between;
    }
  }

  .results-col {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    min-height: 20px;

    & + .AtSubScaleNavigation {
      @media print {
        padding-top: @padding-xs;
        .AtScaleLegend {
          margin-top: 0;
        }
      }
    }

    &:hover {
      background-color: @almost-white-color;
      cursor: pointer;
      transition: 0.3s;
    }

    .scale-container {
      margin: 0 @margin-xl;
      padding: @padding-xxs 0;

      @media print {
        margin: 0;
      }

      &.compact {
        min-height: @pixels-lg;
      }

      .target-icons {
        .flexLayout();

        svg:not(:last-child) {
          margin-right: @margin-xxs;
        }
      }

      .sub-scale-container {
        text-align: left;
        align-items: center;
        height: 100%;

        .inline-score {
          text-align: right;
          padding: 0 @padding-sm 0 0;
          font-size: 20px;
          line-height: 25px;
          font-weight: bold;
          letter-spacing: 1px;
          color: @grey-color-base;

          .denominator {
            .textSizeMedium;
            color: @grey-shade-2;
          }
        }
      }
    }

    .FitScoreContainer {
      position: absolute;
      top: @pixels-xs;
      left: @pixels-sm;
      .square(@pixels-xs);
      .borderRadius(6px);
      @media print {
        top: 13px;
        left: -@pixels-md;
      }
    }
  }

  .distribution-container {
    display: flex;
    position: relative;

    .ScoreRangeHighlight {
      margin-top: 12px;
    }

    .distribution-people-group {
      padding: 14px 2px;
      margin: 0 2px;
      display: inline-block;
      text-align: center;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: @margin-xs;
      }

      & > * {
        margin-bottom: @margin-xs;
        .multiplePagePrintHack();
      }
    }
  }

  &.AtUnipolarScale {
    .results-col {
      .FitScoreContainer {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .actions-col {
    text-align: left;

    &.is-open,
    &:hover {
      .show-more-link {
        opacity: 1;
      }
    }

    .show-more-link {
      .textWeightMedium;
      .textSizeSmall;
      padding: @padding-xs 0;
      color: @grey-shade-2;
      float: right;
      opacity: 0;
      transition: opacity 0.3s;

      &:hover {
        color: darken(@grey-shade-2, 10%);
        text-decoration: none;
      }
    }
  }

  .expanded-additional-info-col {
    padding: 0;

    .collapsed-additional-info-panel {
      &.show,
      &.collapsing {
        display: flex;
      }

      .collapsed-additional-info-panel-content {
        margin: @pixels-xs @pixels-xl @alternate-pixels-l @pixels-xl;
        width: 100%;
      }
    }
  }

  @media print {
    .description {
      .AtTitle {
        .textSizeMedium;
        .textWeightSemiBold;
        .fontQuicksand;
      }
    }

    .above-labels {
      display: none !important;
    }

    .scale-container .d-md-flex {
      display: flex !important;
    }

    &.AtUnipolarScale.detailed {
      page-break-inside: avoid;
      &:not(:last-child) {
        margin-bottom: -@page-top-padding;
      }

      & ~ .BaseAtScale {
        padding-top: @page-top-padding;
      }
    }

    &.AtBipolarScale.detailed {
      & ~ .BaseAtScale {
        &.cognition {
          page-break-before: avoid;
        }
      }
    }
  }
}

@media print {
  .BaseAtScale {
    .results-col .scale-container .sub-scale-container .inline-score {
      display: none;
    }
  }
}
