@import "../../../global/styles/variables";

#AcceptOrDenyRequestAccess {
  .LoginComponentHeader {
    display: block;
    .AtTitle {
      margin-top: @margin-sm;
    }
  }

  .AtCheckbox {
    border: none;
    background-color: #fff;
    margin-top: @margin-sm;
    label {
      color: #262626;
      padding-left: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .buttonGroup {
    display: flex;
    margin-top: @margin-md;

    .AtButton {
      margin-right: @margin-xs;
    }

    .AtButton.refuseButton {
      color: #303030;
    }
  }
}
