@import "../../../../styles/variables";

.ScaleRow {
  width: 100%;

  &:hover {
    .actions-col {
      .show-more-link {
        opacity: 1;
      }
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .above-labels {
    margin-bottom: @margin-xs;
  }

  .dimension-label-col {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    > h4 {
      margin-bottom: 0;
      margin-right: 0;
      .textSizeMedium;
      .textWeightMedium;
      line-height: @pixels-md;
    }

    .AtBadge {
      .textWeightBold;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 14px;
    }

    &.bipolar-label-col {
      width: 24%;
      margin-left: @margin-xs;
      margin-right: @margin-xs;
    }

    &.unipolar-label-col {
      width: 46%;
      margin-right: @margin-xs;
    }

    &.right-align-label {
      text-align: right;
      justify-content: flex-end;
    }

    &.left-align-label {
      text-align: left;
      justify-content: flex-start;
    }
  }

  .results-col {
    align-items: center;
    padding: 0 @padding-xl;
    min-height: 20px;

    & + .AtSubScaleNavigation {
      @media print {
        padding-top: @padding-sm;
      }
    }

    &:hover {
      background-color: @almost-white-color;
      cursor: pointer;
      transition: 0.3s;
    }

    .scale-container {
      margin: 0 40px;
      padding: 2px 0;

      &.compact {
        min-height: @pixels-lg;
      }

      .sub-scale-container {
        text-align: left;
        align-items: center;
        height: 100%;

        .inline-score {
          text-align: right;
          padding: 0 @padding-sm 0 0;
          font-size: 18px;
          line-height: 25px;
          font-weight: bold;
          letter-spacing: 1px;
          color: @grey-color-base;

          .denominator {
            .textSizeMedium;
            color: @grey-shade-2;
          }
        }
      }
    }

    .FitScoreContainer {
      position: absolute;
      top: @pixels-sm;
      left: @pixels-sm;
      .square(12px);
      .borderRadius(6px);
    }
  }

  .line-container {
    position: relative;
    height: (@pixels-xs + @pixels-xxs);
    background: @bg-color;
    width: 100%;
    .borderRadius();

    .range-container {
      @bgColor: fade(@grey-shade-2, 30%);

      position: absolute;
      height: 30px;
      opacity: 0.5;
      .border(@bgColor, 2px);

      background: @bgColor;
      .borderRadius();

      &.is-inverted {
        background: repeating-linear-gradient(-60deg, @bgColor, @bgColor 5px, transparent 5px, transparent 10px);
      }
    }
  }

  .distribution-container {
    display: flex;
    position: relative;

    .ScoreRangeHighlight {
      margin-top: 12px;
    }
  }

  &.AtUnipolarScale {
    .results-col {
      .FitScoreContainer {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .line-container {
      height: (@pixels-xs + @pixels-xxs);
      .borderRadius(8px);

      .range-container {
        height: 20px;
        top: -2px;
        .borderRadius(8px);
      }
    }
  }

  .expanded-additional-info-col {
    padding: 0 @padding-xl;

    .collapsed-additional-info-panel {
      &.show,
      &.collapsing {
        display: flex;
      }

      .collapsed-additional-info-panel-content {
        margin-top: 20px;
        width: 100%;
      }

      .score-separator {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e8e8e8;
      }

      .distribution-people-group {
        padding: 24px 0 12px 0;
        display: inline-block;
        text-align: center;
        z-index: 1;

        &:not(:last-child) {
          margin-bottom: @margin-xs;
        }

        & > * {
          margin-bottom: @margin-xs;
        }
      }
    }
  }

  @media print {
    .above-labels {
      display: none !important;
    }

    .scale-container .d-md-flex {
      display: flex !important;
    }

    .expanded-additional-info-col {
      .collapsed-additional-info-panel {
        .collapsed-additional-info-panel-content {
          margin-top: 0;
        }
      }
    }
  }
}
