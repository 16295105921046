@import "../../../../styles/variables";

.ActionPlanTablesRow {
  display: flex;
  align-items: center;
  .actionPlanCollapseRow {
    border-bottom: 1px solid #e4e4e4;
    width: 100%;

    .AtCollapse-collapse-content > .content {
      border-left: initial;
    }

    .actionPlanCollapseRow-content {
      display: flex;
      align-items: center;
      width: 100%;

      .icon-desc-row-section {
        display: flex;
        flex: 1;
        text-align: left;
        .AtIcon {
          margin-right: @margin-xs;
        }
        padding-right: @padding-xs;
      }

      .actionPlan-tablesRow-competency {
        flex: 0 0 20%;
        padding-right: @padding-xs;
      }

      .actionPlan-tablesRow-subComp-type {
        flex: 0 0 20%;
        padding-right: @padding-xs;
      }

      .actionPlan-tablesRow-actionsCount {
        flex: 0 0 10%;
        padding-right: @padding-xs;
        display: flex;
        justify-content: center;
        svg {
          margin-right: @padding-xxs;
        }
      }

      .actionPlan-tablesRow-iconCol {
        flex: 0 0 3%;
        padding-right: 0;
        display: flex;
        justify-content: center;
      }
    }
  }
  .actionPlanCollapseRow:last-child {
    border-bottom: none;
  }

  @media print {
    .multiplePagePrintHack(@offset: @margin-md);
    page-break-inside: auto;
    page-break-after: always;

    .AtCollapse {
      .AtCollapse-toggle-row {
        display: none;
      }

      .AtCollapse-collapse-content {
        .content {
          .DocumentSection {
            .action-plan-subsection-description {
              display: none;
            }
          }
        }
      }
    }
  }
}
