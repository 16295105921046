@import "../../styles/variables";

.strategicObjectiveInfoForm {
  .strategicObjectiveInfoFormContent {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  &.AtModal {
    overflow: hidden;
    max-width: 768px;
    max-height: 780px;
    padding: 0;
  }

  .competencyContainer {
    display: flex;
    flex-direction: row;
    gap: @pixels-md;
  }

  .competencySection {
    display: block;
    width: 43%;
  }

  .scoreSection {
    display: flex;
    gap: 6px;
    justify-content: start;
    align-items: center;
    .AtText {
      border-bottom: 1px dashed #d6d6d6;
      height: min-content;
      line-height: 20px;
    }
  }

  .text {
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: 1px dashed underline;
  }

  .explanation {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .applicationsHeader {
    display: flex;
    align-items: center;
  }

  .notes-input-container {
    padding-bottom: 40px;
  }
}
