/**
 * This file includes all the font styling variables used in the design system.
 **/

label {
  font-size: 14px;
  font-weight: 600;
}

.lineHeightDefault {
  line-height: 1.2;
}

.textSizeExtraSmall {
  font-size: 10px;
}

.textSizeSmall {
  font-size: 12px;
}

.textSizeMedium {
  font-size: 14px;
}

.textSizeLarge {
  font-size: 16px;
}

.textSizeExtraLarge {
  font-size: 18px;
}

.textSizeH6 {
  font-size: 12px;
  text-transform: uppercase;
}

.textSizeH5 {
  font-size: 14px;
}

.textSizeH4 {
  font-size: 16px;
}

.textSizeH3 {
  font-size: 18px;
}

.textSizeH2 {
  font-size: 20px;
}

.textSizeH1 {
  font-size: 24px;
}

.textWeightRegular {
  font-weight: 500;
}

.textWeightMedium {
  font-weight: 500;
}

.textWeightSemiBold {
  font-weight: 600;
}

.textWeightBold {
  font-weight: 700;
}

.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fontDefault {
  font-family: "Quicksand", sans-serif;
}

@domine: "Domine", sans-serif;
@quicksand: "Quicksand", sans-serif;

.fontDomine {
  font-family: @domine;
}

.fontQuicksand {
  font-family: @quicksand;
}

.fontDomineBold {
  .fontDomine;
  .textWeightBold;
}

.fontQuicksandBold {
  .fontQuicksand;
  .textWeightBold;
}
.fontDomineSemiBold {
  .fontDomine;
  .textWeightSemiBold;
}

.fontQuicksandSemiBold {
  .fontQuicksand;
  .textWeightSemiBold;
}
