@import "../../styles/variables";

.CustomTextInput {
  text-align: left;
  position: relative;

  label {
    .textSizeSmall;
    .textWeightBold;
  }

  .left-icon-container,
  .right-icon-container {
    position: absolute;
    display: inline-block;
    top: ~"calc(50% - 8px)";
  }

  .left-icon-container {
    pointer-events: none;
    left: 10px;
  }

  .right-icon-container {
    right: 10px;

    &.hasOnClickEvent {
      cursor: pointer;
    }
  }

  &.has-icon {
    .form-control {
      padding-left: 32px;
    }
  }

  .form-control {
    .textSizeMedium;
    padding: 9px @padding-sm;
    height: inherit;
  }

  .input-state-container {
    position: absolute;
    display: inline-block;
    right: 12px;
    top: ~"calc(50% - 15px)";

    #input-state-icon-container {
      font-size: 20px;
    }
  }
}
