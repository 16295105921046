@import "../../../global/styles/variables";

#SsoSignInContainer {
  .logo-container {
    display: flex;
    justify-content: center;

    .provider-logo {
      text-align: center;
      margin-bottom: @margin-lg;
      max-height: 120px;
      max-width: 420px;
    }
  }

  .provider-name {
    text-align: center;
  }

  .provider-description {
    text-align: center;
    margin-bottom: @margin-lg;
  }

  .provider-instructions {
    margin-bottom: @margin-md;
  }
}
