@import "../../../styles/variables";

.FitIndicator {
  .square((@padding-xs + @padding-xxs));
  display: inline-flex;
  vertical-align: middle;
  border-radius: 10px;

  &.VeryGood {
    background-color: @green-shade-4;
    .border(@green-color-base);

    &.isCompetency {
      background-color: @success-alt-shade-4;
      .border(@success-alt-color-base);
    }
  }

  &.GoodFit {
    background-color: @yellow-shade-4;
    .border(@yellow-color-base);

    &.isCompetency {
      background-color: @warning-alt-shade-4;
      .border(@warning-alt-color-base);
    }
  }

  &.BeVigilent {
    background-color: @red-shade-4;
    .border(@red-color-base);

    &.isCompetency {
      background-color: @danger-alt-shade-4;
      .border(@danger-alt-color-base);
    }
  }

  .count-label {
    display: inline-block;
    font-size: 12px;
    line-height: @pixels-md;
    .textWeightSemiBold;
    text-transform: uppercase;
    margin-left: 8px;
  }

  .count {
    font-size: 20px;
    line-height: @pixels-md;
    .textWeightBold;
    padding-left: (@padding-xs + @padding-xxs);
  }

  @media print {
    border: none;
  }

  @media print {
    border: none;
  }
}
