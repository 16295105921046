@import "../../../../styles/variables";

#ClientAccountInformationForm {
  text-align: left;

  .platform-use-label {
    .textWeightMedium;
    .textSizeSmall;
  }

  .platform-use-container {
    display: flex;
    align-items: center;

    .AtCheckbox {
      min-width: 140px;
      .textSizeMedium;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .CardSaveButtons {
    margin-top: 16px;
  }
}
