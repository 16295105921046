@import "../../../styles/variables";

.CompDevRequiredAssessmentsSelector {
  text-align: left;

  > .AtTitle {
    margin-bottom: @margin-sm;
  }

  .AtCheckboxTitleSubtitle {
    &:not(:last-child) {
      margin-bottom: @margin-sm;
    }
  }
}
