@import "../../../styles/variables";

#DashboardOwnersList {
  .react-bootstrap-table {
    height: inherit;

    .table {
      height: inherit;

      tbody {
        height: inherit;
      }
    }
  }
}
