@import "../../styles/variables";

.ModalButtons {
  display: flex;
  justify-content: flex-end;

  .AtButton:not(:last-child) {
    margin-right: 16px;
  }
}
