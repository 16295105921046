@import "../../../../../../styles/variables";

.AtTableNumberFilter,
.AtTableNumberRangeFilter {
  .AtTitle {
    font-style: normal;
    font-weight: 600;
    font-size: @alternate-pixels-s;
    line-height: @alternate-pixels-l;
  }
  .item {
    display: inline-block;
    width: 50%;
    margin-bottom: @margin-xs;
  }

  .layout {
    .flexLayout();
    justify-content: space-between;

    .numberInput {
      width: 50%;
      margin-right: @margin-sm;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .inputTitle {
    .fontDefault();
    color: @grey-color-base;
  }

  .input {
    border: 1px solid @grey-shade-7;
    border-radius: 6px;
  }
}
