@import "../../../styles/variables";

.FitScoreContainerAndLegend {
  display: flex;
  align-items: center;
  margin-right: @margin-xs;
  .borderRadius(6px);
  .iconTextWrapper {
    display: flex;
    align-items: center;
    span {
      font-size: @alternate-pixels-xs;
    }
  }

  .iconTextWrapper.withDescription {
    padding: 4px 4px 4px 0;
  }

  .fit-counter-and-text {
    display: flex;
    align-items: center;

    .AtBadge {
      margin-bottom: @margin-xs;
      padding: 0 @padding-xxs;
      &.veryGood {
        color: #fff;
      }

      &.goodFit,
      &.vigilent {
        color: #303030;
      }

      &.veryGood,
      &.goodFit,
      &.vigilent {
        height: @pixels-xl;
        width: @pixels-xl;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: @pixels-md;
        font-weight: 700;
      }
    }

    .AtText {
      margin-left: @margin-sm;
    }
  }
}

.statusAndCandidatesAvatar > .FitScoreContainerAndLegend {
  .AtBadge {
    padding: @padding-xxs @padding-xs @padding-xxs @padding-xxs;
    span {
      font-size: @pixels-sm;
    }
  }
}
