@import "../../../styles/variables";

.AtMenuButton {
  &.border-type-hidden {
    .AtButton {
      border-width: 1px !important;
      border-color: transparent !important;
    }
  }

  &.border-type-hover {
    .AtButton {
      border-width: 1px !important;
      border-color: transparent !important;
    }

    &:hover {
      .AtButton {
        border-width: 1px !important;
        border-color: @input-border-hover-color !important;
      }
    }
  }

  .AtButton,
  &:hover .AtButton {
    &:disabled,
    &.disabled {
      border-width: 0 !important;
      color: @grey-shade-4 !important;
    }
  }

  .AtButton {
    min-width: inherit;
    height: inherit;
  }
}
