@import "../../styles/variables";

.FormStepGroupSection {
  background: #ffffff;
  .cardBorder;
  .borderRadius;
  margin-bottom: 24px;

  .header {
    padding: @padding-sm;
    display: flex;
    align-items: center;
    border-bottom: @card-border;

    .step-number {
      height: 24px;
      width: 24px;
      font-size: 14px;
      line-height: 22px;
      background: #efefef;
      .borderRadius(50%);
      text-align: center;
      margin-right: 16px;
      margin-bottom: 0;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.11);
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .content {
    padding: @padding-md;
  }
}
