@import "../../../styles/variables";

.ResultTableContainer {
  @horizontalHeaderPadding: @padding-sm;

  .table-filter-header {
    padding: @padding-sm @horizontalHeaderPadding;

    .table-filter-header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: (@margin-xs + @margin-xxs);
      }

      .table-filter-header-col {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > * {
          &:not(:last-child) {
            margin-right: (@margin-xs + @margin-xxs);
          }
        }

        .AtSelect {
          min-width: 160px;
          margin-bottom: 0;
        }
      }
    }
  }
}
