@import "../../styles/variables";

.AtStyleSvgs {
  padding-bottom: @padding-xs;
  padding-top: @padding-xs;
  img {
    width: 100%;
    height: 75%;
    max-width: 15em;
    max-height: 1.5em;

    @media print {
      width: auto;
    }
  }

  &.Creatif,
  &.Ambassadeur,
  &.Analytique,
  &.Bienveillant,
  &.Fonceur,
  &.Rigoureux {
    img {
      height: 1em;
      width: auto;
    }
  }
}
