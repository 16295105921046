@import "../../styles/variables";
.tooltipBubbleChartContainer {
  .tooltip-inner {
    padding: 0;
    margin: 0;
  }
  .tooltipBubbleChartTopContent {
    .AtmanIcon {
      margin-right: @margin-xs;
    }
    display: flex;
    align-items: center;
    border-bottom: @layout-border;
    padding-bottom: @padding-xs;
    width: 100%;
  }

  .bubbleChartPercentageText {
    padding-right: @padding-sm;
  }

  .tooltipBubbleChartBottomContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: @pixels-xs;
    padding-bottom: @pixels-xs;
  }
}

.AtStyleResultCard {
  &.AtCard {
    .bottom-section {
      display: block;
    }
  }

  margin-bottom: @margin-xxs;
  margin-top: @margin-xxs;

  .AtTabs {
    .content {
      padding-top: @padding-md;
      margin-top: @margin-md;
      border-top: @layout-border;
    }
  }
}
