@import "../../styles/variables";

.ProcessStatus {
  display: flex;
  flex-direction: column;
  gap: @pixels-xs;

  .main {
    .text {
      .textWeightMedium;
    }

    .flexLayout(@justify-content:left);
  }

  .second {
    margin-left: @margin-xs;
    text-align-last: left;
    .textSizeSmall;
    color: @grey-shade-4;
  }
}
