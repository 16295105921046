@import "../../../styles/variables";

.InitialsAvatar {
  .flexLayout();
  .textWeightBold;
  .borderRadius(100%);

  &.size-xs {
    .square((@pixels-sm + @pixels-xxs));
    font-size: 10px;
    &.square {
      border-radius: 10%;
    }
  }

  &.size-sm {
    .square(@pixels-md);
    font-size: 10px;
    &.square {
      border-radius: 10%;
    }
  }

  &.size-md {
    .square((@pixels-md + @pixels-xxs));
    font-size: 10px;
    &.square {
      border-radius: 10%;
    }
  }

  &.size-lg {
    .square(@pixels-lg);
    font-size: 12px;
    &.square {
      border-radius: 10%;
    }
  }

  &.size-xl {
    .square(@pixels-xxl);
    font-size: 16px;
    &.square {
      border-radius: 10%;
    }
  }

  &.size-xxl {
    .square(@pixels-xxxl);
    font-size: 20px;
    &.square {
      border-radius: 10%;
    }
  }
}
