@import "../../../styles/variables";

.LearningResourceInline {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: @alternate-pixels-xs @pixels-sm;
  .LearningResourceTitleAuthor {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-items: start;
    align-items: start;

    .AtLinkBase {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .LearningResourceTypeLink {
    display: flex;
    flex-direction: row;
    flex: none;
    align-items: start;
    justify-content: center;

    span:last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .AtBadge {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  /*.top-section {
    justify-content: space-between;

    .AtBadge {
      .flexLayout();
      gap: 4px;

      .textWeightBold;
      text-transform: uppercase;
    }
  }

  .bottom-section {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    span,
    .AtLinkBase {
      .textWeightSemiBold;
      .textMaxLines();

      &.authors {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-3;
      }
    }
  } */

  @media print {
    page-break-inside: avoid;
    width: 100%;
    text-decoration: none !important;
  }
}
