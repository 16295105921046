@import "../../styles/variables";

#ConsumptionSchedulingForm {
  .filters {
    text-align: left;
    margin-bottom: 8px;

    .filter {
      .textSizeMedium;
      .textWeightMedium;
      margin: @padding-xxs @padding-xs;
      cursor: pointer;
      color: #818181;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &.selected {
        border-bottom: 2px solid @atman-orange;
        color: @grey-shade-2;
      }

      &:hover {
        color: @grey-shade-2;
      }
    }
  }

  .consumption-scheduling-table {
    .cardBorder();
    .borderRadius;
    text-align: left;
    .textSizeSmall;

    @media @screenWidthXXL {
      .textSizeMedium;
    }

    .header-row {
      padding: @padding-xs;
      border-bottom: @card-border;

      .table-header {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-4;
      }
    }

    .entry-row {
      padding: (@padding-xxs + @padding-xs) @padding-xs;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .button-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
