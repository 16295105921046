@import "../../styles/variables";

.UserFormModalContentCompDev {
  .AtContainer {
    display: flex;
    flex-direction: column;

    .first-and-last-name {
      padding: @padding-sm @padding-lg 0;
      display: flex;

      .firstNameInput,
      .lastNameInput {
        width: 50%;
      }

      .firstNameInput {
        margin-right: @margin-md;
      }
    }
    .emails-and-language {
      padding: @padding-sm @padding-lg;
    }

    .highlight-and-checkbox {
      padding: @padding-xs @padding-lg;

      .highlight {
        border-radius: @pixels-xs;
        padding: @padding-sm @padding-lg 20px;
        background-color: #f3f6ff;
        .icon-and-title {
          display: flex;
          align-items: center;
          .AtTitle.blueText {
            color: blue;
            margin-bottom: @margin-xxs;
          }
        }
        .highlight-text-info {
          margin-left: 28px;
        }
      }

      .checkbox-title-desc {
        padding: @padding-md 0;
        .checkbox-and-text {
          display: flex;
          align-items: center;
          h6.AtTitle {
            margin-bottom: 0;
            font-size: 14px;
          }
        }

        .highlight-text-info {
          margin-left: @margin-lg;
        }
      }
    }
  }

  &.AtTempModal {
    position: fixed;
  }
}
