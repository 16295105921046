@import "../../styles/variables";

.AtTabNavigationLayout {
  .nav-tabs {
    border-width: 0;

    .nav-item {
      .nav-link {
        .textSizeSmall;
        .textWeightBold;
        text-align: left;
        color: #818181;
        padding: @padding-sm;
        min-width: 8vw;
        text-decoration: none !important;

        &.active {
          color: @grey-shade-2;
          background-color: #ffffff;
          border-color: #efefef;
          border-bottom: @card-border;

          .icon-container {
            color: @atman-orange;
          }
        }

        .icon-container {
          margin-right: 12px;
        }
      }
    }
  }

  .tab-content {
    background: #ffffff;
    border: @card-border;
    padding: @padding-sm @padding-sm;
    overflow-y: auto;
    height: ~"calc(100vh - 218px)";
    border-top-right-radius: 4px;

    .AtTabCard {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @media @screenWidthXL {
      padding: @padding-lg @padding-md;
    }
  }

  &.-device-mobile {
    .headers {
      li:only-child {
        display: none;
      }
    }

    .tab-content {
      height: 100%;
      overflow: hidden;

      .tab-pane {
        .fixedLayout(56px);
        padding: @padding-sm;
        overflow-y: auto;
      }
    }
  }

  &.-device-default {
    .tab-content {
      padding: @padding-sm @padding-sm;
      height: ~"calc(100vh - 300px)";
      overflow-y: auto;
    }
  }
}
