@import "../../../styles/variables";

.AtTextList {
  ol,
  ul {
    list-style-position: inside;
    padding-inline-start: 0;
    margin-bottom: 0;

    li {
      &:not(:last-child) {
        margin-bottom: @margin-xs;
      }

      .AtParagraph {
        display: inline;
      }
    }
  }
}
