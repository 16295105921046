@import "../../../styles/variables";

#TeamDevelopmentImprovementObjectives {
  > .objectives-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: @padding-md @padding-lg;

    .IconTitleSubtitle {
      align-items: flex-start;
      justify-content: center;
      margin-right: @margin-lg;

      .title-container {
        .textWeightSemiBold();
        margin-bottom: @margin-xxs;
      }
    }
  }
}
