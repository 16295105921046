@import "../../styles/variables";

#PersonQuickView {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .CandidatePicture {
    margin-bottom: 8px;
  }

  .email {
    .textSizeMedium;
    .textWeightRegular;
  }
}
