@import "../../styles/variables";

#AtSidebar {
  background: white;
  color: @grey-shade-7;
  height: 100%;
  position: relative;

  .sidebar {
    .sidebar-header {
      margin-bottom: (@margin-xs + @margin-xxs);

      @media only screen and (min-height: 550px) {
        margin-bottom: (@margin-sm + @margin-xxs);
      }

      @media @screenHeightSM {
        margin-bottom: @margin-lg;
      }

      @media @screenHeightMD {
        margin-bottom: @margin-xxl;
      }

      @media @screenHeightLG {
        margin-bottom: @margin-xxxl;
      }

      @media @screenHeightXL {
        margin-bottom: @margin-eighty;
      }
    }

    .sections-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0 auto;

      .bottom-navigation {
        margin-bottom: @margin-md;
      }
    }
  }

  &.-device-mobile {
    padding: @padding-md 0;
    box-shadow: #00000029 0 3px 6px;
    transition: 0.5s;
    overflow-x: hidden;
    overflow-y: auto;

    &.sidebar-open {
      width: 80vw;
    }

    &.sidebar-close {
      width: 0;
    }

    .sidebar {
      height: 100%;
      text-align: left;

      .sidebar-header {
        .textSizeLarge;
        .textWeightBold;
        color: @grey-shade-2;
        padding: 0 @padding-sm;
      }

      .main-navigation {
        .SidebarNavItem {
          margin-bottom: 8px;
        }
      }

      .bottom-navigation {
        .SidebarNavItemContainer {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  &.-device-default {
    .sidebar {
      height: 100%;

      .sidebar-header {
        .flexLayout();
        padding-top: 16px;
      }
    }
  }
}
