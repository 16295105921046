@import "../../../styles/variables";

.TransactionExpandRow {
  width: 125%;
  display: flex;
  gap: 5%;

  .FormGroupSection {
    flex: 0 0 49%;
    text-align: left;
    margin-bottom: 0;
  }

  .affected-balance-section {
    .TransactionAffectedBalanceBadge {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  .notes-section {
    .content {
      .textSizeMedium;
    }
  }
}
