@import "../../styles/variables";

#ChangePasswordForm {
  svg {
    margin-right: 8px;
  }

  .password-rules {
    max-width: 70%;
    margin-bottom: 24px;
  }
}
