@import "../../../../../styles/variables";

.ResultTable {
  .TableHeader,
  .header-col,
  .content-col {
    text-align: center;

    &.competency-col {
      flex: 1;
      text-align: left;
    }

    &.average-note-col {
      flex: 0 0 15%;
    }

    &.average-note-scale-col {
      flex: 0 0 40%;
    }

    &.employee-note-col {
      flex: 0 0 10%;
    }

    &.manager-note-col {
      flex: 0 0 10%;
    }

    &.spread-col {
      flex: 0 0 12%;
    }
  }

  > .table-rows > .AtCollapse:first-child {
    border-top: none;
  }

  @media @screenWidthMaxSM {
    .TableHeader,
    .header-col,
    .content-col {
      text-align: center;

      &.competency-col {
        flex: 0 0 50%;
      }
    }

    .TableHeaderRow {
      padding: @padding-xs @padding-xs;
    }
  }

  @media @screenWidthSM {
    margin-bottom: @margin-sm;
  }

  @media @screenWidthMD {
    margin-bottom: @margin-md;
  }

  @media @screenWidthLG {
    margin-bottom: @margin-lg;
  }
}
