@import "../../styles/variables";

.AtTabBar {
  background: #ffffff;
  padding: 0 @padding-md;

  .flexStartLayout();
  align-items: center;

  .AtTabBarItem {
    &:not(:last-child) {
      margin-right: @margin-md;
    }
  }
}
