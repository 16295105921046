/**
 * This file includes all the layout mixins used in the design system.
 **/

.square(@length) {
  height: @length;
  width: @length;
}

.minSquare(@length) {
  min-height: @length;
  min-width: @length;
}

.fixedLayout(@top: 0, @right: 0, @bottom: 0, @left: 0) {
  position: fixed;
  .boxedLayout(@top, @right, @bottom, @left);
}

.absoluteLayout(@top: 0, @right: 0, @bottom: 0, @left: 0) {
  position: absolute;
  .boxedLayout(@top, @right, @bottom, @left);
}

.boxedLayout(@top: 0, @right: 0, @bottom: 0, @left: 0) {
  & when (isnumber(@top)) {
    top: @top;
  }

  & when (isnumber(@right)) {
    right: @right;
  }

  & when (isnumber(@bottom)) {
    bottom: @bottom;
  }

  & when (isnumber(@left)) {
    left: @left;
  }
}

.flexLayout(@align-items: center, @justify-content: center, @flex-direction: initial) {
  display: flex;
  align-items: @align-items;
  justify-content: @justify-content;
  flex-direction: @flex-direction;
}

.flexStartLayout() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: @pixels-xxs;
}

.baseDashedBoxContainer {
  .borderRadius();
}

.dashedBoxContainer-info() {
  .baseDashedBoxContainer;

  border: 1px dashed @primary-color-base;
  background: @primary-color-bg;
}

.dashedBoxContainer-warning() {
  .baseDashedBoxContainer;

  border: 1px dashed @yellow-color-base;
  background: @yellow-color-bg;
}

.dashedBoxContainer-error() {
  .baseDashedBoxContainer;

  border: 1px dashed @red-color-base;
  background: @red-color-bg;
}

.dashedBoxContainer-muted(@fadePercentage: 100%) {
  .baseDashedBoxContainer;

  border: 1px dashed fade(@grey-color-base, @fadePercentage);
  background: transparent;
}

.boxShadow(@blurRadius: 2px, @opacity: 0.16, @offsetY: 0) {
  box-shadow: 0 @offsetY @blurRadius rgba(0, 0, 0, @opacity);
}

.formBoxShadow(@color: @primary-shade-6, @blurRadius: 2px) {
  box-shadow: 0 0 0 @blurRadius @color;
}

.avoidPageBreakAfter(@unbreakable-height: 200px) {
  page-break-inside: avoid;

  ::after {
    content: "";
    display: block;
    height: @unbreakable-height;
    margin-bottom: -@unbreakable-height;
    page-break-inside: avoid;
  }
}

.textMaxLines(@lineHeight: @pixels-sm, @maxLines: 2) {
  word-wrap: break-word;
  overflow: hidden;
  max-height: (@lineHeight * @maxLines);
  line-height: @lineHeight;

  // Used to display the ellipsis. Not supported by IE (will be clipped instead).
  display: -webkit-box;
  -webkit-line-clamp: @maxLines;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.containerSpacer() {
  > * {
    margin-bottom: @margin-xs;
  }
}

.containerWidth() {
  max-width: 912px;
  margin: @margin-md auto;
}

.circleBorder() {
  .borderRadius(50%);
}

.fullPageTableMediaQuery {
  @media @screenWidthMD {
    max-width: none;
    width: 100%;
    margin: 0 auto;
    padding: @pixels-xs @pixels-md;
  }

  @media @screenWidthLG {
    max-width: 900px;
    margin: 0 auto;
    padding: @pixels-xs 0;
  }

  @media @screenWidthXL {
    max-width: 1050px;
    margin: 0 auto;
    padding: @pixels-xs 0;
  }

  @media @screenWidthXXL {
    max-width: 1200px;
    margin: 0 auto;
    padding: @pixels-xs 0;
  }

  @media @screenWidthXXXL {
    max-width: 1400px;
    margin: 0 auto;
    padding: @pixels-xs 0;
  }
}
