@import "../../styles/variables";

#CulturesContainer {
  .AtListHeader,
  .TableItemHeading {
    margin-bottom: @margin-sm;
  }

  .AtPageHeader {
    margin-bottom: @margin-md + @margin-xs;
  }

  .AtTable {
    width: 100%;
  }

  .cards-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .CultureCard {
      margin: @margin-xs;

      @marginToRemoveFromFlex: @margin-xs * 2;
      flex: 0 0 ~"calc(33.3% - @{marginToRemoveFromFlex})";

      @media @screenWidthXL {
        @marginToRemoveFromFlex: @margin-xs * 2;
        flex: 0 0 ~"calc(25% - @{marginToRemoveFromFlex})";
      }
    }
  }
}

.internetExplorer {
  #CulturesContainer {
    .cards-container {
      .CultureCard {
        @marginToRemoveFromFlex: @margin-xs * 9;
        flex-basis: ~"calc(33.3% - @{marginToRemoveFromFlex})";

        @media @screenWidthXL {
          flex-basis: ~"calc(25% - @{marginToRemoveFromFlex})";
        }
      }
    }
  }
}
