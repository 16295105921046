@import "../../styles/variables";

.AtStyleView {
  .AtContainerListSection {
    display: flex;

    .flexItems {
      margin-top: @margin-xs;
      margin-bottom: @margin-xs;
      width: 50%;
    }

    .leftContainer {
      margin-right: 1%;
    }

    .rightContainer {
      margin-left: 1%;
    }
  }

  .AtCollapsibleCardBehavior {
    .rowContent {
      display: block;
      .AtTitle {
        margin-bottom: @pixels-xs;
      }
    }

    .imageContainer {
      display: flex;

      .AtImageCard {
        margin-right: @margin-sm;
      }
    }
  }

  @media print {
  }
}
