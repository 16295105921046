@import "../../../styles/variables";

.AtIconTextCard {
  .flexLayout();
  justify-content: space-around;

  .AtIcon {
    margin-right: @margin-sm;
  }

  .AtTitleSubtitle {
    flex: 2;
    text-align: left;
  }

  .rightContent {
    margin: auto;
  }
}
