@import "../../styles/variables";

#UnavailableSystem {
  text-align: left;

  .hint-container {
    .AtTitleSubtitle {
      margin-bottom: @margin-sm;
    }

    .date-info {
      .textWeightBold;
      color: @grey-color-base;
    }
  }

  .help-center-section {
    margin: 0 @margin-sm;

    .help-center-link-subtitle {
      margin-top: @margin-sm;
    }
  }
}
