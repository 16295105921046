@import "../../styles/variables";

.AtStylePersonCard {
  margin-bottom: @margin-xxs;
  .AtContainer {
    .flexLayout(center, space-between);
  }

  .cvs {
    margin-right: @margin-xxs;
  }
}
