@import "../variables";

.DateRangePicker {
  .DateRangePickerInput__withBorder {
    border-color: @input-border-color;
    .borderRadius;

    &:hover,
    &:focus {
      border-color: @input-border-hover-color;
    }

    &:active,
    &.active {
      border-color: @primary-color-base;
    }
  }

  .DateInput {
    min-width: 147px;
    width: inherit;

    &:first-child,
    &:first-child input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child,
    &:last-child input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .DateInput_input {
      text-align: center;
      padding: 11px 10px 10px;
      .textSizeMedium;
    }

    .DateInput_input__focused {
      border-bottom-color: @primary-color-base;
    }
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    color: white;
    .border(@primary-color-base);
    background-color: @primary-shade-2;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__default:hover,
  .CalendarDay__selected_span {
    color: white;
    .border(@primary-color-base);
    background-color: @primary-color-base;
  }

  .CalendarDay__blocked_out_of_range:not(.CalendarDay__selected_span) {
    &,
    &:hover {
      background: #fafafa !important;
      color: #cacccd !important;
      border-color: #e4e7e7 !important;
    }
  }
}
