@import "../../../styles/variables";

.AtSubsection {
  > h6 {
    .textWeightBold;
  }

  > .AtParagraph {
    .textWeightMedium;
    color: @grey-shade-3;

    &:not(:last-child) {
      margin-bottom: (@margin-xs + @margin-xxs);
    }
  }

  .Atsubsection-content {
    .flexLayout();
    flex-wrap: wrap;
  }
}
