@import "../../../global/styles/variables";

#ChangePassword {
  width: 400px;
  margin: 0 auto;
  text-align: center;

  h1 {
    text-align: left;
  }

  @media @screenWidthMaxSM {
    width: 100%;
    max-width: 400px;
    padding-bottom: @padding-sm;
  }
}
