@import "../../../styles/variables";

.LoadingIndicator {
  .flexLayout();
  flex-direction: column;

  .icon-container {
    .spinner-icon {
      -webkit-animation: fa-spin 1s infinite cubic-bezier(0.17, 0.67, 0.83, 0.67);
      animation: fa-spin 1s infinite cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }
  }

  .label {
    margin-top: @margin-xs;
  }
}
