@import "../../styles/variables";
@import "./components/mixins";

#AtGlobalSearch {
  .menu-container {
    position: relative;
    width: 100%;
    height: 100%;
    .boxShadow(6px, @offsetY: 3px);
    padding: 0;

    @media @screenWidthMD {
      width: 400px;
      height: 400px;
    }

    @media @screenWidthLG {
      width: 500px;
      height: 500px;
    }

    @media @screenWidthXL {
      width: 600px;
      height: 600px;
    }

    .header {
      padding: 20px 16px 0;
      border-bottom: @layout-border;

      .app-switch-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: @margin-sm;
      }

      .search-input-container {
        .left-icon-container {
          svg {
            color: @primary-color-base;
          }
        }

        .form-control {
          .textWeightSemiBold;
          color: @grey-color-base;
        }
      }

      .filtersContainerMixin();
    }

    .results-container {
      overflow-y: auto;
      height: ~"calc(100% - 108px)";

      &.has-account-switcher {
        height: ~"calc(100% - 160px)";
      }

      .SearchResultEntry {
        padding: @padding-xs @padding-sm;
      }
    }
  }
}
