@import "../../styles/variables";

.ExpandableFormStepGroupSection {
  &:not(:last-child) {
    margin-bottom: @margin-xxs;
  }

  > .AtContainer-header {
    .left-section {
      //TODO: Should be a design-system atom component.
      .step-number {
        .square(@pixels-md);
        .textSizeLarge;
        .textWeightSemiBold;
        .flexLayout();
        border: 1px solid @grey-shade-3;
        .borderRadius(50%);
      }
    }
  }

  > .content {
    padding: 0 !important;

    .collapse-content {
      padding: @padding-md @padding-xl;
    }
  }
}
