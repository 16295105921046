@import "../../styles/variables";

.AtBaseSelectableCard {
  border-color: @input-border-color;

  &:hover {
    border-color: @input-border-hover-color;
  }

  &:active,
  &.active {
    border-color: @primary-color-base;
    background-color: rgba(27, 89, 234, 0.08);
    box-shadow: 0 0 4px rgba(27, 89, 234, 0.18);
  }

  > label {
    cursor: pointer;

    .custom-control {
      position: absolute;
      top: @padding-md;
    }
  }

  &.radio-position-top-right {
    > label .custom-control {
      right: @padding-sm;
      left: auto;
    }
  }

  &.radio-position-top-left {
    > label .custom-control {
      left: @padding-sm;
      right: auto;
    }
  }
}
