@import "../../styles/variables";

.AssessmentBalanceInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 8px;

  .info-container {
    display: flex;

    .assessments-included-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 24px;

      .ProductIcon {
        width: 12px;
        height: 12px;

        &:after {
          width: 8px;
          height: 8px;
          font-size: 8px;
          top: -1px;
          left: 2px;
        }
      }

      .plus {
        margin: 0 4px;
      }
    }
  }

  .balance-info {
    text-align: right;

    label {
      font-size: 10px;
      line-height: 13px;
      .textWeightRegular;
      color: @grey-shade-4;
      margin-bottom: 0;
      //margin-right: 8px;
    }

    .balance-value {
      .textWeightMedium;
      .textSizeMedium;
    }
  }
}
