@import "../../../global/styles/variables";

#LoginForm {
  text-align: center;

  h1 {
    text-align: left;
    margin-bottom: @margin-lg;
    font-family: Domine;
    font-size: @pixels-lg;
  }

  form {
    text-align: left;

    .info-container {
      .textWeightMedium;
      color: @grey-shade-4;
      margin-bottom: @margin-lg;
    }

    .subtitle {
      margin-bottom: @margin-xs;
    }

    .EmailAccountComponent {
      margin-bottom: @margin-md;
    }

    .password-input-container {
      .password-label {
        .textWeightBold;
        margin-bottom: @margin-xxs;
      }

      .password-input {
        margin-bottom: @margin-xs;
      }
    }

    .email-input {
      margin-bottom: @margin-md;
    }

    #forgot-password-link {
      .textSizeMedium;
      .textWeightSemiBold;
      text-align: left;
      display: block;
      margin-bottom: @margin-md;

      &:not(:hover) {
        text-decoration: none;
      }
    }

    .remember-me-checkbox-container {
      cursor: pointer;
      .textWeightSemiBold;
      .flexStartLayout();
      margin-bottom: 20px;

      .label {
        display: flex;
        align-items: center;
      }
    }

    .login-silently-container {
      text-align: center;

      #login-silently-btn {
        margin-top: @margin-sm;
        margin-bottom: @margin-xs;
      }
    }
  }
}
