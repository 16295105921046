@import "../../../styles/variables";

.AtText {
  line-height: 1.25;

  &.paragraph {
    line-height: 1.5;
  }

  &.size-sm {
    @fontSize: @alternate-pixels-xs;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-md {
    @fontSize: @alternate-pixels-s;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-lg {
    @fontSize: @pixels-sm;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.size-xl {
    @fontSize: @alternate-pixels-m;
    font-size: @fontSize;

    @media print {
      font-size: (@fontSize + 4px);
    }
  }

  &.weight-regular {
    .textWeightRegular;
  }

  &.weight-medium {
    .textWeightMedium;
  }

  &.weight-bold {
    .textWeightBold;
  }
}
