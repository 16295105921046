@import "../../styles/variables";

.ProductBalanceElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .product-quantity {
    color: #343434;
    .textSizeLarge;
    .textWeightBold;
  }

  &.disabled {
    opacity: 0.4;
  }
}
