@import "../../../styles/variables";

.AtPersonLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  min-width: @pixels-md;

  &.size-sm {
    .left-section {
      .info-container {
        .name {
          .textSizeMedium;
          color: @grey-color-base;

          @media @screenWidthXL {
            .textSizeMedium;
          }

          .textWeightMedium;
        }
      }

      .profile-picture-container {
        .blank-profile-picture {
          .square(24px);
        }
      }
    }
  }

  &.size-md {
    .left-section {
      .profile-picture-container {
        .blank-profile-picture {
          .square(32px);
        }
      }
    }
  }

  &.size-lg {
    .left-section {
      .profile-picture-container {
        .blank-profile-picture {
          .square(48px);
        }
      }
    }
  }

  &.size-md,
  &.size-lg {
    .left-section {
      .info-container {
        .name {
          margin-bottom: 0;
        }
      }
    }
  }

  .left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    flex: 1;
    min-width: @pixels-md;

    .profile-picture-container {
      margin-right: 14px;
      position: relative;

      .blank-profile-picture {
        .borderRadius(100%);
        background: @grey-shade-8;
      }

      .candidate-initials {
        position: absolute;
        .border(white);

        @positionOffset: -4px;

        bottom: @positionOffset;
        right: @positionOffset;
      }
    }

    .info-container {
      flex: 1;
      min-width: @pixels-md;

      .AtSubtitle {
        color: #6e6e6e;
      }

      .name,
      .description {
        .textOverflow;
      }

      .name {
        .textSizeMedium;
        .textWeightSemiBold;
        color: @grey-color-base;
      }

      .description {
        @media @screenWidthXL {
          .textSizeSmall;
        }

        .textWeightMedium;
        color: @grey-shade-3;
      }
    }
  }

  .right-section {
    margin-left: 16px;
    margin-right: 16px;

    .AtBadge {
      .textWeightBold;

      @media only screen and (min-width: 1300px) {
        .textWeightMedium;
      }
    }
  }

  @media print {
    .left-section .info-container {
      max-width: 305px;

      .name,
      .description {
        .textOverflow;
        max-width: none;
      }
    }
  }
}
