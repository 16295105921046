@import "../../../styles/variables";

.AtPersonCard {
  padding: (@padding-xs + @padding-xxs) @padding-xxs;
  height: 92px;
  .cardBorder();
  .borderRadius(@pixels-xxs);

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.highlighted,
  &.hovered {
    border-color: @atman-orange;

    .AtPersonAvatar {
      border-color: @atman-orange;
    }
  }

  &.interactive {
    cursor: pointer;

    &:hover {
      border-color: @atman-orange;

      .AtPersonAvatar {
        border-color: @atman-orange;
      }

      background-color: @orange-shade-7;

      .person-name {
        text-decoration: underline;
      }
    }
  }

  img {
    .square(@pixels-lg);
    .borderRadius(100%);
  }

  .person-name {
    margin: @margin-xs @margin-xxs 0 @margin-xxs;
    text-align: center;
    font-weight: bold;
    .textSizeSmall;
    line-height: (@pixels-xs + @pixels-xxs);
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: @pixels-md;
    color: @grey-color-base;
  }
}
