@import "../../styles/variables";

.ReportLegendContainer {
  .ReportLegendItem {
    &.ReportDimensionWeightLegendItem {
      .dimension-weight-icon {
        .flexLayout();
        padding: @padding-xxs 0 @padding-xxs 6px;
      }
    }
  }
}
