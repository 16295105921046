@import "../../styles/variables";

#TransactionsList {
  height: 100%;
  display: flex;
  flex-direction: column;

  .list-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: @padding-md;

    .left-section {
      display: flex;
      justify-content: flex-start;

      .DateRangePicker {
        margin-right: 16px;

        .DayPicker {
          > div {
            display: flex;
            flex-direction: column-reverse;

            .presets-container {
              padding: 16px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }

      .CustomSelectInput {
        margin-right: 16px;
      }

      .AtSelect {
        margin-bottom: 0;
      }
    }

    .right-section {
      display: flex;
      align-items: flex-start;

      .AtButton {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }

  .list {
    flex: 1 0;
    overflow: hidden;
    display: flex;
    .AtTableInfinite {
      .AtTableBase {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;

        .TransactionExpandRow > .FormGroupSection {
          .header,
          .content {
            padding: @padding-xs;
          }
        }

        .AtTableRow {
          &.isHovered {
            cursor: pointer;
            > .AtTableRowCell {
              &:first-child {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .fullPageTableMediaQuery();
}

.row-expand-slide-enter-done,
.row-expand-slide-enter-active,
.row-expand-slide-exit,
.row-expand-slide-exit-active,
.row-expand-slide-appear,
.row-expand-slide-appear-active {
  width: 100%;
}
