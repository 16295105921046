@import "../../styles/variables";

.AtStepper {
  .AtStepperStep {
    &:not(:last-child) {
      .main-content-container {
        margin-bottom: @margin-lg;
      }
    }
  }
}
