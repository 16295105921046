@import "../../../../styles/variables";

.AtSubScaleNavigationItem {
  @default-bottom-border: solid 3px transparent;

  .item-container {
    color: @grey-color-base;
    padding-bottom: 12px;
    margin-right: @margin-md;
    cursor: pointer;
    border-bottom: @default-bottom-border;
    .textWeightBold;
    .textSizeSmall;
    .flexLayout();

    &.selected {
      color: @primary-color-base;
      border-bottom: solid 3px @primary-color-base;
      cursor: default;

      @media print {
        color: @grey-color-base;
        border-bottom: @default-bottom-border;
      }
    }

    .item-icon-container {
      margin-right: @margin-xs;
    }
  }
}
