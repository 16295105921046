@import "../../../styles/variables";

.AtIcon {
  .flexLayout();

  &:not(.no-fixed-sizing) {
    &.icon-xs {
      .minSquare((@pixels-sm + @pixels-xxs));
    }

    &.icon-sm,
    &.icon-xs.extra-padding {
      .minSquare(@pixels-md);
    }

    &.icon-1x,
    &.icon-sm.extra-padding {
      .minSquare((@pixels-md + @pixels-xxs));
    }

    &.icon-lg,
    &.icon-1x.extra-padding {
      .minSquare(@pixels-lg);
    }

    &.icon-2x,
    &.icon-lg.extra-padding,
    &.icon-1x.upSize {
      .minSquare(@pixels-xxl);
    }

    &.icon-3x,
    &.icon-2x.extra-padding {
      .minSquare(@pixels-xxxl);
    }

    &.icon-4x,
    &.icon-3x.extra-padding {
      .minSquare(76px);
    }

    &.icon-5x,
    &.icon-4x.extra-padding {
      .minSquare(100px);
    }

    &.icon-6x,
    &.icon-5x.extra-padding {
      .minSquare(116px);
    }

    &.icon-7x,
    &.icon-6x.extra-padding {
      .minSquare(132px);
    }

    &.icon-8x,
    &.icon-7x.extra-padding {
      .minSquare(148px);
    }

    &.icon-9x,
    &.icon-8x.extra-padding {
      .minSquare(170px);
    }

    &.icon-10x,
    &.icon-9x.extra-padding {
      .minSquare(188px);
    }
  }
}
