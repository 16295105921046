@import "../../styles/variables";

.ReportPageHeader {
  padding-bottom: @padding-lg;

  &.lgHeaderSvg {
    position: relative;
    .AtParagraph {
      margin-top: @margin-sm;
      padding-left: @pixels-xl;
      width: 90%;
    }

    .AtSubtitle {
      position: relative;
      top: 85px;
      left: 117px;
      font-size: 25px;
    }
  }

  .AtTitle.titleOnBanner {
    position: relative;
    top: 75px;
    left: 126px;
  }

  @media print {
    padding-top: @page-top-padding !important;
    page-break-after: avoid;
    page-break-before: always;
    margin-left: -124px;

    &.pageBreakBeforeAuto {
      page-break-before: auto;
    }
  }
}
