@import "../../../styles/variables";

.AtTitleBadge {
  .flexLayout(center, flex-start);

  .AtTitle {
    margin-right: @margin-xs;
  }

  // TODO Simon 2021-08-06: Remove & use ComponentPadding prop once it's implement in AtBadge.
  .AtBadge {
    padding: @padding-xxs;
  }
}
