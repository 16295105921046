@import "../../../styles/variables";

#DashboardGraph {
  &.loading > .content {
    padding: 24px;
  }

  > .content {
    padding: 0;
  }

  .graph-container {
    width: @container-max-width-lg;
    height: 300px;
    margin: 0 auto;
  }

  .filters-section {
    display: flex;

    .all-products-filter {
      margin-right: 16px;
    }

    .btn-group > .AtButton {
      //color: #818181 !important;
      //background: #FAFAFA !important;
      min-width: inherit;

      svg {
        color: #818181;
      }

      &.selected {
        //color: @grey-shade-2 !important;
        //background: #FFFFFF !important;

        svg {
          color: @atman-orange;
        }
      }
    }
  }
}
