/**
 * This file sets the default layout styles used in the design system.
 **/

body {
  margin: 0;
  padding: 0;

  &,
  html,
  #react-root {
    overflow-y: hidden !important;
  }
}

html,
body,
#react-root,
.App {
  height: 100%;
  overflow: hidden;

  @media @screenWidthMaxSM {
    overflow-y: auto; //for vertical scroll on mobile when content height is bigger than viewport
  }
}

html.internetExplorer {
  width: 100%;
}
