@import "../../../styles/variables";

.AtCard {
  .flexLayout(initial, initial, column);
  box-shadow: 0px 1px 2px @box-shadow-color;

  &.no-shadow {
    box-shadow: none;
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
  }

  &.AtContainer .AtContainer-header {
    .flexLayout(flex-start, flex-start);

    &.clickable {
      cursor: pointer;
      user-select: none;
    }
  }

  .bottom-section {
    .flexLayout();
  }
}
