@import "../variables";

// Modal Panel: Right
@header-nav-height: 0;
@modal-height: 100%;

.modal {
  &.modal-left-pane,
  &.modal-right-pane {
    .modal-dialog {
      max-width: 100vw;
      height: @modal-height;

      @media @screenWidthMD {
        max-width: ~"calc(100vw - 64px)";
      }

      @media @screenWidthXL {
        max-width: 75vw;
      }

      @media @screenWidthXXL {
        max-width: 60vw;
      }

      @media only screen and (min-width: 1800px) {
        max-width: 50vw;
      }
    }

    &.show .modal-dialog {
      transform: translate(0, 0);
    }

    .modal-content {
      height: @modal-height;
      .borderRadius(0);
    }
  }
  &.modal-left-pane {
    .modal-dialog {
      transform: translate(-100%, 0);
      margin: @header-nav-height auto 0 0;
    }
  }

  &.modal-right-pane {
    .modal-dialog {
      transform: translate(100%, 0);
      margin: @header-nav-height 0 0 auto;
    }
  }
}

.internetExplorer {
  body > div[tabindex="-1"] {
    position: static !important;
  }
}
