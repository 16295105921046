@import "../variables";

.react-bootstrap-table th.sortable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}

// Global Bootstrap Tables

.react-bootstrap-table {
  //table {
  //  border-collapse: separate;
  //  border-spacing: 0 5px;
  //
  //  thead {
  //    tr {
  //      th:first-child {
  //        border-left: @default-border;
  //      }
  //
  //      th {
  //        border-top: @default-border;
  //        border-bottom: @default-border;
  //        font-size: 14px;
  //        padding: 0.25em 0.75em;
  //        text-decoration: underline;
  //        color: @grey-shade-2;
  //      }
  //
  //      th:last-child {
  //        border-right: @default-border;
  //      }
  //    }
  //  }
  //
  //  tbody {
  //    tr {
  //      height: 48px;
  //      border: @grey-color-bg 1px solid !important;
  //      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  //      font-size: 15px;
  //      background: white;
  //      -webkit-border-radius: 4px;
  //      -moz-border-radius: 4px;
  //      border-radius: 4px;
  //
  //      td:first-child {
  //        border-top-left-radius: 4px;
  //        border-bottom-left-radius: 4px;
  //      }
  //
  //      td:last-child {
  //        border-top-right-radius: 4px;
  //        border-bottom-right-radius: 4px;
  //      }
  //
  //      td {
  //        padding: 0.25em 0.75em;
  //        vertical-align: middle;
  //      }
  //
  //      @media @screenWidthLG {
  //        .atman-btn {
  //          opacity: 0;
  //        }
  //      }
  //
  //      &:hover {
  //        text-decoration: underline;
  //
  //        .atman-btn {
  //          opacity: 1;
  //        }
  //      }
  //    }
  //  }
  //}

  .filter,
  .react-bs-table-tool-bar {
    display: none !important;
  }
}

//.AtSubMenuNavigationLayout {
//  .react-bootstrap-table {
//    table {
//      thead {
//        tr {
//          border-top: none;
//          border-left: none;
//          border-right: none;
//
//          th, th:first-child, th:last-child {
//            border-top: none;
//            border-left: none;
//            border-right: none;
//          }
//        }
//      }
//    }
//  }
//}

.react-bootstrap-table {
  table {
    width: 100%;
    height: 100%;
    background: #ffffff;
    margin-bottom: 0;
    .cardBorder();

    thead,
    tbody {
      display: block;
      width: 100%;

      tr {
        display: inline-flex;
        width: 100%;
        border-bottom: @card-border;

        th,
        td {
          width: 100%;
          //padding: 0.25em 0.75em;
          display: flex;
          align-items: center;
        }
      }
    }

    tbody {
      height: ~"calc(100% - 40px)";
      overflow-y: auto;
    }

    thead {
      tr {
        height: 40px;
        padding-right: 17px;

        th {
          border-width: 0;
          color: #818181;
          .textSizeSmall;
          .textWeightMedium;

          &:focus {
            outline: none;
          }
        }
      }
    }

    tbody {
      tr {
        font-size: 17px;
        line-height: 24px;
        color: @grey-shade-2;
        cursor: pointer;

        &.no-click-action {
          cursor: default;

          &:hover td:first-child {
            text-decoration: none;
          }
        }

        &:not(:last-child) {
          border-bottom: @card-border;
        }

        @media @screenWidthMD {
          .atman-btn,
          .AtQuickActionsContainer {
            opacity: 0;
          }
        }

        &:hover {
          background: #ffffff;

          .atman-btn,
          .AtQuickActionsContainer {
            opacity: 1;
          }

          td:first-child {
            text-decoration: underline;
          }

          td.reset-expansion-style {
            text-decoration: none;
            cursor: default;
          }
        }

        td {
          border-width: 0;
          .textWeightRegular;
          .textSizeMedium;
          padding: @padding-xs (@padding-sm - @padding-xxs);

          &.reset-expansion-style {
            background-color: #fafafa;
          }
        }
      }
    }
  }
}

.internetExplorer,
.firefox {
  .react-bootstrap-table {
    overflow-y: auto;
  }
}

.safari {
  .react-bootstrap-table {
    table {
      tbody {
        min-height: 0.1px; // this is a 'hack' to force the list to be rendered (was not being rendered otherwise on safari)
      }
    }
  }
}
