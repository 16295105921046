@import "../../styles/variables";

#ClientDelegationForm {
  .inputs-container {
    display: flex;

    .RadioFormInput {
      flex: 0 0 50%;
      max-width: inherit;
    }
  }
}
