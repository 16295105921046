@import "../../../styles/variables";

#Breadcrumbs {
  .caret-icon {
    margin: 0 8px;
  }

  .custom-breadcrumb-item {
    .textSizeMedium;
    .textWeightSemiBold;
    color: @grey-shade-3;

    &:hover {
      color: @primary-color-base;
      text-decoration: none;
    }

    &:last-child {
      color: @grey-color-base;
    }

    &.root {
      text-transform: uppercase;
    }
  }
}
