@import "../../../../../../styles/variables";

.AssessmentWizardStepContent {
  .layoutBorder();
  .borderRadius();
  background-color: white;

  @containerPadding: @padding-sm @padding-xl;

  .header {
    padding: @containerPadding;
    border-bottom: @layout-border;

    .right-section {
      .flexLayout(@align-items: flex-end);

      > #LikertLegend {
        width: 100%;
      }
    }
  }

  .statements-container {
    padding: @containerPadding;
  }
}
