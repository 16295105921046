@import "../../styles/variables";

.ModalNewBalanceConfirmation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: (@padding-xxs + @padding-xs) @padding-sm;
  background: rgba(58, 115, 250, 0.08);
  border: 1px dashed @primary-color-base;
  .borderRadius;

  .label {
    .textSizeMedium;
    .textWeightMedium;
    margin-right: @padding-lg;
  }

  .cards-container {
    display: flex;
    align-items: center;

    .ProductBalanceChange {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
