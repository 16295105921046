@import "../../../../../../../../styles/variables";

.ObjectiveSubRow {
  // The following rules serve to account for the offset while maintaining the columns alignment
  @offsetWidth: @pixels-sm;
  @rowHorizontalPadding: @pixels-lg;
  @diffBetweenOffsetAndPadding: (@rowHorizontalPadding - @offsetWidth);

  .AtCollapse-toggle-row {
    min-height: @pixels-xxl;
    .textWeightSemiBold;

    .AtCheckboxBase {
      margin-right: @margin-sm;
    }

    .chevron-container {
      margin-right: (@margin-xs + @margin-xxs);
    }

    &.in-error {
      .objective-name {
        color: @red-color-base;
      }
    }
  }

  .AtCollapse-toggle-row {
    padding-left: @diffBetweenOffsetAndPadding !important;

    .content-col.competency-col {
      margin-left: @pixels-xxxl;
      flex-wrap: wrap;

      .AtIcon {
        margin-right: @pixels-sm;
      }
    }
  }

  @media @screenWidthMaxSM {
    .AtCollapse-toggle-row {
      display: block;

      .content-col.competency-col {
        margin-bottom: @margin-xs;
      }

      .DetailedScoreColumns {
        padding-left: @offsetWidth;
      }
    }
  }
}
