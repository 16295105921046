@import "../variables";
// @region: Inputs

.inputFocusStyles(@borderWidth: 1px) {
  .inputBorder(@width: @borderWidth);
  .formBoxShadow();
}

.form-control {
  .inputStyles;

  &:hover {
    .inputHoverStyles;
  }

  &:focus {
    .inputFocusStyles;
  }

  &::placeholder {
    color: @grey-shade-4;
  }

  &:disabled {
    background-color: @grey-shade-9;
    color: @grey-shade-4;

    &,
    &:hover {
      .inputBorder();
    }
  }
}

.form-text {
  &.text-muted {
    color: @grey-shade-2 !important;
  }
}

// @endregion: Inputs

// @region: Badges
.badge {
  .textSizeSmall;
  .textWeightMedium;
  .cardBorder();
  .borderRadius();
  padding: 6px 12px;
  background: #fafafa;
  color: @grey-shade-2;

  &:not(:last-child) {
    margin-bottom: 4px;
    margin-right: 4px;
  }
}
// @endregion: Badges

// @region: Tooltips
.tooltip-inner {
  .fontDefault;
  color: @grey-shade-2;
  background: white;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16) !important;
  border: @grey-color-bg 1px solid;
} // v1

.bs-tooltip-auto,
.bs-tooltip-top,
.bs-tooltip-right,
.bs-tooltip-bottom,
.bs-tooltip-left {
  &[x-placement^="top"],
  &.bs-tooltip-top {
    .arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid @grey-color-bg;

      &:before {
        border-top-color: white;
        display: none;
      }
    }
  }

  &[x-placement^="right"],
  &.bs-tooltip-right {
    .arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid @grey-color-bg;

      &:before {
        border-right-color: white;
        display: none;
      }
    }
  }

  &[x-placement^="bottom"],
  &.bs-tooltip-bottom {
    .arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid @grey-color-bg;

      &:before {
        border-bottom-color: white;
        display: none;
      }
    }
  }

  &[x-placement^="left"],
  &.bs-tooltip-left {
    .arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid @grey-color-bg;

      &:before {
        border-left-color: white;
        display: none;
      }
    }
  }
} // v1
// @endregion: Tooltips

.custom-radio,
.custom-checkbox {
  .custom-control-input {
    &:hover {
      & ~ .custom-control-label {
        &::before {
          .inputHoverStyles(2px);
        }
      }
    }

    &:checked {
      & ~ .custom-control-label {
        &::before {
          border-color: @primary-color-base;
          background-color: @primary-color-base;
        }
      }
    }

    &:focus {
      & ~ .custom-control-label {
        &::before {
          .inputFocusStyles(2px);
        }
      }
    }
  }

  .custom-control-label {
    &::before {
      width: 20px;
      height: 20px;
      .inputBorder(@width: 2px);
    }

    &::after {
      width: 20px;
      height: 20px;
    }
  }
}

// @region: Dropdown
.dropdown {
  > span {
    display: flex;
  }

  .dropdown-menu {
    .dropdown-header {
      padding: @padding-xs @padding-sm;
      color: @grey-shade-2;
    }

    > button,
    > a {
      .textSizeSmall;
      padding: @padding-xs @padding-sm;
      color: @grey-shade-2;
      font-weight: 500;

      &.disabled {
        color: @grey-shade-4;

        &:hover {
          background-color: #ffffff;
        }
      }

      &:hover {
        background-color: #efefef;
      }

      &:focus {
        outline: none;
      }

      &:active {
      }
    }
  }

  &.size-md {
    .dropdown-menu {
      > button {
        .textSizeMedium;
      }
    }
  }
}

// @endregion: Dropdown

// @region: Modal
.modal {
  font-family: "Quicksand", sans-serif;

  &.show {
    &.centered-modal {
      > .modal-dialog {
        margin-top: 160px;

        > .modal-content {
          .layoutBorder();
          .borderRadius();
          -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          padding: @padding-xs 0 0;

          > .modal-header {
            padding: @padding-xs @padding-lg;
            border-bottom-width: 0;

            .modal-title {
              .textSizeH2;
              .textWeightBold;
            }

            .close {
              padding: 0;
              margin-top: ~"calc(-1rem + 12px)";
            }
          }

          > .modal-body {
            padding: @padding-xs @padding-lg;
          }
        }
      }
    }
  }
}

.modal-backdrop {
  &.no-backdrop {
    background-color: transparent;
  }
}
// @endregion: Modal
