@import "../../../../styles/variables";

.SearchResultEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: @almost-white-color;
    text-decoration: none !important;
  }

  .actions-container {
    flex: 1 0 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: @grey-shade-2;

    > *:not(:last-child) {
      margin-right: @margin-sm;
    }
  }
}
