@import "../../../../../styles/variables";

.TableItemLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Quicksand";
  font-style: normal;
  line-height: @alternate-pixels-l;
}
