@import "../../../../styles/variables";

.AtWizardIconHeader {
  padding: @padding-sm @padding-lg;
  margin-bottom: @margin-xs;
  .flexLayout(center, flex-start);
  text-align: left;

  @media @screenWidthMaxSM {
    .borderRadius(0);
    border-top: none;
  }
}
