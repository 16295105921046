@import "../../../../../styles/variables";

.TableItemStatus {
  display: flex;
  align-items: center;

  .status-color-title {
    .flexLayout(center, start);

    .state-red {
      background: @red-color-base;
    }

    .state-yellow {
      background: @yellow-color-base;
    }

    .state-green {
      background: @green-color-base;
    }

    .AtTitle {
      margin-bottom: 0;
    }

    .status-color-indicator.sm {
      .square(@pixels-xs);
      margin-right: @margin-xs;
    }

    .status-color-indicator.lg {
      .square(12px);

      margin-right: @margin-xs;
    }

    .status-color-indicator,
    .status-color-indicator {
      border: 1px solid #d6d6d6;
      .circleBorder();
    }
  }
}
