@import "../../../styles/variables";

.AtStepNumber {
  .step-number {
    .square(@pixels-md);
    .textSizeLarge;
    .textWeightSemiBold;
    .flexLayout();
    border: 1px solid @grey-shade-7;
    .borderRadius(50%);

    &.disable {
      border: 1px solid #eaeaea;
      color: #3030301a;
    }
  }
}
