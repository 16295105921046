@import "../../../styles/variables";

.InterpretationDataView {
  text-align: left;

  .AtSubScaleNavigation {
    .textWeightBold;
  }

  .items {
    margin-top: @margin-sm;
  }

  @media print {
    .AtSubScaleNavigation {
      @spacing: @page-top-padding;
      .render-text-li {
        .multiplePagePrintHack;
      }
    }
    .HighlightedText {
      .interpretationTitle {
        margin-top: @margin-sm;
      }
    }
  }
}
