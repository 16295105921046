@import "../../styles/variables";

.CustomSelectInput {
  text-align: left;

  .form-control {
    .textSizeMedium;
    .cardBorder();
    .borderRadius(2px);
    padding: (@padding-xs + @padding-xxs) @padding-sm;
    height: inherit;
  }
}
