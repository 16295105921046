@import "../../../../styles/variables";

.ToggleableNotificationEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: @margin-xxs;

  label {
    .textSizeMedium;
    .textWeightRegular;
  }

  .AtSwitch {
    height: @pixels-xl;
    width: @pixels-xxxl;
  }
}
