#ct-container {
  top: 40px;

  .ct-toast {
    border-bottom: 4px solid transparent;
    justify-content: flex-start;
    position: relative;
    padding: (@padding-xs + @padding-xxs) @padding-md;
    min-width: ~"calc(100% - 16px)";

    @media (max-width: 768px) {
      margin: 0;
    }

    @media @screenWidthMD {
      min-width: 400px;
    }

    .ct-text-group,
    .ct-text-group-heading {
      width: 100%;
      margin-left: 15px;

      .ct-text {
        .textWeightSemiBold;
      }
    }

    .ct-text-group-heading {
      .close-icon-container {
        position: absolute;
        top: ~"calc(50% - 12px)";
        right: 24px;
      }
    }

    &.ct-toast-success {
      @toastColor: @green-color-base;

      border-bottom-color: @toastColor;

      > svg {
        color: @toastColor;
      }
    }

    &.ct-toast-info {
      @toastColor: @primary-color-base;

      border-bottom-color: @toastColor;

      > svg {
        color: @toastColor;
      }
    }

    &.ct-toast-loading {
      @toastColor: @primary-color-base;

      border-bottom-color: @toastColor;

      > svg {
        color: @toastColor;
      }
    }

    &.ct-toast-warn {
      @toastColor: @yellow-color-base;

      border-bottom-color: @toastColor;

      > svg {
        color: @toastColor;
      }
    }

    &.ct-toast-error {
      @toastColor: @red-color-base;

      border-bottom-color: @toastColor;

      > svg {
        color: @toastColor;
      }
    }
  }
}
