@import "../../../styles/variables";

.SidebarNavItemContainer {
  &.-device-mobile {
    .SidebarNavItem {
      text-align: left;
      height: inherit;
      padding: @padding-xxs @padding-sm;

      > a {
        justify-content: flex-start;
      }

      .icon-container {
        font-size: 18px;
      }

      &.active {
        border-right: 3px solid @atman-orange;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;

        .icon-container {
          svg {
            color: @atman-orange;
          }
        }
      }

      .icon-container {
        margin-right: 24px;

        svg {
          color: @grey-shade-2;
        }
      }
    }
  }

  &.-device-default {
    .SidebarNavItem {
      .name {
        display: none;

        //@media @screenWidthXXL {
        //  display: inline-block;
        //}
      }

      &.active {
        .icon-container {
          .borderRadius;
          background: @app-gradient-180;

          svg {
            color: white;
          }
        }
      }

      &:hover&:not(.active) {
        .icon-container {
          //@media @screenWidthXXL {
          //  margin-right: 10px;
          //}

          svg {
            color: @grey-shade-2;
          }
        }
      }
    }
  }
}

.sidebar-tooltip {
  display: block !important;

  &.show {
    opacity: 1 !important;
  }

  .tooltip-inner {
  }

  //@media @screenWidthXXL{
  //  display: none !important;
  //}
}
