@import "../../../styles/variables";

.TeamDevelopmentEmployeeAppraisal {
  .appraisalViewTabs {
    .AtButtonTabs {
      display: flex;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      margin: initial;
    }
  }
  .Appraisal {
    &-subview {
      margin-top: @margin-xs;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: @padding-md @padding-lg;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: @margin-xs;
      gap: @pixels-xs;
    }

    &-summary {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: @padding-md @padding-lg;
      margin-bottom: @margin-xs;
      overflow-x: hidden;
      width: 100%;

      .score-container {
        padding-right: @padding-md;
      }

      .appraisal-info-container {
        padding: @padding-xxs @padding-md;
        border-left: 1px solid @grey-shade-9;

        .AtTitle {
          font-size: 24px;
          .textWeightBold;
        }

        .manager-label {
          .textSizeSmall;
          color: @grey-shade-3;
          text-transform: uppercase;
          margin-bottom: @margin-xxs;
        }

        .manager-name {
          .textSizeMedium;
          .textWeightBold;
        }
      }

      &-result {
        padding: @padding-md @padding-lg;
        margin-bottom: @margin-xs;
        overflow-x: hidden;
        width: 100%;

        .result-header {
          .AtTitle {
            .textWeightBold();
            margin-bottom: @margin-xs;
          }

          margin-bottom: @margin-sm;
        }
      }
    }
  }

  @media print {
    page-break-before: always;

    .Appraisal-summary {
      border: none;
      padding: @padding-lg 0 0;
      margin-top: calc(@margin-xl + 120px);
      margin-bottom: @margin-md;
    }

    .Appraisal-summary-result {
      border: none;
      padding: @padding-lg 0;
    }

    &.Detailed {
      page-break-before: avoid;

      padding-top: @page-top-padding;

      .TableRow:not(:first-child) {
        page-break-before: always;
        padding-top: @page-top-padding;
      }
    }
  }
}
