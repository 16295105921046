@import "../../styles/variables";

.AtMultiPanelLayout {
  display: flex;
  height: 100%;
  flex-direction: column;

  @media @screenWidthXL {
    flex-direction: row;
  }
}
