@import "../../../../styles/variables";

.AtWizardStepHeader {
  .title-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: @margin-xs;

    .step-number-container {
      .flexLayout();
      flex: 0 0 auto;
      .square(@pixels-lg);
      .cardBorder();
      .borderRadius(100%);
      background-color: white;
      .textSizeLarge;
      .textWeightBold;
      margin-right: @margin-xs;
    }
  }

  .description-section {
    max-width: 70%;
    text-align: left;
    .textSizeMedium;
    .textWeightMedium;
    color: @grey-shade-3;
  }

  @media @screenWidthMaxSM {
    .title-section {
      .title {
        .textWeightBold;
      }
    }

    .description-section {
      display: flex;
      flex-direction: column;
      max-width: none;

      .AtLink {
        text-decoration: underline;
        margin-bottom: @margin-xs;
      }
    }
  }
}
