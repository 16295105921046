@import "../../styles/variables";

.BillingModalHelpMessage {
  .textSizeMedium;
  text-align: center;
  padding: @padding-xxs 0 @padding-md;

  .chat-link {
    color: @primary-color-base !important;
    text-transform: lowercase;
  }
}
