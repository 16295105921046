@import "../../../../../../styles/variables";

.ShowScoreToggle {
  display: flex;
  font-size: @alternate-pixels-xs;
  padding-bottom: @pixels-xs;
  padding-right: @pixels-xxs;
  justify-content: flex-end;

  .sidePanelSort {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: @pixels-sm;

    .AtParagraph {
      margin: 0 @pixels-sm;
      cursor: pointer;
      padding-top: @pixels-xs;
      font-size: @alternate-pixels-xs;
      line-height: @pixels-sm;
    }

    .AtSwitch {
      transform: scale(0.8);
    }

    cursor: pointer;
  }
}
