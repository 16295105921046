@import "../../../../styles/variables";

.TabNav {
  &.standalone {
    background: #ffffff;
    padding: 0 @padding-md;
    border-bottom: @layout-border;
  }

  &.isContainerized {
    margin-bottom: @margin-xs;
  }

  .header-view.hideTabs {
    padding-bottom: @padding-xs;
  }

  > .filters-header {
    .flexStartLayout();
    align-items: center;
    user-select: none;

    .TabNavItem {
      &:not(:last-child) {
        margin-right: @margin-md;
      }
    }
  }
}
