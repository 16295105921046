@import "../../../styles/variables";

.ImportanceIndicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: @margin-sm 0;
  .importanceIndicator-wrapper {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      margin: 0 @margin-xs;
    }
  }
  .ReportLegendContainer {
    margin-bottom: 0;
    .ReportLegendItem.ReportRangeLegendItem {
      flex-direction: row-reverse;
      border: none;
      margin-right: 0;
      .legend-icon.range-icon {
        margin-right: 0;
        margin-left: @margin-xs;
      }
    }
  }
}
