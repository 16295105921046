@import "../../styles/variables";

.ReportCard {
  .cardBorder();
  .borderRadius();
  padding: @padding-sm;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .InitialsAvatar {
      margin-right: 12px;
    }

    .info-container {
      text-align: left;

      .title {
        .textSizeMedium;
        .textWeightMedium;
      }

      .sub-title {
        .textSizeSmall;
        .textWeightMedium;
        color: @grey-shade-4;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80%;
      }
    }
  }
}
