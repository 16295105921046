@import "../../../../../styles/variables";

.TableItemButtons {
  display: flex;
  align-items: center;
  margin-left: auto;

  .secondaryButton {
    margin-right: @margin-xxs;
    .AtButtonBase.color-secondary.btn-secondary {
      border: none;
    }
  }
}

.cardsItem {
  .TableItemButtons {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
