@import "../../styles/variables";
.AtNotes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0 @padding-sm @padding-sm @padding-sm;
  background-color: #f5f5f5;
  padding: @padding-xs;
  white-space: pre-wrap;
  color: #303030;
  font-family: @quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  overflow-wrap: anywhere;
}
