@import "../../styles/variables";

#TransactionsContainer {
  padding: @default-container-padding-bottom;
  height: 93vh;

  #TransactionsList {
    .list {
      .AtTableInfinite {
        .AtTableBase {
          height: 0;
          flex: 1 0;
        }
      }
    }

    .AtTableInfinite {
      @media screen and (min-width: 991px) and (max-width: 1600px) {
        .emptyState {
          position: absolute;
          margin-left: 480px;
        }
      }

      @media screen and (min-width: 1600px) {
        .emptyState {
          position: absolute;
          margin-left: 700px;
          margin-top: 200px;
        }
      }
    }
  }
}
