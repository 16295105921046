@import "../../styles/variables";

#CompanyInformation {
  .company-info {
    .buttons-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .required-languages-form {
    .description {
      .textSizeSmall;
      .textWeightMedium;
      color: @grey-shade-5;
      margin-bottom: @margin-xs;
      text-align: left;
    }

    .AtCheckbox {
      &:not(:last-child) {
        margin-bottom: @margin-xs;
      }
    }
  }

  .logo {
    flex: 0 0 50%;
  }
}
