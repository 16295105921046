@import "../../styles/variables";

.SmallTitleContentBlock {
  &:first-child {
    padding-right: @padding-md;
  }

  > .title {
    .textSizeSmall;
    font-weight: bold;
    text-transform: uppercase;

    margin-bottom: @margin-xs;
  }
}
