@import "../../../styles/variables";

.AtSubtitle {
  .fontQuicksand;
  color: @grey-shade-2;

  &.size-sm {
    font-size: @alternate-pixels-s;
    line-height: @alternate-pixels-l;

    @media print {
      font-size: @pixels-sm;
      line-height: @pixels-md;
    }
  }

  &.size-md {
    font-size: @pixels-sm;
    line-height: @pixels-md;

    @media print {
      font-size: @alternate-pixels-m;
      line-height: @pixels-md;
    }
  }

  &.size-lg {
    font-size: @alternate-pixels-m;
    line-height: @pixels-md;

    @media print {
      font-size: @alternate-pixels-l;
      line-height: @pixels-md;
    }
  }

  &.size-xl {
    font-size: @alternate-pixels-l;
    line-height: @alternate-pixels-lg;

    @media print {
      font-size: @pixels-md;
      line-height: @pixels-lg;
    }
  }
}
