@import "../../../styles/variables";

.AtAvatar {
  img {
    border-radius: 50%;
  }

  &.avatar-xs {
    @size: (@pixels-sm + @pixels-xxs);

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-sm {
    @size: @pixels-md;

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-md {
    @size: (@pixels-md + @pixels-xxs);

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-lg {
    @size: @pixels-lg;

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-lg-md {
    @size: @pixels-xl;

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-xl {
    @size: @pixels-xxl;

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }

  &.avatar-xxl {
    @size: @pixels-xxxl;

    > img {
      .square(@size);
    }

    .AtIcon > svg {
      font-size: @size;
    }
  }
}
