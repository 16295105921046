@import "../../../styles/variables";

.Banner {
  display: flex;
  align-items: center;

  .iconTextBanner {
    display: flex;
    align-items: center;

    .AtIcon {
      margin-right: @margin-xs;
    }
  }

  .AtButton {
    margin-left: auto;
  }
}
