@import "../../../../../../styles/variables";

.AtTableHeader {
  .tableBorder();
  border-radius: 8px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  height: @pixels-xxl;

  .AtTableHeaderCell.checkbox:first-child {
    padding-left: @padding-xs;
  }

  .AtTableHeaderCell.iconDropdown:first-child {
    padding-left: @padding-sm;
  }

  .AtTableHeaderCell:first-child {
    padding-left: @pixels-sm;
  }

  .AtTableHeaderCell:last-child {
    padding-right: @pixels-sm;
  }

  .AtTableHeaderCell {
    padding: @padding-xs;
    .AtCheckboxBase.custom-checkbox {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.size-sm {
        .custom-control-label {
          height: @pixels-sm;
          width: @pixels-sm;
        }
      }

      &.size-md {
        .custom-control-label {
          height: @alternate-pixels-l;
          width: @alternate-pixels-l;
        }
      }

      .custom-control-label::before,
      .custom-control-label::after {
        top: 0;
        left: 0;
      }
    }
    &.checkbox {
      width: @pixels-lg;

      .AtCheckboxBase.custom-checkbox {
        margin-right: 0;
      }
    }
  }
}
