@import "../../../styles/variables";

.LegendSection {
  > .items-container {
    .AtValueColor {
      .textWeightMedium;

      &:not(:last-child) {
        margin-right: @margin-md;
      }
    }
  }
}
