@import "../../../../styles/variables";

.H3SectionLayout {
  .scale-group-header {
    margin: @margin-xxs (@margin-xs + @margin-lg);
    text-align: left;
    display: flex;
    justify-content: space-between;

    &:hover .AtIconTooltip svg {
      opacity: 1;
    }

    .AtIconTooltip {
      svg {
        margin-left: @pixels-xs;
        color: #8d8d8d;
        vertical-align: initial;
        .square(12px);
        opacity: 0;
        transition: 0.3s;
      }
    }

    * {
      text-align: left;
    }

    &.AtUnipolarScaleGroup {
      margin-bottom: @margin-md;
    }
  }

  @media print {
    &.AtUnipolarScaleGroup.cognitiveBipolarScale.detailed {
      .scale-group-header {
        padding-top: @padding-sm;
        margin: 0;

        .AtTitle {
          font-size: @pixels-sm;
          margin-bottom: 0;
        }
      }
    }
  }
}
