@import "../../styles/variables";

.ExportResultCompDevCampaign.AtModal {
  max-width: 600px;
  box-shadow: 0 2px 2px 0 #2626260a;

  .AtModalContainerContent {
    margin: 0;
    padding: 0;
    max-height: 600px;
    position: relative;

    .AtModalHeader {
      padding: @padding-sm @padding-sm;
      box-shadow: 0 2px 2px 0 #2626260a;

      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1055;
      background-color: white;
    }

    .AtModalBody {
      padding: @padding-lg @padding-xxl;

      .selectors {
        display: flex;
        flex-direction: column;
        gap: @padding-xs;
        .AtHint {
          border: none;
          div {
            display: flex;
            flex-direction: row;
            gap: @padding-xs;
            .AtText {
              color: @primary-color-base;
            }
          }
        }
      }

      .AtCheckbox {
        border: initial;

        &:first-child {
          border-bottom: 1px solid #efefef;
        }
      }
    }
  }

  .AtModalFooter {
  }
}
