@import "../../../../../styles/variables";

.PersonalityFactorsDescription {
  padding-top: @padding-lg;
  .summary-element {
    .multiplePagePrintHack;
    display: flex;
    border-bottom: @layout-border;

    .AtText {
      width: 25%;
      padding-bottom: @padding-sm;
      padding-top: @padding-sm;
    }

    .AtParagraph {
      width: 75%;
      margin-left: @margin-xs;
      padding-bottom: @padding-sm;
      padding-top: @padding-sm;
    }
  }
}
